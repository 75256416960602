import { handleClassNames } from "lib/cssHelpers";
import React, { useState } from "react";
import styles from "@assets/css/home.module.css";
import { useRouter } from "next/router";
import FaqMapItem from "./FaqMapItem";
import { faq } from "../../lib/faq";

const Faq = ({ setLoader, requestpage = false }) => {
  const router = useRouter();
  const [showMore, setShowMore] = useState(false);
  const [show, setShow] = useState(true);
  const handleClasses = (classValue) => {
    return handleClassNames(classValue, styles);
  };
  const handleViewLess = () => {
    setShowMore(false);
  };

  const handleViewMore = () => {
    if (requestpage) {
      setShowMore(true);
      return;
    }
    setLoader(true);
    router.push("/faq");
  };

  return (
    <section className={`faq-requst-section ${handleClasses("faqs-section")}`}>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className={`text-center ${handleClasses("title-anim")}`}>
              <div
                className={`header-all faq-font-size ${handleClasses(
                  "fqs-heade"
                )} faq-color-all`}

              >
                Frequently Asked Questions (FAQs)
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className={handleClasses("faqs-div")}>
              <div id="accordion" className={handleClasses("faqs-inner")}>
                {!showMore
                  ? faq.slice(0, 6).map((item) => {
                    return <FaqMapItem item={item} show={show} setShow={setShow} />;
                  })
                  : faq.map((item) => {
                    return <FaqMapItem item={item} show={show} setShow={setShow} />;
                  })}
                {showMore ? (
                  <div
                    onClick={() => {
                      handleViewLess();
                    }}
                    className={`${handleClasses("view-more-faq")} faq-request`}
                  >
                    <a className="text-light pt-1">
                      View Less
                      <i className="fas fa-angle-up arrow-down-faq px-1"></i>
                    </a>
                  </div>
                ) : (
                  <div
                    onClick={() => {
                      handleViewMore();
                    }}
                    className={`${handleClasses("view-more-faq")} faq-request`}
                  >
                    <a className="text-light pt-1">
                      View More{" "}
                      {requestpage ? (
                        <i className="fas fa-angle-down arrow-down-faq"></i>
                      ) : (
                        <i className="fa-solid fa-arrow-right"></i>
                      )}
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Faq;