import { handleClassNames } from 'lib/cssHelpers';
import React from 'react'
import Style from '@assets/css/home.module.css'

const AssureStrip = () => {
    const handleClass = (classValue) => {
        return handleClassNames(classValue, Style);
    }
    return (
        <section className={handleClass("assure-section ")}>
            <div className="container-fluid">
                <div className={handleClass("lg-blue-bg")}>
                    <ul className={handleClass("style-ul")}>
                        <li>
                            <div className={handleClass("elem-assure")}>
                                <span>
                                    <img src="/assets/service-icon/request.webp"  alt="oba-image" />
                                </span>
                                <h5>
                                    Neutral Service <br /> Advisory
                                </h5>
                            </div>
                        </li>
                        <li>
                            <div className={handleClass("elem-assure")}>
                                <span>
                                    <img src="/assets/oba/two-v2.webp"  alt="oba-image" />
                                </span>
                                <h5>
                                    Cheaper than  <br />
                                    Garage Pricing
                                </h5>
                            </div>
                        </li>
                        <li className={handleClass("assureblick-li")}>
                            <div className={handleClass("elem-assure assure-block")}>
                                <span>
                                    <img src="/assets/oba/ob-assure.webp" alt="obsure" />
                                </span>
                            </div>
                        </li>
                        <li>
                            <div className={handleClass("elem-assure")}>
                                <span>
                                    <img src="/assets/oba/three-v2.webp"   alt="oba-image"/>
                                </span>
                                <h5>
                                    Genuine Parts
                                    <br />
                                    Guarantee
                                </h5>
                            </div>
                        </li>
                        <li>
                            <div className={handleClass("elem-assure")}>
                                <span>
                                    <img src="/assets/oba/four-v2.webp"  alt="oba-image"/>
                                </span>
                                <h5>
                                    90 Days Labor
                                    <br />
                                    Warranty
                                </h5>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </section>)
}

export default AssureStrip