import moment from "moment";
import React, { useEffect, useState } from "react";

const WorkshopTime = ({ data }) => {
  const [days, setDays] = useState([]);

  let today = moment().format("dddd");

  useEffect(() => {
    let obj = [
      {
        day: "Monday",
        from: data.MondayFrom,
        to: data.MondayTo,
        nextDay: "Tuesday",
      },
      {
        day: "Tuesday",
        from: data.TuesdayFrom,
        to: data.TuesdayTo,
        nextDay: "Wednesday",
      },
      {
        day: "Wednesday",
        from: data.WednesdayFrom,
        to: data.WednesdayTo,
        nextDay: "Thursday",
      },
      {
        day: "Thursday",
        from: data.ThursdayFrom,
        to: data.ThursdayTo,
        nextDay: "Friday",
      },
      {
        day: "Friday",
        from: data.FridayFrom,
        to: data.FridayTo,
        nextDay: "Saturday",
      },
      {
        day: "Saturday",
        from: data.SaturdayFrom,
        to: data.SaturdayTo,
        nextDay: "Sunday",
      },
      {
        day: "Sunday",
        from: data.SundayFrom,
        to: data.SundayTo,
        nextDay: "Monday",
      },
    ];
    setDays(obj);
  }, [data]);
  return (
    <>
      {days.map((day, index) => {
        if (day.day === today && day.to && day.from) {
          if (
            moment().isBetween(
              moment(day.from, "hh:mm A"),
              moment(day.to, "hh:mm A")
            )
          ) {
            return (
              <span className="font-weight-bold" style={{ color: "gray" }}>
                <span className="text-success">Open</span> - Closes {day.to}
              </span>
            );
          } else {
            return (
              <span className="font-weight-bold" style={{ color: "gray" }}>
                <span className="text-danger">Closed</span> - Opens at{" "}
                {days.length <= index ? days[index + 1]?.from : days[0]?.from}
              </span>
            );
          }
        }
      })}
    </>
  );
};

export default WorkshopTime;
