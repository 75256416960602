import { getCustomerCart, getSearch, getEmirates, getDefaultWorkshop, getBrandObServe, getBrand, getLowestPackages, getJourneyCategories } from "../api/home";
import { setCartAddons, setCartJobItems, setCartProducts, setCartWorkshop } from "../redux/actions/cartAction";
import { getCarBrandModal } from "../redux/actions/globalAction";
import { EMIRATES, FETCH_CAR_BRAND, FETCH_CATEGORY, FETCH_DEFAULT_WORKSHOP, FETCH_HOME_CAR_BRAND, GET_ERROR, LOWEST_PACKAGES, WORKSHOPS_PRODUCT_CATEGORY } from "../redux/actions/types";

export const fetchDefaultWorkshop = () => async (dispatch) => {
    try {
        const { data } = await getDefaultWorkshop();
        if (data) {
            dispatch({
                type: FETCH_DEFAULT_WORKSHOP,
                payload: data.Data,
            });
        }
    } catch (error) {
        dispatch({
            type: GET_ERROR,
            payload: error,
        });
    }

}

export const fetchEmirates = () => async (dispatch) => {
    try {
        const { data: res } = await getEmirates()
        if (res && res.Data) {
            dispatch({
                type: EMIRATES,
                payload: res.Data,
            });
        }
    } catch (error) {
        dispatch({
            type: GET_ERROR,
            payload: error,
        });
    }
};

export const fetchSearch = async () => {
    try {
        const response = await getSearch();
        if (response && response?.data) {
            return response.data;
        } else {
            return { Data: [] };
        }
    } catch (error) {
        console.error(error);
    }
}

export const fetchCustomerCart = () => async (dispatch) => {

    try {
        let cartId = localStorage.getItem("cartId");
        const Id = cartId ? cartId : null;

        const CarData = JSON.parse(
            localStorage.getItem("car_data") || JSON.stringify("")
        );
        const res = await getCustomerCart(Id);
        const response = res?.data;
        if ((!response?.Data || ((response?.Data.product && !response?.Data.product.length) || (response?.Data.jobs && !response?.Data.jobs.length))) && localStorage.getItem('fetch-cart')) {
            localStorage.removeItem('fetch-cart')
            // router.push("/services");
            return
        }
        const isStartOver = localStorage.getItem("startOver");
        const isTrue = (response.Data?.jobs && response.Data?.jobs?.length) || (response.Data?.product && response.Data?.product?.length) || (response.Data?.workshop && !isStartOver)
        if (response && response?.Data && isTrue) {
            localStorage.setItem("cartId", response.Data?.Id);
            const { addons, jobs, product, workshop } = response?.Data;
            dispatch(setCartProducts(product));
            dispatch(setCartJobItems(jobs));
            dispatch(setCartAddons(addons));
            dispatch(setCartWorkshop(workshop));
            if (response?.Data?.CarDetails) {
                const { CarCompanyName, CarModelName, CarCompany, CarModel } = response?.Data?.CarDetails;
                dispatch(
                    getCarBrandModal(
                        CarCompanyName,
                        CarModelName,
                        CarCompany?.ImgUrl,
                        CarModel?.ImgUrl,
                        CarCompany.ID,
                        CarModel.ID,
                        CarData && CarData.slug ? CarData.slug : null
                    )
                );
            }
            localStorage.removeItem('fetch-cart')
        }
    } catch (error) {
        dispatch({
            type: GET_ERROR,
            payload: error,
        });
    }
}


export const fetchBrandObServe = () => async (dispatch) => {
    try {
        const { data: res } = await getBrandObServe();
        if (res && res.Data) {
            dispatch({
                type: FETCH_HOME_CAR_BRAND,
                payload: res.Data,
            });
        }
    } catch (error) {
        dispatch({
            type: GET_ERROR,
            payload: error,
        });
    }
};

export const fetchBrand = (id) => async (dispatch) => {
    try {
        const { data: res } = await getBrand();
        if (res && res.Data) {
            dispatch({
                type: FETCH_CAR_BRAND,
                payload: res.Data,
            });
        }
    } catch (error) {
        dispatch({
            type: GET_ERROR,
            payload: error,
        });
    }
};


export const fetchLowestPackage = (EmirateId = null, EmirateAreaId = null, CarCompanyId = null, CarModelId = null) => async (dispatch) => {
    try {
        const { data: res } = await getLowestPackages(EmirateId, EmirateAreaId, CarCompanyId, CarModelId);
        if (res && res.Data) {
            dispatch({
                type: LOWEST_PACKAGES,
                payload: res.Data,
            });
        }
    } catch (error) {
        dispatch({
            type: GET_ERROR,
            payload: error,
        });
    }
}

export const fetchJourneyCategories = () => async (dispatch) => {
    try {
        dispatch({
            type: WORKSHOPS_PRODUCT_CATEGORY,
            payload: [],
        });
        
        const { data: res } = await getJourneyCategories();
        
        if (res && res.Data) {
            dispatch({
                type: FETCH_CATEGORY,
                payload: res.Data,
            });
        }
    } catch (error) {
        dispatch({
            type: GET_ERROR,
            payload: error,
        });
    }
};





