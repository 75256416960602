import API from "api";

export const checkUser = () => {
    const token = localStorage.getItem("token");
    if (token) return token;
    else return undefined;
};

export const setToken = (token) => {
    localStorage.setItem("token", token);
    return true;
};

export const isLoggedIn = async () => {
    return await new Promise( async (resolve, reject) => {        
        let token 
        if (typeof window !== 'undefined') {
             token = JSON.parse(localStorage.getItem("login_access"));
            // Proceed with further logic using the token
          } else {
            // Handle the case when running on the server
            token=""
          }
        if (token?.access_token) {
            return await API.get(
                `${process.env.NEXT_PUBLIC_API_URL}v2/api/customers/login-status`
            ).then(res => {
                resolve()
            }).catch(err => {
                reject()
            })
        } else reject()
    })
}