import { useRouter } from "next/router";
import React, { useCallback, useEffect, useState } from "react";
import { Container, Row, Col, Button, Modal, Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import {
  setCartAddedAddons,
  setCartJobItems,
  setCartProducts,
  setCartStage,
  setCartAddedPackages,
  setCartWorkshop,
  setShowMobileIstand,
  setCartJourney,
} from "../../redux/actions/cartAction";
import { saveCart } from "../../lib/cart";
import { gtm_ecommerce_remove_from_cart } from "lib/GA4_tag";
import { resetCart } from "../../lib/cart";
import { isMobile } from "react-device-detect";
import { route } from "next/dist/server/router";
import { setLayer } from "redux/actions/globalAction";
import { workshopPurchase } from "lib/config";
import { getWorkshopsProductCategory } from "redux/actions/apiActions";
import axios from "axios";

const ConfirmCartChange = ({
  show,
  onHide,
  redirectURL,
  setCartJourney,
  cartPage,
  setCartJobItems,
  setCartProducts,
  setCartStage,
  setCartWorkshop,
  redirectWorkshopURL,
  setCartAddedAddons,
  cart,
  globalData,
  setShowMobileIstand,
  handleResetCart,
  apiData,
  paragraph,
  setLayer,
  getWorkshopsProductCategory,
}) => {
  const router = useRouter();
  const [loading, setLoading] = useState("");

  // const isFirstJourneySteps = localStorage.getItem("isFirstJourneySteps")
  let isFirstJourneySteps;
  let isWorkshopCollection;
  if (typeof window !== "undefined") {
    isFirstJourneySteps = localStorage.getItem("isFirstJourneySteps");
    isWorkshopCollection = sessionStorage.getItem("isWorkshopCollection");
  }

  const handleConfirmChange = () => {
    localStorage.removeItem("tyreProduct");
    //   sessionStorage.remove('get-fitting-at-home')
    setLoading("start-over");
    if (paragraph) {
      handleResetCart();
      if (
        (cart?.jobItems && cart?.jobItems.length) ||
        (cart?.addedPackages && cart?.addedPackages.length)
      ) {
        const removeItemData = [...cart?.jobItems, ...cart?.addedPackages];
        RemoveFromCartEvent(removeItemData);
      }
      setTimeout(() => {
        setLoading("");
      }, 2000);
      return;
    }
    sessionStorage.removeItem("isWorkshopCollection");
    localStorage.setItem("isFirstJourneySteps", true);
    localStorage.removeItem("workshop-selected");
    localStorage.removeItem("obWorkshop");
    setCartStage(null);
    setLayer(false);
    localStorage.removeItem("cart-packages");
    localStorage.removeItem("isSmartSelection");
    localStorage.removeItem("cart-job-items");
    localStorage.removeItem("cart-offers");
    localStorage.setItem("startOver", true);
    localStorage.setItem("cartEmpty", true);
    sessionStorage.removeItem("get-fitting-at-home");
    resetCart().then(async () => {
      localStorage.setItem("isCartClear", true);
      localStorage.setItem("startOver", true);
      const workshopBasedJourney = JSON.parse(
        sessionStorage.getItem("WORKSHOP_JOURNEY") ?? JSON.stringify("")
      );

      if (workshopBasedJourney) {
        getWorkshopsProductCategory(
          workshopBasedJourney.id,
          globalData.carData?.modelId
        );

        let workshopSlug = workshopBasedJourney?.slug;
        const { data } = await axios.get(
          `${process.env.NEXT_PUBLIC_API_URL}v2/api/workshops?slug=${workshopSlug}`,
          {
            headers: {},
          }
        );
        sessionStorage.setItem("isWorkshopCollection", true);
        setCartWorkshop(data);
        setTimeout(() => {
          window.location.href =
            window.location.origin + workshopBasedJourney.url;
        }, 500);
        return;
      }
      if (!cartPage) {
        if (redirectWorkshopURL) {
          router.push(redirectWorkshopURL);
        } else {
          router.push(redirectURL);
        }
      } else {
        localStorage.setItem("hasLocationPermission", "granted");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    });

    // saveCart(cart, apiData, globalData, true);
    if (
      (cart?.jobItems && cart?.jobItems.length) ||
      (cart?.addedPackages && cart?.addedPackages.length)
    ) {
      const removeItemData = [...cart?.jobItems, ...cart?.addedPackages];

      RemoveFromCartEvent(removeItemData);
    }
  };
  const RemoveFromCartEvent = (items) => {
    const removeItem = items.map((item, index) => {
      let offer;
      const isOfferApplied =
        cart.appliedCartOffers?.variantId == item?.JourneyVariantId;
      if (isOfferApplied && item?.Offers) {
        offer = item.Offers.filter(
          (e) => e.PromotionId == cart.appliedCartOffers?.PromotionId
        );
      }
      return {
        item_id: item.JourneyVariantId || item.Id,
        item_name: item.JourneyVariantName || item.Name,
        coupon: offer?.length
          ? offer[0]?.PromotionCode || offer[0].PromotionId
          : null,
        price: item.Cost,
        index: index,
        quantity: 1,
      };
    });
    const totalCost = items.reduce((accumulator, currentItem) => {
      return accumulator + currentItem.Cost;
    }, 0);

    const event = {
      currency: "AED",
      value: totalCost,
      items: removeItem,
    };
    gtm_ecommerce_remove_from_cart(event);
  };
  const handleRedirect = () => {
    setLoading("go-to-cart");
    if (paragraph) {
      onHide();
      setLoading("");
      return;
    }
    localStorage.removeItem("url_data");
    localStorage.removeItem("startOver");
    if (isFirstJourneySteps) {
      localStorage.removeItem("isWorkshopCollection");
    }
    if (isMobile) {
      localStorage.setItem("mobileCart", true);
    }
    if (!cartPage) {
      router.push(redirectURL);
    } else {
      let productLink = "";
      if (cart.products?.length > 0) {
        productLink = apiData.categories.filter(
          (item) => item.ID == cart.products[0].JourneyCategoryId
        )[0].Slug;
      }
      // onHide()
      let immutableUrl;
      if (router.query && router.query.slug && router.query?.slug?.length) {
        const modifiedUrl = productLink
          ? router.query?.slug?.map((item, index) =>
              index === 1 ? productLink : item
            )
          : router.query?.slug;
        const result = modifiedUrl.join("/");
        immutableUrl = result;
      }
      localStorage.setItem("isHeader", true);
      window.location.href = `${window.location.origin}/services/fujairah-mina-al-fajer/${productLink}`;

      // window.location.href = `${window.location.origin}/services/${immutableUrl}`;
      // router.push(`/services/fujairah-mina-al-fajer/${productLink}`, undefined, { shallow: false });
    }
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title style={isMobile ? { fontSize: "20px" } : {}}>
          Start a new service selection?
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          {" "}
          {paragraph
            ? paragraph
            : "Adding a new service will clear existing items from your cart."}
        </p>
      </Modal.Body>
      <Modal.Footer>
        {/* <div className= {isMobile ? "w-100 d-flex justify-content-between align-items-center":{}}> */}
        <Button variant="primary" onClick={handleConfirmChange}>
          {paragraph ? "Reset Cart" : "Start Over"}
          {loading == "start-over" ? (
            <Spinner
              animation="grow"
              role="status"
              className={!isMobile ? "ml-2" : ""}
              size="sm"
              // style={{ width: "15px", height: "15px" }}
            >
              <span className="visually-hidden"></span>
            </Spinner>
          ) : null}
        </Button>
        <Button variant="secondary" onClick={handleRedirect}>
          {paragraph ? "Go back" : "Go to Cart"}
          {loading == "go-to-cart" ? (
            <Spinner
              animation="grow"
              role="status"
              className={!isMobile ? "ml-2" : ""}
              size="sm"
              // style={{ width: "15px", height: "15px" }}
            >
              <span className="visually-hidden"></span>
            </Spinner>
          ) : null}
        </Button>
        {/* </div> */}
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    apiData: state.apiData,
    globalData: state.globalData,
    profile: state.profile,
    cart: state.cart,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCartStage: (stage) => {
      dispatch(setCartStage(stage));
    },
    setCartProducts: (products) => {
      dispatch(setCartProducts(products));
    },
    setCartJobItems: (jobs) => {
      dispatch(setCartJobItems(jobs));
    },
    setCartWorkshop: (data) => {
      dispatch(setCartWorkshop(data));
    },
    setCartAddedAddons: (data) => {
      dispatch(setCartAddedAddons(data));
    },
    setCartAddedPackages: (data) => {
      dispatch(setCartAddedPackages(data));
    },
    setShowMobileIstand: (flag) => {
      dispatch(setShowMobileIstand(flag));
    },
    setCartJourney: (stage) => {
      dispatch(setCartJourney(stage));
    },
    setLayer: (status) => {
      dispatch(setLayer(status));
    },
    getWorkshopsProductCategory: (id, mid) => {
      dispatch(getWorkshopsProductCategory(id, mid));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmCartChange);
