import API from "api";
import React, { useEffect, useState } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { connect } from "react-redux";
import Select from "react-select";
import { toast } from "react-hot-toast";
import { useRouter } from "next/router";
import { useMemo } from "react";
import { setSelctedId } from "redux/actions/globalAction";
import { getOfferCategories, getVariants } from "redux/actions/apiActions";
import CommonPopup from "components/common/CommonPopup";
import axios from "axios";

const ServiceSelection = ({
  apiData,
  handleServiceSelection = () => {},
  handleCloseLead = () => {},
  setSelctedId,
  getOfferCategories,
  globalData,
}) => {
  const [serviceList, setServiceList] = useState([]);
  const [defaultPrice, setDefaultPrice] = useState(null);
  const [loading, setloading] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [popUpSlug, setPopUpSlug] = useState();
  const [option, setOption] = useState(0);
  const [offerList, setOfferList] = useState([]);
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    control,
    formState: { errors, isSubmitting },
  } = useForm();
  const router = useRouter();
  const [workshopCategories, setworkshopCategories] = useState([]);
  const [defaultValue, setDefaultService] = useState("oil-change");

  const resetFormData = () => {
    setValue("optionId", "");
    setValue("LeadOfferCategoryId", "");
    setValue("JourneyCategoryId", "");
  };

  const OptionID = watch("optionId");

  const resetForm = () => {
    setOption(0);
    resetFormData();
  };

  useEffect(() => {
    if (OptionID) {
      setOption(OptionID?.value || 0);
    }
  }, [OptionID]);

  const PriceList = [
    { name: "Oil Change", price: 165 },
    { name: "AC Service", price: 150 },
    { name: "Inspection", price: 125 },
    { name: "Mobile Mechanic", price: 150 },
    { name: "Breakdown", price: 100 },
    { name: "Car Wash At Home", price: 55 },
    { name: "Car Care", price: 200 },
    { name: "Battery", price: 125 },
    { name: "Repair", price: 100 },
    { name: "Service Contract", price: 710 },
    { name: "Tires & Wheels", price: 100 },
  ];

  const selectedLabel = watch("JourneyCategoryId");

  const openPopupFunction = (slug) => {
    setPopUpSlug(slug);
    setIsPopupOpen(true);
  };

  const closePopupFunction = () => {
    setIsPopupOpen(false);
  };

  const handleAdd = async (data) => {
    const URL =
      typeof window !== "undefined"
        ? window?.location?.href
        : `https://openbonnet.com/${router?.asPath}`;
    setloading(true);
    let { Brand, optionId, ...Rest } = data;
    const ID = data.JourneyCategoryId?.data?.ID;
    const e = JSON.parse(localStorage.getItem("start-here-modal"));
    const payload = {
      ...Rest,
      JourneyCategoryId: data.JourneyCategoryId?.value || null,
      LeadOfferCategoryId: data.LeadOfferCategoryId?.value || null,
      CarCompanyId: e?.brandId || null,
      CarModelId: e?.ID || null,
      Type: "Start-Here",
      RequestPostUrl: URL,
    };

    await API.post(`v2/api/leads`, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then(async (res) => {
        const {
          data: { Data: result },
        } = res;

        let slug;
        if (router.pathname != `/services/[...slug]`) {
          slug = `services/dubai-dubai-silicon-oasis/${
            result?.JourneyVariantSlug
          }/${result?.CarModelSlug ?? "car"}`;
        } else {
          slug = `dubai-dubai-silicon-oasis/${result?.JourneyVariantSlug}/${
            result?.CarModelSlug ?? "car"
          }`;
        }

        if (payload.CarModelId === null) {
          openPopupFunction(slug);
          return;
        }

        localStorage.setItem("lead-submitted", true);
        localStorage.setItem("phoneNumber", data.PhoneNumber);
        // reset();
        if (router.pathname == `/services/[...slug]`) {
          setloading(false);
          setSelctedId(ID);
          const workshopBasedJourney =
            sessionStorage.getItem("WORKSHOP_JOURNEY");
          sessionStorage.removeItem("WORKSHOP_JOURNEY");
          if (workshopBasedJourney) {
            const workshop = localStorage.getItem("workshop-selected");
            const selectedWorkshop = JSON.parse(workshop);
            getVariants(
              data.JourneyCategoryId?.value || null,
              e.ID,
              selectedWorkshop?.Id
            );
            // window.location.href =
            //   window.location.origin +
            //   workshopBasedJourney.replace("car", result?.CarModelSlug);
            // return;
          }
          router.push(slug);
          handleServiceSelection();
          handleCloseLead();
        } else {
          router.push(slug);
          setTimeout(() => {
            handleServiceSelection();
          }, 3000);
        }
      })
      .catch((err) => {
        console.log("Error ", err);
        setloading(false);
        toast.dismiss();
        toast.error("Something went wrong! Please retry.");
      });
  };

  useEffect(() => {
    if (localStorage.getItem("phoneNumber")) {
      setValue("PhoneNumber", JSON.parse(localStorage.getItem("phoneNumber")));
    }
    return () => {
      handleCloseLead();
    };
  }, []);

  const getDefaultValue = () => {
    const data = PriceList?.filter((item) => item.name == selectedLabel?.label);
    setDefaultPrice(data[0]);
  };

  const ChoiceList = [
    {
      label: "Services",
      value: 1,
    },
    {
      label: "Offers",
      value: 2,
    },
  ];

  useEffect(() => {
    getDefaultValue();
  }, [selectedLabel]);

  useEffect(() => {
    getOfferCategories();
  }, []);

  useEffect(() => {
    const ServiceListOptions =
      apiData?.categories?.map((service) => {
        return {
          label: service.Name,
          value: service.ID,
          Slug: service.Slug,
          data: service,
        };
      }) || [];

    setServiceList(ServiceListOptions);
    if (globalData.currentCategory && globalData.currentCategory.length) {
      const immutableData = globalData.currentCategory
        .filter((item) => item.ID !== 8)
        .map((service) => {
          return {
            label: service.Name,
            value: service.ID,
            Slug: service.Slug,
            data: service,
          };
        });
      setworkshopCategories(immutableData);
    }
    const OfferCategoriesOptions = apiData?.offerCategories?.map((item) => {
      return { label: item.SelectItemDescription, value: item.Id, data: item };
    });
    setOfferList(OfferCategoriesOptions);
  }, [apiData, globalData.currentCategory]);

  const SelectionOfferDropdown = ({
    field: { onChange, onBlur, value, name, ref },
    fieldState: { invalid, isTouched, isDirty, error },
    formState,
  }) => {
    return (
      <Select
        options={offerList}
        onBlur={onBlur}
        placeholder="Select offer"
        isSearchable={false} // Remove search feature
        menuPlacement="bottom"
        onChange={(e) => {
          onChange(e);
        }}
        value={value}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
        })}
        styles={{
          control: (styles) => ({
            ...styles,
            height: 45,
            borderRadius: "5px",
            color: "black",
          }),
          placeholder: (styles) => ({
            ...styles,
          }),
          menuList: (styles) => ({
            ...styles,
            maxHeight: "200px",
            border: "2px solid #2682fc",
          }),
          dropdownIndicator: (styles) => ({
            ...styles,
            margin: "5px",
            width: "35px",
            height: "35px",
          }),
        }}
      />
    );
  };

  const SelctionOptionDropDOwn = ({
    field: { onChange, onBlur, value, name, ref },
    fieldState: { invalid, isTouched, isDirty, error },
    formState,
  }) => {
    return (
      <Select
        options={ChoiceList}
        onBlur={onBlur}
        placeholder="What are you looking for?"
        isSearchable={false} // Remove search feature
        menuPlacement="bottom"
        onChange={(e) => {
          onChange(e);
        }}
        value={value}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
        })}
        styles={{
          control: (styles) => ({
            ...styles,
            height: 45,
            borderRadius: "5px",
            color: "black",
          }),
          menuList: (styles) => ({
            ...styles,
            border: "2px solid #2682fc",
          }),
          placeholder: (styles) => ({
            ...styles,
          }),
          dropdownIndicator: (styles) => ({
            ...styles,
            margin: "5px",
            width: "35px",
            height: "35px",
          }),
        }}
      />
    );
  };

  const SelectionDropdown = ({
    field: { onChange, onBlur, value, name, ref },
    fieldState: { invalid, isTouched, isDirty, error },
    formState,
  }) => {
    return (
      <Select
        key={defaultValue}
        options={
          workshopCategories && workshopCategories.length
            ? workshopCategories
            : serviceList
        }
        defaultValue={defaultValue}
        onBlur={onBlur}
        placeholder="Select Service"
        isSearchable={false} // Remove search feature
        menuPlacement="bottom"
        onChange={(e) => {
          onChange(e);
        }}
        value={value}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
        })}
        styles={{
          control: (styles) => ({
            ...styles,
            height: 45,
            borderRadius: "5px",
            color: "black",
          }),
          menuList: (styles) => ({
            ...styles,
            border: "2px solid #2682fc",
            maxHeight: "200px",
            zIndex: 99999,
          }),
          placeholder: (styles) => ({
            ...styles,
          }),
          dropdownIndicator: (styles) => ({
            ...styles,
            margin: "5px",
            width: "35px",
            height: "35px",
          }),
        }}
      />
    );
  };

  useEffect(() => {
    const init = async () => {
      const service_path =
        router.pathname == "/services/[...slug]"
          ? router?.query?.slug?.length > 1
            ? router?.query?.slug[1]
            : localStorage.getItem("selected-service")
            ? localStorage.getItem("selected-service")
            : "oil-change"
          : "oil-change";
      const { data } = await axios.get(
        `${process.env.NEXT_PUBLIC_API_URL}v2/api/slugs/category-variant/${service_path}`,
        {
          headers: {},
        }
      );
      const selectedService = serviceList.filter(
        (item) => item.value === data?.Data?.JouneryCategoryId
      )[0];
      console.log("sdfsdklfksdf;lksdf", selectedService);
      if (selectedService) {
        setOption(1);
        setValue("optionId", {
          label: "Services",
          value: 1,
        });
      }
      setValue("JourneyCategoryId", selectedService);

      setDefaultService(selectedService);
    };

    init();
  }, [router.query, serviceList]);
  return (
    <div
      style={{ width: "100%", margin: "0 auto", padding: "0px 25px" }}
      className="ServiceSelectionBox"
    >
      <div>
        <p style={{ fontSize: "24px", color: "black", fontWeight: "bold" }}>
          {" "}
          Service Selection{" "}
        </p>
        <p
          style={{
            fontSize: "15px",
            color: "#808080ba",
            marginBottom: "35px",
            lineHeight: "20px",
          }}
        >
          {" "}
          Select a service and provide your phone number to see pricing.
        </p>
        {option == 0 ? (
          <>
            <div className="select-control exp-margin">
              <Controller
                control={control}
                name="optionId"
                rules={{ required: true }}
                render={SelctionOptionDropDOwn}
              />
              {errors.optionId && (
                <span className="text-danger">
                  Please Select The Respective Option
                </span>
              )}
            </div>
            <div
              className={
                option == 0
                  ? "hp-request-rest-btn request-now-btn-gray"
                  : "hp-request-rest-btn"
              }
              onClick={() => resetForm()}
            >
              <span>Reset</span>
            </div>
          </>
        ) : null}

        {option == 1 ? (
          <>
            <div className="select-control exp-margin">
              {serviceList && serviceList.length ? (
                <Controller
                  control={control}
                  name="JourneyCategoryId"
                  rules={{ required: true }}
                  render={SelectionDropdown}
                />
              ) : (
                <Controller
                  control={control}
                  name="JourneyCategoryId"
                  rules={{ required: true }}
                  render={SelectionDropdown}
                />
              )}
              {errors.JourneyCategoryId && (
                <span className="text-danger">Please Select The Service</span>
              )}
            </div>
            <div className="hp-request-rest-btn" onClick={() => resetForm()}>
              <span>Reset</span>
            </div>
          </>
        ) : null}
        {option == 2 ? (
          <>
            <div className="select-control exp-margin">
              <Controller
                control={control}
                name="LeadOfferCategoryId"
                rules={{ required: true }}
                render={SelectionOfferDropdown}
              />
              {errors.LeadOfferCategoryId && (
                <span className="text-danger">Please Select The Offer</span>
              )}
            </div>
            <div
              className={
                option == 0
                  ? "hp-request-rest-btn request-now-btn-gray"
                  : "hp-request-rest-btn"
              }
              onClick={() => resetForm()}
            >
              <span>Reset</span>
            </div>
          </>
        ) : null}
        <div></div>
        <div style={{ width: "100%" }} className="exp-margin">
          <div className="d-flex">
            <div
              className="d-flex align-items-center px-2"
              style={{
                border: "1px solid hsl(0, 0%, 80%)",
                borderRadius: "5px",
                borderTopRightRadius: "0px",
                borderBottomRightRadius: "0px",
                borderRight: "0px",
                fontWeight: "bold",
                color: "black",
              }}
            >
              <p className="text-black d-flex align-items-center">
                <svg
                  width={31}
                  height={30}
                  viewBox="0 0 31 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.0083 29.8114C23.2886 29.8114 30.0011 23.1539 30.0011 14.9414C30.0011 6.72885 23.2886 0.0712891 15.0083 0.0712891C6.72809 0.0712891 0.015625 6.72885 0.015625 14.9414C0.015625 23.1539 6.72809 29.8114 15.0083 29.8114Z"
                    fill="#F0F0F0"
                  />
                  <path
                    d="M8.55469 20.2178L9.86998 29.0672C13.6317 30.4476 17.792 30.2911 21.4374 28.6319C25.0828 26.9727 27.9154 23.9465 29.3132 20.2178H8.55469Z"
                    fill="black"
                  />
                  <path
                    d="M8.55469 9.78162L9.86998 0.932157C13.6314 -0.447474 17.791 -0.290494 21.4359 1.36864C25.0807 3.02778 27.9129 6.05349 29.3107 9.78162H8.55469Z"
                    fill="#6DA544"
                  />
                  <path
                    d="M8.06025e-10 14.9987C-3.18685e-05 18.0616 0.944996 21.0512 2.70814 23.5658C4.47128 26.0805 6.96809 27.9997 9.86317 29.0658V0.932129C6.96817 1.99817 4.47142 3.91736 2.70829 6.4319C0.945148 8.94643 7.18711e-05 11.9359 8.06027e-10 14.9987H8.06025e-10Z"
                    fill="#A2001D"
                  />
                </svg>{" "}
                +971
              </p>
            </div>
            <input
              type="number"
              style={{
                borderTopLeftRadius: "0px",
                borderBottomLeftRadius: "0px",
                border: "1px solid hsl(0, 0%, 80%) !important",
                color: "black",
                fontWeight: "normal",
              }}
              placeholder="Enter Mobile Number"
              className="login-input px-1 red phone-number-field"
              onPaste={(event) => {
                event.preventDefault();
                const text = event.clipboardData.getData("text/plain");
                const sanitizedText = text.replace(/[^0-9]/g, ""); // remove spaces
                document.execCommand("insertText", false, sanitizedText);
              }}
              onInput={(e) => {
                if (e.target.value.length > e.target.maxLength)
                  e.target.value = e.target.value.slice(0, e.target.maxLength);
                e.target.value = e.target.value.replace(/[^0-9]/g, "");
              }}
              maxLength={9}
              {...register("PhoneNumber", {
                required: true,
                validate: (value) => {
                  return (
                    value[0] == "5" &&
                    (value[1] === "5" ||
                      value[1] === "2" ||
                      value[1] === "0" ||
                      value[1] === "6" ||
                      value[1] === "4" ||
                      value[1] === "8") &&
                    value.length === 9
                  );
                },
              })}
            />
          </div>
          {errors.PhoneNumber && (
            <span className="text-danger">Enter valid phone number.</span>
          )}
        </div>
        {option == 1 && defaultPrice ? (
          <p
            style={{
              fontSize: "14px",
              color: "#f56223",
              margin: "0px",
            }}
          >{`Job Prices For Your Selected Service Starts From AED ${
            defaultPrice?.price || PriceList[0]?.price
          }`}</p>
        ) : null}

        <Row className="justify-content-center mb-0">
          <Col xs={12}>
            <button
              className="otp-button bg-black d-flex align-items-center w-100 justify-content-center mt-3"
              style={{
                borderRadius: "10px",
                padding: "14px",
                fontSize: "16px",
              }}
              disabled={isSubmitting || loading}
              onClick={handleSubmit(handleAdd)}
            >
              View Prices For Your Car
              {isSubmitting || loading ? (
                <Spinner
                  animation="grow"
                  role="status"
                  className="ml-2"
                  size="sm"
                >
                  <span className="visually-hidden"></span>
                </Spinner>
              ) : null}
            </button>
          </Col>
        </Row>
      </div>

      <CommonPopup
        popUpSlug={popUpSlug}
        show={isPopupOpen}
        onHide={closePopupFunction}
        message="Your inquiry for an unlisted car model has been forwarded to our Service Advisor. We will contact you shortly for further details."
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    apiData: state.apiData,
    globalData: state.globalData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSelctedId: (stage) => {
      dispatch(setSelctedId(stage));
    },
    getOfferCategories: (stage) => {
      dispatch(getOfferCategories(stage));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ServiceSelection);
