export const FETCH_CATEGORY = "FETCH_CATEGORY";
export const FETCH_COORDINATES = "FETCH_COORDINATES";
export const GET_ERROR = "GET_ERROR";
export const GET_VARIANT = "GET_VARIANT";
export const FETCH_CAR_BRAND = "FETCH_CAR_BRAND";
export const FETCH_CAR_MODEL = "FETCH_CAR_MODEL";
export const EMIRATES = "EMIRATES";
export const EMIRATES_AREA = "EMIRATES_AREA";
export const OB_POINT_GENERIC = "OB_POINT_GENERIC";
export const OB_POINT_JOURNEY_CATEGORY = "OB_POINT_JOURNEY_CATEGORY";
export const JOB_SECTION = "JOB_SECTION";
export const CATEGORY_BY_SECTION = "CATEGORY_BY_SECTION";
export const WORKSHOPS = "WORKSHOPS";
export const WORKSHOPS_ADDONS = "WORKSHOPS_ADDONS";
export const WORKSHOPS_ADDONS_BY_VARIANT = "WORKSHOPS_ADDONS_BY_VARIANT";
export const WORKSHOPS_WORKING_HOUR = "WORKSHOPS_WORKING_HOUR";
export const WORKSHOPS_JOURNEY_CATEGORY = "WORKSHOPS_JOURNEY_CATEGORY";
export const WORKSHOPS_JOURNEY_VARIANT = "WORKSHOPS_JOURNEY_VARIANT";
export const WORKSHOPS_JOB_WITH_VARIANT = "WORKSHOPS_JOB_WITH_VARIANT";
export const JOB_SEARCH = "JOB_SEARCH";
export const PACKAGES = "PACKAGES";
export const PURCHASE_ORDER = "PURCHASE_ORDER";
export const CUSTOMER_PURCHASE_ORDER = "CUSTOMER_PURCHASE_ORDER";
export const CUSTOMER_QUOTATION_REQUEST = "CUSTOMER_QUOTATION_REQUEST";
export const WORKSHOP_AMENITIES = "WORKSHOP_AMENITIES";
export const TOP_SELLING = "TOP_SELLING";
export const CAR_BRAND_MODAL = "CAR_BRAND_MODAL";
export const WORKSHOP_FILTER_MAP = "WORKSHOP_FILTER_MAP";
export const LOCATION_DATA = "LOCATION_DATA";
export const CART_DATA = "CART_DATA";
export const SERVICE = "SERVICE";
export const ADDON = "ADDON";
export const LOGIN_DATA = "LOGIN_DATA";
export const USER_AUTH = "USER_AUTH";
export const BRANDS = "BRANDS";
export const WORKSHOPS_PRODUCT_CATEGORY = "WORKSHOPS_PRODUCT_CATEGORY";
export const PROFILE = "PROFILE";
export const FETCH_AVATAR = "FETCH_AVATAR";
export const FETCH_ADDRESS = "FETCH_ADDRESS";
export const FETCH_BILLING_DETAILS = "FETCH_BILLING_DETAILS";
FETCH_BILLING_DETAILS
export const FETCH_CARS = "FETCH_CARS";
export const FETCH_RECOMMENDED_WORKSHOP = "FETCH_RECOMMENDED_WORKSHOP";
export const FETCH_RECOMMENDED_OB_ASSURED_WORKSHOP =
  "FETCH_RECOMMENDED_OB_ASSURED_WORKSHOP";
export const FETCH_RECOMMENDED_BY_BRAND_WORKSHOP =
  "FETCH_RECOMMENDED_BY_BRAND_WORKSHOP";
export const SET_LOCATION = "SET_LOCATION";
export const SET_CART_STAGE = "SET_CART_STAGE";
export const SET_CART_WORKSHOP = "SET_CART_WORKSHOP";
export const FETCH_CART_WORKSHOP = "FETCH_CART_WORKSHOP";
export const SET_CART_PRODUCTS = "SET_CART_PRODUCTS";
export const FETCH_CART_PRODUCTS = "FETCH_CART_PRODUCTS";
export const SET_CART_ADDONS = "SET_CART_ADDONS";
export const FETCH_CART_ADDONS = "FETCH_CART_ADDONS";
export const SET_CART_MEMBERSHIP = "SET_CART_MEMBERSHIP";
export const SET_CART_JOB_ITEMS = "SET_CART_JOB_ITEMS";
export const FETCH_CART_JOBS_ITEMS = "FETCH_CART_JOBS_ITEMS";
export const SET_CART_DETAILS = "SET_CART_DETAILS";
export const SET_CART_PROMOTIONS = "SET_CART_PROMOTIONS";
export const SET_CART_APPLIED_OFFER = "SET_CART_APPLIED_OFFER";
export const SET_CART_PICKUP_DROP_PRICE = "SET_CART_PICKUP_DROP_PRICE";
export const SET_CART_APPLIED_PICKUP_DROP_PRICE =
  "SET_CART_APPLIED_PICKUP_DROP_PRICE";
export const FETCH_LEVEL_UP_MEMBERSHIP = "FETCH_LEVEL_UP_MEMBERSHIP";
export const SET_CART_PACKAGES = "SET_CART_PACKAGES";
export const FETCH_DEFAULT_WORKSHOP = "FETCH_DEFAULT_WORKSHOP";
export const SET_CART_SELECTED_PICKUP_DROP = "SET_CART_SELECTED_PICKUP_DROP";
export const TYRE_SELECTION_ITEMS = "TYRE_SELECTION_ITEMS";
export const SET_CART_JOURNEY = "SET_CART_JOURNEY";
export const SET_CART_TYRE_PARAMETER = "SET_CART_TYRE_PARAMETER";
export const BATTERY_SELECTION_ITEMS = "BATTERY_SELECTION_ITEMS";
export const SET_CART_BATTERY_PARAMETER = "SET_CART_BATTERY_PARAMETER";
export const SET_LOGIN_POPUP = "SET_LOGIN_POPUP";
export const SET_OB_POINTS = "SET_OB_POINTS";
export const APPLY_OB_POINTS = "APPLY_OB_POINTS";
export const FETCH_PROFILE_ORDERS = "FETCH_PROFILE_ORDERS";
export const FETCH_PROFILE_ACTIVE_ORDERS = "FETCH_PROFILE_ACTIVE_ORDERS";
export const FETCH_PROFILE_FOR_ACTIVE_ORDERS = "FETCH_PROFILE_FOR_ACTIVE_ORDERS";
export const FETCH_PROFILE_ACTIVE_QUOTATIONS = "FETCH_PROFILE_ACTIVE_QUOTATIONS";
export const SET_SELECTED_AREA = "SET_SELECTED_AREA";

export const FETCH_PROFILE_PACKAGES_ORDERS = "FETCH_PROFILE_PACKAGES_ORDERS";
export const SET_REMOVED_SPARES = "SET_REMOVED_SPARES";
export const SET_REMOVED_LABOUR = "SET_REMOVED_LABOUR";
export const FETCH_PROFILE_QUOTATIONS = "FETCH_PROFILE_QUOTATIONS";
export const SET_ORDER_SUCCESS = "SET_ORDER_SUCCESS";
export const SET_CAR_POPUP = "SET_CAR_POPUP";
export const LOWEST_PACKAGES = "LOWEST_PACKAGES";
export const FETCH_HOME_CAR_BRAND = "FETCH_HOME_CAR_BRAND";
export const FETCH_NOTIFICATION = "FETCH_NOTIFICATION";
export const SET_SELECTED_QUOTATION_FORM = "SET_SELECTED_QUOTATION_FORM";
export const SET_LOADER = "SET_LOADER";
export const SET_SHOW_MOBILE_ISLAND = "SET_SHOW_MOBILE_ISLAND";
export const FETCH_CUSTOM_PLAN_LIST = "FETCH_CUSTOM_PLAN_LIST";
export const SET_TOUR_BOX = "SET_TOUR_BOX";
export const FETCH_WORKSHOP_IMAGES = "FETCH_WORKSHOP_IMAGES";
export const FETCH_PROFILE_COMPLETION = "FETCH_PROFILE_COMPLETION";
export const FETCH_RELATIONSHIP_SUMMARY = "FETCH_RELATIONSHIP_SUMMARY";
export const FETCH_OB_POINTS = "FETCH_OB_POINTS";
export const UPDATE_PROFILE_ORDERS = "UPDATE_PROFILE_ORDERS";
export const TOGGLE_ADDRESS_LOADING = "TOGGLE_ADDRESS_LOADING";
export const TOGGLE_CAR_LOADING = "TOGGLE_ADDRESS_LOADING";
export const TOGGLE_ORDER_LOADING = "TOGGLE_ORDER_LOADING";
export const TOGGLE_PACKAGE_LOADING = "TOGGLE_PACKAGE_LOADING";
export const TOGGLE_REPAIR_INSURNACE_LOADING = "TOGGLE_REPAIR_INSURNACE_LOADING";
export const LOADING_PACKAGES = "LOADING_PACKAGES";
export const TOGGLE_MY_ACCOUNT = "TOGGLE_MY_ACCOUNT";
export const RESET_STORE = "RESET_STORE";
export const GET_NEW_TYRES_VARIANT = "GET_NEW_TYRES_VARIANT";

export const RESET_WORKSHOP = "RESET_WORKSHOP";
export const RESET_CART = "RESET_CART";
export const ADD_ORDER_ESTIMATE = "ADD_ORDER_ESTIMATE";
export const UPDATE_ORDER_ESTIMATE = "UPDATE_ORDER_ESTIMATE";
export const TOGGLE_OBPOINTS_LOADING = "TOGGLE_OBPOINTS_LOADING";
export const TOGGLE_QUOTATIONS_LOADING = "TOGGLE_QUOTATIONS_LOADING";
export const SET_LAYER = "SET_LAYER";
export const SET_OB_EARN_POINTS = "SET_OB_EARN_POINTS";
export const SET_OB_BURN_POINTS = "SET_OB_BURN_POINTS";
export const SET_APPLIED_PROMOTIONS = "SET_APPLIED_PROMOTIONS";
export const REMOVE_APPLIED_PROMOTIONS = "REMOVE_APPLIED_PROMOTIONS";
export const ADDDED_PICKUP_DROP_PRICE = "ADDDED_PICKUP_DROP_PRICE";
export const SHOW_LOADER = "SHOW_LOADER";
export const UPDATE_CART_JOB_ITEMS = "UPDATE_CART_JOB_ITEMS";
export const UPDATE_CART_PRODUCTS = "UPDATE_CART_PRODUCTS";
export const SET_JOB_HISTORY = "SET_JOB_HISTORY";
export const TOGGLE_JOB_HISTORY_LOADING = "TOGGLE_JOB_HISTORY_LOADING";
export const FETCH_JOB_HISTORY = "FETCH_JOB_HISTORY";
export const FETCH_REPAIR_INSURANCE = "FETCH_REPAIR_INSURANCE";
export const FETCH_ORDER_INVOICE = "FETCH_ORDER_INVOICE";
export const TOGGLE_REQUEST_FORM = "TOGGLE_REQUEST_FORM";
export const SET_NEW_CAR_SIZE = "SET_NEW_CAR_SIZE";
export const FETCH_SUPER_CAR_LEAD_ITEMS = "FETCH_SUPER_CAR_LEAD_ITEMS";
export const FETCH_SUPER_CAR_BRAND = "FETCH_SUPER_CAR_BRAND";
export const TOGGLE_INVOICE_LOADING = "TOGGLE_INVOICE_LOADING";
export const TOGGLE_LOCATION = "TOGGLE_LOCATION";
export const TOGGLE_CHOOSE_CAR = "TOGGLE_CHOOSE_CAR";
export const TOGGLE_MANAGE_CAR = "TOGGLE_MANAGE_CAR";
export const TOGGLE_LOGIN = "TOGGLE_LOGIN";
export const TOGGLE_SIGNUP = "TOGGLE_SIGNUP";
export const TOGGLE_SEARCH = "TOGGLE_SEARCH";
export const TOGGLE_SOS_FORM = "TOGGLE_SOS_FORM";
export const TOGGLE_NOTIFICATION = "TOGGLE_NOTIFICATION";
export const SET_ACCESS_LOCATION = "SET_ACCESS_LOCATION";
export const SET_SELECTED_ID = "SET_SELECTED_ID";
export const SET_CURRENT_CATEGORY = "SET_CURRENT_CATEGORY";
export const SET_UPDATE_PROFILE = "SET_UPDATE_PROFILE";
export const SET_SAVE_LOCATION = "SET_SAVE_LOCATION";
export const SET_OTP_SCREEN = "SET_OTP_SCREEN";
export const SHOW_JOB_SCREEN = "SHOW_JOB_SCREEN";
export const FORMAT_RESET_STORE = "FORMAT_RESET_STORE";
export const TOGGLE_HIDE_HEADER = "TOGGLE_HIDE_HEADER";
export const ADD_TO_COMPARE = "ADD_TO_COMPARE";
export const REMOVE_FROM_COMPARE = "REMOVE_FROM_COMPARE";
export const SET_PROFILE_LOADING = "SET_PROFILE_LOADING";
export const SET_APPLIED_CART_OFFERS = "SET_APPLIED_CART_OFFERS";
export const GET_OFFER_CATEGORIES = "GET_OFFER_CATEGORIES";
export const FETCH_HOMEPAGE_BANNER = "FETCH_HOMEPAGE_BANNER";
export const FETCH_REQUESTPAGE_BANNER = "FETCH_REQUESTPAGE_BANNER";

export const FETCH_LEVEL_UP_MEMBERSHIP_STATUS = "FETCH_LEVEL_UP_MEMBERSHIP_STATUS"
export const FETCH_LEVEL_UP_MEMBERSHIP_STATUS_LOADING = "FETCH_LEVEL_UP_MEMBERSHIP_STATUS_LOADING"