import { route } from "next/dist/server/router";
import {
  FETCH_CATEGORY,
  FETCH_COORDINATES,
  GET_VARIANT,
  GET_ERROR,
  FETCH_CAR_BRAND,
  FETCH_CAR_MODEL,
  EMIRATES,
  OB_POINT_GENERIC,
  OB_POINT_JOURNEY_CATEGORY,
  EMIRATES_AREA,
  JOB_SECTION,
  CATEGORY_BY_SECTION,
  WORKSHOPS,
  WORKSHOPS_ADDONS,
  WORKSHOPS_WORKING_HOUR,
  WORKSHOPS_JOURNEY_CATEGORY,
  WORKSHOPS_JOURNEY_VARIANT,
  WORKSHOPS_ADDONS_BY_VARIANT,
  WORKSHOPS_JOB_WITH_VARIANT,
  JOB_SEARCH,
  PACKAGES,
  PURCHASE_ORDER,
  CUSTOMER_PURCHASE_ORDER,
  CUSTOMER_QUOTATION_REQUEST,
  WORKSHOP_AMENITIES,
  TOP_SELLING,
  WORKSHOP_FILTER_MAP,
  BRANDS,
  WORKSHOPS_PRODUCT_CATEGORY,
  FETCH_AVATAR,
  FETCH_RECOMMENDED_WORKSHOP,
  FETCH_RECOMMENDED_OB_ASSURED_WORKSHOP,
  FETCH_RECOMMENDED_BY_BRAND_WORKSHOP,
  FETCH_LEVEL_UP_MEMBERSHIP,
  FETCH_DEFAULT_WORKSHOP,
  LOWEST_PACKAGES,
  FETCH_HOME_CAR_BRAND,
  FETCH_CUSTOM_PLAN_LIST,
  FETCH_WORKSHOP_IMAGES,
  LOADING_PACKAGES,
  FETCH_SUPER_CAR_LEAD_ITEMS,
  FETCH_SUPER_CAR_BRAND,
  GET_OFFER_CATEGORIES,
  FETCH_HOMEPAGE_BANNER,
  FETCH_REQUESTPAGE_BANNER,
  GET_NEW_TYRES_VARIANT,
} from "./types";
import API from "api";

let header = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

export const getCategory = () => async (dispatch) => {
  dispatch({
    type: WORKSHOPS_PRODUCT_CATEGORY,
    payload: [],
  });
  try {
    const { data } = await API.get(`v2/api/journey-categories`, {
      headers: header,
    });

    dispatch({
      type: FETCH_CATEGORY,
      payload: data.Data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERROR,
      payload: error,
    });
  }
};

export const getCoordinates = (lat, lang, size) => async (dispatch) => {
  if (lat == 0 && lang == 0) {
    return;
  }
  try {
    let routeName;
    if (typeof window !== "undefined") {
      routeName = window.location.pathname;
    }
    if (routeName == "/workshop-network") {
      size = 100;
    }
    const { data: res } = await API.get(
      `v2/api/nearest/workshops?lat=${lat}&lng=${lang}&size=${size}`,
      {
        headers: header,
      }
    );
    dispatch({
      type: FETCH_COORDINATES,
      payload: res.Data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERROR,
      payload: error,
    });
  }
};

export const getVariants =
  (
    id,
    mId = null,
    WId = null,
    EmirateId = null,
    EmirateAreaId = null,
    CarCompanyId = null
  ) =>
  async (dispatch) => {
    if (sessionStorage.getItem("isWorkshopCollection") ? WId : true) {
      dispatch({
        type: GET_VARIANT,
        payload: [],
      });
      try {
        const { data: res } = await API.get(
          `v2/api/journey-categories/${id}/journey-variant?carModelId=${mId}&EmirateId=${EmirateId}&EmirateAreaId=${EmirateAreaId}&CarCompanyId=${CarCompanyId}&WorkshopId=${WId}`,
          {
            headers: header,
          }
        );
        dispatch({
          type: GET_VARIANT,
          payload: res.Data,
        });
      } catch (error) {
        dispatch({
          type: GET_ERROR,
          payload: error,
        });
      }
    }
  };
export const getNewTyresVariants = (width, height, rim) => async (dispatch) => {
  dispatch({
    type: GET_NEW_TYRES_VARIANT,
    payload: [],
  });
  try {
    const { data: res } = await API.get(
      `${process.env.NEXT_PUBLIC_API_URL}v2/api/products/web/tyre-products?Width=${width}&Height=${height}&Rim=${rim}`,
      {
        headers: header,
      }
    );
    dispatch({
      type: GET_NEW_TYRES_VARIANT,
      payload: res.Data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERROR,
      payload: error,
    });
  }
};
export const getNewTyresVariantsByModel = (carModeId) => async (dispatch) => {
  dispatch({
    type: GET_NEW_TYRES_VARIANT,
    payload: [],
  });
  try {
    const { data: res } = await API.get(
      `${process.env.NEXT_PUBLIC_API_URL}v2/api/products/web/tyre-products?CarModelId=${carModeId}`,
      {
        headers: header,
      }
    );

    dispatch({
      type: GET_NEW_TYRES_VARIANT,
      payload: res.Data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERROR,
      payload: error,
    });
  }
};

export const getBrand =
  (IsSuperCar = false) =>
  async (dispatch) => {
    try {
      const { data: res } = await API.get(`v2/api/car/brand`, {
        headers: header,
      });

      dispatch({
        type: FETCH_CAR_BRAND,
        payload: res.Data,
      });
    } catch (error) {
      dispatch({
        type: GET_ERROR,
        payload: error,
      });
    }
  };

export const getSuperCarBrand = (IsSuperCar) => async (dispatch) => {
  try {
    const { data: res } = await API.get(
      `v2/api/car/brand?IsSuperCar=${IsSuperCar}`,
      {
        headers: header,
      }
    );
    dispatch({
      type: FETCH_SUPER_CAR_BRAND,
      payload: res.Data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERROR,
      payload: error,
    });
  }
};

export const getHomeBrand = () => async (dispatch) => {
  try {
    const { data: res } = await API.get(`v2/api/car/brand/ob-serve`, {
      headers: header,
    });
    dispatch({
      type: FETCH_HOME_CAR_BRAND,
      payload: res.Data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERROR,
      payload: error,
    });
  }
};

export const getModel = (id) => async (dispatch) => {
  try {
    const { data: res } = await API.get(`v2/api/car/brand/${id}/model`, {
      headers: header,
    });
    dispatch({
      type: FETCH_CAR_MODEL,
      payload: res.Data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERROR,
      payload: error,
    });
  }
};

export const getEmirates = () => async (dispatch) => {
  try {
    const { data: res } = await API.get(`v2/api/emirates`, {
      headers: header,
    });
    dispatch({
      type: EMIRATES,
      payload: res.Data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERROR,
      payload: error,
    });
  }
};

export const getOfferCategories = () => async (dispatch) => {
  try {
    const { data: res } = await API.get(`V2/api/leads/offer-categories`, {
      headers: header,
    });
    dispatch({
      type: GET_OFFER_CATEGORIES,
      payload: res.Data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERROR,
      payload: error,
    });
  }
};

export const fetchHomePageBanner = (code) => async (dispatch) => {
  try {
    const { data: res } = await API.get(
      `v2/api/banners?BannerTypeCode=${code}`,
      {
        headers: header,
      }
    );
    if (code == "REQUESTBANNER") {
      dispatch({
        type: FETCH_REQUESTPAGE_BANNER,
        payload: res.Data,
      });
    } else if (code == "HOMEBANNER") {
      dispatch({
        type: FETCH_HOMEPAGE_BANNER,
        payload: res.Data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_ERROR,
      payload: error,
    });
  }
};

export const getEmiratesArea = (id) => async (dispatch) => {
  try {
    const { data: res } = await API.get(`v2/api/emirates/${id}/areas`, {
      headers: header,
    });
    dispatch({
      type: EMIRATES_AREA,
      payload: res.Data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERROR,
      payload: error,
    });
  }
};

export const getObPointsGeneric = () => async (dispatch) => {
  try {
    const { data: res } = await API.get(`v2/api/ob-percentage/generic`, {
      headers: header,
    });
    dispatch({
      type: OB_POINT_GENERIC,
      payload: res.Data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERROR,
      payload: error,
    });
  }
};

export const getObPointsJourneyCategory = (page) => async (dispatch) => {
  try {
    const { data: res } = await API.get(
      `v2/api/ob-percentage/journey-category/${page}`,
      {
        headers: header,
      }
    );
    dispatch({
      type: OB_POINT_JOURNEY_CATEGORY,
      payload: res.Data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERROR,
      payload: error,
    });
  }
};

export const getJobSection = () => async (dispatch) => {
  try {
    const { data: res } = await API.get(`v2/api/job-sections`, {
      headers: header,
    });
    dispatch({
      type: JOB_SECTION,
      payload: res.Data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERROR,
      payload: error,
    });
  }
};

export const getCategoryBySection = (id) => async (dispatch) => {
  try {
    const { data: res } = await API.get(
      `v2/api/job-sections/${id}/journey-category`,
      {
        headers: header,
      }
    );
    dispatch({
      type: CATEGORY_BY_SECTION,
      payload: res.Data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERROR,
      payload: error,
    });
  }
};

export const getWorkshops = (size, page) => async (dispatch) => {
  try {
    const { data: res } = await API.get(
      `v2/api/nearest/workshops?size=${size}&page=${page}`,
      {
        headers: header,
      }
    );
    dispatch({
      type: WORKSHOPS,
      payload: res.Data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERROR,
      payload: error,
    });
  }
};

export const getWorkshopsAddons = (id) => async (dispatch) => {
  try {
    const { data: res } = await API.get(`v2/api/workshop/${id}/addons`, {
      headers: header,
    });
    dispatch({
      type: WORKSHOPS_ADDONS,
      payload: res.Data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERROR,
      payload: error,
    });
  }
};

export const getWorkshopsWorkingHour = (id) => async (dispatch) => {
  try {
    const response = await API.get(`v2/api/workshop/${id}/work-hours`, {
      headers: header,
    });

    dispatch({
      type: WORKSHOPS_WORKING_HOUR,
      payload: response.data.Data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERROR,
      payload: err,
    });
  }
};

export const getWorkshopsJourneyCategory = (id) => async (dispatch) => {
  dispatch({
    type: WORKSHOPS_JOURNEY_CATEGORY,
    payload: [],
  });

  try {
    const response = await API.get(`v2/api/workshop/${id}/journey-category`, {
      headers: header,
    });

    dispatch({
      type: WORKSHOPS_JOURNEY_CATEGORY,
      payload: response.data.Data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERROR,
      payload: err,
    });
  }
};

export const getWorkshopsProductCategory = (id, mId) => async (dispatch) => {
  dispatch({
    type: WORKSHOPS_PRODUCT_CATEGORY,
    payload: [],
  });

  try {
    const response = await API.get(
      `v2/api/workshop/${id}/journey-category?carModelId=${mId}`,
      {
        headers: header,
      }
    );

    dispatch({
      type: WORKSHOPS_PRODUCT_CATEGORY,
      payload: response.data.Data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERROR,
      payload: err,
    });
  }
};

export const getWorkshopsJourneyVariant =
  (id, cId, mId, EmirateId = null, EmirateAreaId = null, CarCompanyId = null) =>
  async (dispatch) => {
    dispatch({
      type: GET_VARIANT,
      payload: [],
    });

    try {
      const response = await API.get(
        `v2/api/workshop/${id}/journey-variant?journeyCategoryId=${cId}&&carModelId=${
          mId ? mId : 0
        }`,
        {
          headers: header,
        }
      );

      dispatch({
        type: GET_VARIANT,
        payload: response.data.Data,
      });
    } catch (err) {
      dispatch({
        type: GET_ERROR,
        payload: err,
      });
    }
  };

export const getWorkshopsAddonByVariant = (id, vId) => async (dispatch) => {
  const defaultPayload = [
    {
      Id: 1,
      Name: "Add-On1",
      Description: "Exceptional Performance Boost & More Fuel Economy",
      Cost: 50,
      ImgUrl: "https://bellard.org/bpg/2.png",
    },
    {
      Id: 2,
      Name: "Add-On2",
      Description: "Exceptional Performance Boost & More Fuel Economy",
      Cost: 30,
      ImgUrl: "https://bellard.org/bpg/2.png",
    },
    {
      Id: 3,
      Name: "Add-On3",
      Description: "Exceptional Performance Boost & More Fuel Economy",
      Cost: 80,
      ImgUrl: "https://bellard.org/bpg/2.png",
    },
  ];

  dispatch({
    type: WORKSHOPS_ADDONS_BY_VARIANT,
    payload: defaultPayload,
  });

  try {
    const response = await API.get(
      `v2/api/workshop/${id}/addons?variantId=${vId}`,
      {
        headers: header,
      }
    );
    dispatch({
      type: WORKSHOPS_ADDONS_BY_VARIANT,
      payload: response.data.Data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERROR,
      payload: err,
    });
  }
};

export const getWorkshopsJobWithVariant =
  (lat, lng, cId, vId) => async (dispatch) => {
    try {
      const response = await API.get(
        `v2/api/journey-variants/search/workshop?variantId=${vId}&Lat=${lat}&Lng=${lng}&CarModelId=${cId}`,
        {
          headers: header,
        }
      );

      dispatch({
        type: WORKSHOPS_JOB_WITH_VARIANT,
        payload: response.data.Data,
      });
    } catch (err) {
      dispatch({
        type: GET_ERROR,
        payload: err,
      });
    }
  };

export const getJobSearch = (lat, lng, cId, vId) => async (dispatch) => {
  try {
    const response = await API.get(
      `v2/api/journey-variants/search/workshop?variantId=${vId}&Lat=${lat}&Lng=${lng}&CarModelId=${cId}`,
      {
        headers: header, // Assuming 'header' is defined elsewhere
      }
    );

    dispatch({
      type: JOB_SEARCH,
      payload: response.data.Data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERROR,
      payload: err,
    });
  }
};
export const getPackage =
  (
    EmirateId = null,
    EmirateAreaId = null,
    CarCompanyId = null,
    CarModelId = null,
    CustomerId
  ) =>
  async (dispatch) => {
    dispatch({
      type: LOADING_PACKAGES,
      payload: true,
    });

    try {
      const response = await API.get(
        `v2/api/packages?EmirateId=${EmirateId}&EmirateAreaId=${EmirateAreaId}&CarCompanyId=${CarCompanyId}&CarModelId=${CarModelId}&CustomerId=${CustomerId}&Page=10&Size=10`,
        {
          headers: header,
        }
      );

      dispatch({
        type: LOADING_PACKAGES,
        payload: false,
      });
      dispatch({
        type: PACKAGES,
        payload: response.data.Data,
      });
    } catch (err) {
      dispatch({
        type: LOADING_PACKAGES,
        payload: false,
      });
      dispatch({
        type: GET_ERROR,
        payload: err,
      });
    }
  };

export const getLowestPackage =
  (
    EmirateId = null,
    EmirateAreaId = null,
    CarCompanyId = null,
    CarModelId = null
  ) =>
  async (dispatch) => {
    try {
      const response = await API.get(
        `v2/api/packages/lowest?EmirateId=${EmirateId}&EmirateAreaId=${EmirateAreaId}&CarCompanyId=${CarCompanyId}&CarModelId=${CarModelId}`,
        {
          headers: header,
        }
      );

      dispatch({
        type: LOWEST_PACKAGES,
        payload: response.data.Data,
      });
    } catch (err) {
      dispatch({
        type: GET_ERROR,
        payload: err,
      });
    }
  };
export const getPurchaseOrder = (page, size) => async (dispatch) => {
  try {
    const response = await API.get(
      `v2/api/customer-purchase-order?page=${page}&size=${size}`,
      {
        headers: header,
      }
    );
    dispatch({
      type: PURCHASE_ORDER,
      payload: response.data.Data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERROR,
      payload: err,
    });
  }
};

export const getCustomerPurchaseOrder = (data) => async (dispatch) => {
  try {
    const response = await API.post(`v2/api/customer-purchase-order`, data, {
      headers: header,
    });

    dispatch({
      type: CUSTOMER_PURCHASE_ORDER,
      payload: response.data.Data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERROR,
      payload: err,
    });
  }
};

export const getCustomerQuotationRequest = (data) => async (dispatch) => {
  try {
    const response = await API.post(`v2/api/customer-quotation-request`, data, {
      headers: header,
    });

    dispatch({
      type: CUSTOMER_QUOTATION_REQUEST,
      payload: response.data.Data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERROR,
      payload: err,
    });
  }
};

export const getWorkshopAmenities = (id) => async (dispatch) => {
  try {
    const response = await API.get(`v2/api/workshop/${id}/amenities`, {
      headers: header,
    });

    dispatch({
      type: WORKSHOP_AMENITIES,
      payload: response.data.Data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERROR,
      payload: err,
    });
  }
};

export const getTopSellingJob =
  (CarModelId = null) =>
  async (dispatch) => {
    try {
      const response = await API.get(
        `v2/api/journey-variants/top-selling?CarModelId=${CarModelId}`,
        {
          headers: header,
        }
      );

      dispatch({
        type: TOP_SELLING,
        payload: response.data.Data,
      });
    } catch (err) {
      dispatch({
        type: GET_ERROR,
        payload: err,
      });
    }
  };

export const clearWorkshopFilterMap = () => async (dispatch) => {
  dispatch({
    type: WORKSHOP_FILTER_MAP,
    payload: [],
  });
};
export const areaWorkshopFilterMap = (data) => async (dispatch) => {
  dispatch({
    type: WORKSHOP_FILTER_MAP,
    payload: data,
  });
};

export const getWorkshopFilterMap =
  (size, page, lat, lang, vId, mId, filter, brandId = null) =>
  async (dispatch) => {
    if (lat == 0 && lang == 0) {
      return;
    }
    const BrandId = brandId ?? localStorage.getItem("brand-id");
    dispatch({
      type: WORKSHOP_FILTER_MAP,
      payload: [],
    });

    try {
      const response = await API.get(
        `v2/api/workshops/jobs?size=${size}&page=${page}&lat=${lat}&lng=${lang}&variantId=${vId}&carModelId=${mId}&sortingCol=${filter}&brandId=${BrandId}`
      );
      if (response && response.data && response.data.Data) {
        const Data = response.data.Data;
        dispatch({
          type: WORKSHOP_FILTER_MAP,
          payload: Data,
        });
      }
    } catch (error) {
      dispatch({
        type: GET_ERROR,
        payload: error,
      });
    }
  };
export const getWorkshopFilterMapTyre =
  (productId, lat, lang) => async (dispatch) => {
    if (lat == 0 && lang == 0) {
      return;
    }

    dispatch({
      type: WORKSHOP_FILTER_MAP,
      payload: [],
    });

    try {
      const response = await API.get(
        `v2/api/products/web/${productId}/workshops?lat=${lat}&lng=${lang}`
      );
      if (response && response.data && response.data.Data) {
        const Data = response.data.Data;
        dispatch({
          type: WORKSHOP_FILTER_MAP,
          payload: Data,
        });
      }
    } catch (error) {
      dispatch({
        type: GET_ERROR,
        payload: error,
      });
    }
  };

export const getWorkshopFilterMapMultiple =
  (size, page, lat, lang, vIds, mId, filter) => async (dispatch) => {
    if (lat === 0 && lang === 0) {
      return;
    }
    dispatch({
      type: WORKSHOP_FILTER_MAP,
      payload: [],
    });

    try {
      let data = [];

      await Promise.all(
        vIds.map(async (vId) => {
          const response = await API.get(
            `v2/api/workshops/jobs?size=${size}&page=${page}&lat=${lat}&lng=${lang}&variantId=${vId}&carModelId=${mId}&sortingCol=${filter}`,
            {
              headers: header,
            }
          );

          const resData = response.data.Data;

          if (data.length === 0) {
            data = resData;
          } else {
            data = data.filter((value) =>
              resData.some((val) => value.Id === val.Id)
            );
          }
        })
      );

      dispatch({
        type: WORKSHOP_FILTER_MAP,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_ERROR,
        payload: error,
      });
    }
  };

export const getWorkshopFilterMapTwo =
  (size, page, lat, lang, vId, mId, filter) => async (dispatch) => {
    if (lat === 0 && lang === 0) {
      return;
    }
    dispatch({
      type: WORKSHOP_FILTER_MAP,
      payload: [],
    });

    try {
      const response = await API.get(
        `v2/api/workshops/jobs?size=${size}&page=${page}&lat=${lat}&lng=${lang}&variantId=${vId}&carModelId=${mId}&sortingCol=${filter}`,
        {
          headers: header,
        }
      );

      dispatch({
        type: WORKSHOP_FILTER_MAP,
        payload: response.data.Data,
      });
    } catch (error) {
      dispatch({
        type: GET_ERROR,
        payload: error,
      });
    }
  };
export const getWorkshopBrand = (id) => async (dispatch) => {
  dispatch({
    type: BRANDS,
    payload: [],
  });

  try {
    const response = await API.get(
      `v2/api/workshops/${id}/car-company-experties`,
      {
        headers: header,
      }
    );

    dispatch({
      type: BRANDS,
      payload: response.data.Data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERROR,
      payload: error,
    });
  }
};

export const fetchAvatarData = () => async (dispatch) => {
  const { data } = await API.get(`v2/api/avatar-images`);
  dispatch({
    type: FETCH_AVATAR,
    payload: data.Data,
  });
};

export const fetchRecommendedWorkshop =
  (lat, lng, size) => async (dispatch) => {
    const { data } = await API.get(
      `v2/api/nearest/workshops?size=${size}&page=1&lat=${lat}&lng=${lng}`
    );
    dispatch({
      type: FETCH_RECOMMENDED_WORKSHOP,
      payload: data.Data,
    });
  };

export const fetchRecommendedOBAssuredWorkshop =
  (lat, lng) => async (dispatch) => {
    const { data } = await API.get(
      `v2/api/nearest/workshops/offers?lat=${lat}&lng=${lng}`
    );
    dispatch({
      type: FETCH_RECOMMENDED_OB_ASSURED_WORKSHOP,
      payload: data.Data,
    });
  };

export const fetchRecommendedByBrandWorkshop =
  (lat, lng, carId) => async (dispatch) => {
    const { data } = await API.get(
      `v2/api/nearest/workshops/car-company?carCompanyId=${carId}&lat=${lat}&lng=${lng}`
    );
    dispatch({
      type: FETCH_RECOMMENDED_BY_BRAND_WORKSHOP,
      payload: data.Data,
    });
  };

export const fetchLevelupMembership = () => async (dispatch) => {
  const { data } = await API.get(`v2/api/membership`);
  dispatch({
    type: FETCH_LEVEL_UP_MEMBERSHIP,
    payload: data.Data,
  });
};

export const fetchDefaultWorkshop = () => async (dispatch) => {
  const { data } = await API.get(`v2/api/workshops/default`);
  dispatch({
    type: FETCH_DEFAULT_WORKSHOP,
    payload: data.Data,
  });
};

export const fetchCustomPlanList = () => async (dispatch) => {
  const { data } = await API.get(`v2/api/packages/check-list`);
  dispatch({
    type: FETCH_CUSTOM_PLAN_LIST,
    payload: data.Data,
  });
};

export const fetchSuperCarRequestItems = () => async (dispatch) => {
  const { data } = await API.get(`v2/api/car/super-cars/lead-request-items`);
  dispatch({
    type: FETCH_SUPER_CAR_LEAD_ITEMS,
    payload: data.Data,
  });
};

export const fetchWorkshopImages = (id) => async (dispatch) => {
  dispatch({
    type: FETCH_WORKSHOP_IMAGES,
    payload: [],
  });
  const { data } = await API.get(`v2/api/workshops/${id}/slider-images`);
  dispatch({
    type: FETCH_WORKSHOP_IMAGES,
    payload: data.Data,
  });
};

export const getOBWorkshopsProductCategory = async (id = 1001, mId = 0) => {
  try {
    const { data } = await API.get(
      `v2/api/workshop/${id}/journey-category?carModelId=${mId}`,
      {
        headers: header,
      }
    );
    return data;
  } catch (err) {
    return [];
  }
};
