import React from "react";
import { Button, Modal } from "react-bootstrap";

const ConfirmationModal = ({
  show,
  onHide,
  heading = "",
  sub_heading = "",
}) => {
  return (
    <Modal
      className="common-confirmation-modal"
      show={show}
      onHide={onHide}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {heading ? heading : "Details Submitted Successfully"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="text-center">
          <img src="/assets/images/check-mark.gif" width={60}></img>
          <p>
            {sub_heading ? (
              sub_heading
            ) : (
              <span>
                Thanks for showing interest in open bonnet platform.
                <br />
                Our OB Service Advisor will contact you at the details you have
                provided.
              </span>
            )}
            <br />
            TEAM OPEN BONNET
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center">
        <Button variant="primary" onClick={onHide}>
          OK
        </Button>
        {/* <Button variant="secondary" onClick={onHide}>
        No
      </Button> */}
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationModal;
