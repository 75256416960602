import { SignInUrl, SignUpUrl, VerifyOtpUrl } from "api/Auth";
import toast from "react-hot-toast";

export const UserLoginApi = async (payload) => {
  try {
    const response = await SignInUrl(payload);
    return response?.data;
  } catch (error) {
    console.log("Error:", error);
    toast.dismiss();
    toast.error("Something went wrong! Please retry.");
  }
};

export const UserSignUpApi = async (payload) => {
  try {
    const response = await SignUpUrl(payload);
    return response?.data;
  } catch (error) {
    console.log("Error ", error);
    toast.dismiss();
    toast.error("Something went wrong! Please retry.");
  }
};

export const VerifyOtpApi = async (payload) => {
  try {
    const response = await VerifyOtpUrl(payload);
    return response;
  } catch (error) {
    return error.response
  }
};
