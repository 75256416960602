import {
  FETCH_ADDRESS,
  FETCH_BILLING_DETAILS,
  FETCH_CARS,
  FETCH_NOTIFICATION,
  FETCH_OB_POINTS,
  FETCH_PROFILE_COMPLETION,
  FETCH_PROFILE_ORDERS,
  FETCH_PROFILE_PACKAGES_ORDERS,
  FETCH_PROFILE_QUOTATIONS,
  FETCH_RELATIONSHIP_SUMMARY,
  PROFILE,
  SET_LOCATION,
  SET_REMOVED_LABOUR,
  SET_REMOVED_SPARES,
  TOGGLE_ADDRESS_LOADING,
  TOGGLE_CAR_LOADING,
  UPDATE_PROFILE_ORDERS,
  TOGGLE_ORDER_LOADING,
  TOGGLE_OBPOINTS_LOADING,
  TOGGLE_QUOTATIONS_LOADING,
  TOGGLE_PACKAGE_LOADING,
  UPDATE_ORDER_ESTIMATE,
  ADD_ORDER_ESTIMATE,
  SET_JOB_HISTORY,
  TOGGLE_JOB_HISTORY_LOADING,
  FETCH_JOB_HISTORY,
  FETCH_ORDER_INVOICE,
  TOGGLE_INVOICE_LOADING,
  SET_UPDATE_PROFILE,
  RESET_STORE,
  SET_PROFILE_LOADING,
  FETCH_PROFILE_FOR_ACTIVE_ORDERS,
  FETCH_PROFILE_ACTIVE_ORDERS,
  FETCH_PROFILE_ACTIVE_QUOTATIONS,
  FETCH_REPAIR_INSURANCE,
  TOGGLE_REPAIR_INSURNACE_LOADING,
} from "../actions/types";

const initState = {
  info: [],
  addresses: [],
  cars: [],
  location: {
    lat: 0,
    lng: 0,
  },
  orders: null,
  activeOrders: null,
  forActiveOrders: null,
  job_history: {},
  job_history_loading: false,
  active_estimate: {},
  packages: null,
  repairInsurance: null,
  quotations: null,
  activeQuotations: null,
  removedSpares: [],
  removedLabour: [],
  notifications: [],
  invoice: {},
  profileCompletion: null,
  relationshipSummary: null,
  obPoints: null,
  loadingQuotations: false,
  loadingObPoints: false,
  loadingAddresses: false,
  loadingCars: false,
  loadingOrders: false,
  loadingPackages: false,
  loadingJobHistory: false,
  loadingRepairInsurance: false,
  loadingInvoice: false,
  IsProfileUpdate: false,
  billingDetails: {},
};

const profileReducer = (state = initState, action) => {
  switch (action.type) {
    case PROFILE:
      return {
        ...state,
        info: action.payload,
      };
    case FETCH_ADDRESS:
      return {
        ...state,
        addresses: action.payload,
      };
    case FETCH_BILLING_DETAILS:
      return {
        ...state,
        billingDetails: action.payload,
      };
    case FETCH_CARS:
      return {
        ...state,
        cars: action.payload,
      };
    case SET_LOCATION:
      return {
        ...state,
        location: action.payload,
      };
    case FETCH_PROFILE_ORDERS:
      return {
        ...state,
        orders: action.payload,
      };
    case FETCH_PROFILE_ACTIVE_ORDERS:
      return {
        ...state,
        activeOrders: action.payload,
      };

    case FETCH_PROFILE_FOR_ACTIVE_ORDERS:
      return {
        ...state,
        forActiveOrders: action.payload,
      };

    case FETCH_PROFILE_PACKAGES_ORDERS:
      return {
        ...state,
        packages: action.payload,
      };

    case UPDATE_PROFILE_ORDERS:
      const updatedOrders = state.orders?.Data.map((item) => {
        if (item.Id == action.payload.Id) {
          return { ...action.payload };
        }
        return item;
      });
      return { ...state, orders: { ...state.orders, Data: updatedOrders } };

    case SET_REMOVED_SPARES:
      return {
        ...state,
        removedSpares: action.payload,
      };
    case SET_REMOVED_LABOUR:
      return {
        ...state,
        removedLabour: action.payload,
      };
    case FETCH_PROFILE_QUOTATIONS:
      return {
        ...state,
        quotations: action.payload,
      };
    case FETCH_PROFILE_ACTIVE_QUOTATIONS:
      return {
        ...state,
        activeQuotations: action.payload,
      };
    case FETCH_NOTIFICATION:
      return {
        ...state,
        notifications: action.payload,
      };
    case FETCH_PROFILE_COMPLETION:
      return {
        ...state,
        profileCompletion: action.payload,
      };
    case FETCH_RELATIONSHIP_SUMMARY:
      return {
        ...state,
        relationshipSummary: action.payload,
      };
    case FETCH_OB_POINTS:
      return {
        ...state,
        obPoints: action.payload,
      };

    case TOGGLE_QUOTATIONS_LOADING:
      return {
        ...state,
        loadingQuotations: action.payload,
      };

    case TOGGLE_OBPOINTS_LOADING:
      return {
        ...state,
        loadingObPoints: action.payload,
      };

    case TOGGLE_JOB_HISTORY_LOADING:
      return {
        ...state,
        job_history_loading: action.payload,
      };

    case FETCH_JOB_HISTORY:
      return {
        ...state,
        loadingJobHistory: action.payload,
      };

    case FETCH_REPAIR_INSURANCE:
      return {
        ...state,
        repairInsurance: action.payload,
      };

    case FETCH_ORDER_INVOICE:
      return {
        ...state,
        invoice: action.payload,
      };

    case TOGGLE_ADDRESS_LOADING:
      return {
        ...state,
        loadingAddresses: action.payload,
      };

    case TOGGLE_CAR_LOADING:
      return {
        ...state,
        loadingCars: action.payload,
      };

    case TOGGLE_ORDER_LOADING:
      return {
        ...state,
        loadingOrders: action.payload,
      };

    case TOGGLE_PACKAGE_LOADING:
      return {
        ...state,
        loadingPackages: action.payload,
      };
      
    case TOGGLE_REPAIR_INSURNACE_LOADING:
      return {
        ...state,
        loadingPackages: action.payload,
      };

    case TOGGLE_INVOICE_LOADING:
      return {
        ...state,
        loadingInvoice: action.payload,
      };
    case SET_UPDATE_PROFILE:
      return {
        ...state,
        IsProfileUpdate: action.payload,
      };
    case ADD_ORDER_ESTIMATE:
      return { ...state, active_estimate: action.payload };

    case SET_JOB_HISTORY:
      return { ...state, job_history: action.payload };

    case UPDATE_ORDER_ESTIMATE:
      return { ...state, active_estimate: action.payload };

    case RESET_STORE:
      return initState;

    default:
      return state;
  }
};

export default profileReducer;
