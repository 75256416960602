import React, { useState, useEffect } from "react";
import useWindowDimensions from "../../WindowSize";
import { OffCanvas, OffCanvasMenu } from "react-offcanvas";
import { isMobile } from "react-device-detect";
import Nav from "react-bootstrap/Nav";
import { Container, Row, Col, Modal, Button, Spinner } from "react-bootstrap";
import Scrollbars from "react-custom-scrollbars";
import { connect } from "react-redux";
import {
  getModel,
  getVariants,
  getWorkshopFilterMapMultiple,
} from "../../../redux/actions/apiActions";
import {
  setCartAddedPackages,
  setCartJobItems,
  setCartJourney,
  setCartProducts,
  setCartStage,
  setCartWorkshop,
  showMyAccount,
} from "../../../redux/actions/cartAction";
import {
  getCarBrandModal,
  setCarPopup,
  setLayer,
  setLoader,
  toggleChooseCar,
  toggleLocation,
  toggleLoginForm,
  toggleManageCar,
  toggleSignupForm,
} from "../../../redux/actions/globalAction";

import { FaCar } from "react-icons/fa";
import { useRouter } from "next/router";
import { resetCart, saveCart } from "../../../lib/cart";
import ConfirmCartChange from "../../common/ConfirmCartChange";
import styles from "@assets/css/header.module.css";
import { handleClassNames } from "lib/cssHelpers";
import { toast } from "react-hot-toast";
import axios from "axios";
import ServiceSelection from "./ServiceSelection";
import { IoMdArrowRoundBack } from "react-icons/io";
import { gtmStartHereButtonClick } from "../../../lib/google-tag-manager";
import profile from "pages/profile";
import { obWorkshop, workshopPurchase } from "lib/config";
const ChooseCar = ({
  apiData,
  getModel,
  showModel,
  loginData,
  style,
  style1,
  text,
  textTrue,
  getCarBrandModal,
  globalData,
  cart,
  setCartStage,
  setCartProducts,
  setCartJobItems,
  setCartWorkshop,
  showMobileBtn = false,
  setCarPopup,
  getVariants,
  mobileCartView = false,
  showMyAccount,
  setCartJourney,
  setLayer,
  setCartAddedPackages,
  showCarPopUp,
  toggleChooseCar,
  toggleLoginForm,
  toggleSignupForm,
  toggleLocation,
  toggleManageCar,
  isLogin = false,
  isShowCar,
  setIsShowCar,
  buttonText,
  isopen = false,
}) => {
  const { height, width } = useWindowDimensions();
  const [brandHide, setBrandHide] = useState(true);
  const [carHide, setCarHide] = useState(false);
  const [hide, setHide] = useState(false);
  const [data, setData] = useState([]);
  const [mData, setMData] = useState([]);
  const [sData, setSData] = useState([]);
  const [msData, setMSData] = useState([]);
  const [modelList, setModelList] = useState([]);
  const [isCurrentCar, setIsCurrentCar] = useState({});
  const [carBrand1, setCarBrand1] = useState(null);
  const [carModal, setCarModal] = useState(null);
  const [carModal1, setCarModal1] = useState(null);
  const [carBrand, setCarBrand] = useState(null);
  const [logo, setLogo] = useState(null);
  const [logoModal, setLogoModal] = useState(null);
  const [brandId, setBrandId] = useState(null);
  const [modelId, setModelId] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showServiceSelection, setShowServiceSelection] = useState(false);
  const router = useRouter();
  const [modalDetails, setModalDetails] = useState({});

  useEffect(() => {
    if (showCarPopUp) {
      setCarHide(!carHide);
      setBrandHide(true);
      setHide(false);
      setData(apiData.brands);
      setSData(apiData.brands);
    }
  }, [showCarPopUp]);

  useEffect(() => {
    if (isLogin && isShowCar) {
      handleChangeCar();
      setIsShowCar(false);
    }
  }, [isLogin, isShowCar]);
  useEffect(() => {
    if (isLogin && isopen && globalData.showCarPop == "brand") {
      handleChangeCar();
    }
  }, [isLogin, isopen]);

  const handleClickMissingModel = () => {
    setIsOpen(true);
  };

  const handleChangeCar = () => {
    if (
      localStorage.getItem("lead-submitted") ||
      (loginData && loginData.userAuth)
    ) {
      setShowServiceSelection(false);
    }
    const selectionModal = localStorage.getItem("start-here-modal");
    const selectionBrand = localStorage.getItem("start-here-brand");
    if (
      selectionBrand &&
      selectionModal &&
      (router.pathname === `/services/[...slug]` || router.pathname === `/`) &&
      !localStorage.getItem("lead-submitted")
    ) {
      setShowServiceSelection(true);
      setBrandHide(false);
      // setCarPopup("model");
      localStorage.removeItem("toggleback");
      setModalDetails({
        ...JSON.parse(selectionModal),
        brandName: JSON.parse(selectionBrand)?.carBrand,
      });
    }
    if (
      (!sessionStorage.getItem("isWorkshopCollection") ||
        globalData.carData?.brand) &&
      ((cart.products && cart.products?.length) ||
        (cart.workshop && cart.jobItems) ||
        (cart.addedPackages && cart.addedPackages?.length))
    ) {
      setShowAlert(true);
      toggleChooseCar(!carHide);
      return;
    }

    if (globalData?.chooseCarForm) {
      setCarHide(false);
      toggleChooseCar(false);
    } else {
      setCarHide(!carHide);

      toggleChooseCar(!carHide);
    }

    toggleLoginForm(false);
    toggleSignupForm(false);
    toggleLocation(false);
    //  setCarHide(!carHide);
    setBrandHide(true);
    setHide(false);
    setData(apiData.brands);
    setSData(apiData.brands);
    gtmStartHereButtonClick();
    setCartJourney(workshopPurchase);
  };

  useEffect(() => {
    const car_data = globalData.carData
      ? globalData.carData
      : JSON.parse(localStorage.getItem("car_data") || JSON.stringify(""));
    setIsCurrentCar(car_data);
  }, [globalData.carData]);

  useEffect(() => {
    setData(apiData.brands);
    setSData(apiData.brands);
    setCarHide(showModel);
    let carData = globalData.carData;
    const localData = localStorage.getItem("global_car_data");
    if (!localData) {
      setCarBrand(carData?.brand);
      setCarBrand1(carData?.brand);
      setCarModal(carData?.modal);
      setCarModal1(carData?.modal);
      setLogo(carData?.brandUrl);
    }

    if (
      globalData.showCarPop == "brand" &&
      !globalData.chooseCarForm &&
      !localStorage.getItem("carpopup") &&
      !isMobile &&
      !localStorage.getItem("customPlan")
    ) {
      localStorage.setItem("carpopup", true);
      setCarHide(true);
      setBrandHide(true);
      toggleChooseCar(true);
    } else if (globalData.showCarPop == "model") {
      if (
        JSON.parse(
          localStorage.getItem("start-here-brand") || JSON.stringify("")
        ) &&
        JSON.parse(
          localStorage.getItem("start-here-modal") || JSON.stringify("")
        )
      ) {
        return;
      }

      setCarHide(true);
      setBrandHide(false);
    } else if (globalData.showCarPop == null) {
      setCarHide(false);
      setBrandHide(false);
    }

    return () => {
      localStorage.removeItem("carpopup");
      //  setCarHide(false);
      // toggleChooseCar(false);
      //  setCarPopup(null);
    };
  }, [globalData.showCarPop, globalData.carData]);

  useEffect(() => {
    setModelList(apiData.models);
  }, [apiData.models]);

  useEffect(() => {
    setData(apiData.brands);
    setSData(apiData.brands);
  }, [apiData.brands]);

  const search = (e) => {
    let text = String(e.target.value).toLowerCase();
    if (text.length == 0) {
      setData(sData);
    } else {
      const filteredBrands = apiData.brands.filter((e) => {
        return e.Name.toLowerCase().includes(text);
      });
      setData(filteredBrands);
    }
  };

  const searchOne = (e) => {
    let text = String(e.target.value).toLowerCase();
    if (text.length == 0) {
      setMData(msData);
    } else {
      const filteredBrands = apiData.models.filter((e) => {
        return e.Name.toLowerCase().includes(text);
      });
      setMData(filteredBrands);
      setModelList(filteredBrands);
    }
  };

  const selectCar = async (e) => {
    // setCartStage(null);
    await getModel(e.ID);
    setCarBrand(e.Name);
    setLogo(e.ImgUrl);
    setBrandId(e.ID);
    localStorage.setItem("brand", JSON.stringify(e.Name));
    setBrandHide(false);
    setMSData(apiData?.models);
    setMData(apiData?.models);
  };

  const handleCloseLead = () => {
    setShowServiceSelection(false);
    setCarHide(false);
    toggleChooseCar(false);
  };

  const selectModel = (e) => {
    localStorage.setItem("start-here-modal", JSON.stringify(e));
    setModalDetails(e);
    localStorage.setItem(
      "start-here-brand",
      JSON.stringify({ carBrand, brandId, brandImage: logo })
    );
    if (
      !localStorage.getItem("lead-submitted") &&
      Boolean(
        (!globalData.carData &&
          !localStorage.getItem("car_data") &&
          (router.pathname == "/" ||
            router.pathname === `/services/[...slug]`)) ||
          localStorage.getItem("toggleback")
      ) &&
      !(loginData && loginData.userAuth)
    ) {
      localStorage.removeItem("toggleback");
      setShowServiceSelection(true);
    } else {
      setCarPopup("");
      setCarHide(false);
      toggleChooseCar(false);
      setShowServiceSelection(false);
      handleServiceSelection(true);
      if (router.pathname === `/services/[...slug]`) {
        router.push(
          `/services/${
            globalData.locationData?.data.EmirateAreaSlug || "dubai"
          }/${router.query.slug[1]}/${e.Slug ?? "car"}`
        );
      }
    }
  };

  const handleServiceSelection = () => {
    const e = JSON.parse(localStorage.getItem("start-here-modal"));
    const {
      carBrand: selectionBrand,
      brandId: selectionBrandId,
      brandImage,
    } = JSON.parse(localStorage.getItem("start-here-brand"));
    setCarModal1(e.Name);
    setCarBrand1(selectionBrand);
    setLogoModal(e.ImgUrl);
    setModelId(e.ID);
    setCarModal(carModal1);
    localStorage.setItem("modal", JSON.stringify(e.Name));
    localStorage.setItem("logo", JSON.stringify(logo));
    localStorage.setItem("logoModal", JSON.stringify(logoModal));
    localStorage.removeItem("global_car_data");
    if (cart.products?.length == 0) {
      getCarBrandModal(
        selectionBrand,
        e.Name,
        brandImage,
        e.ImgUrl,
        selectionBrandId,
        e.ID,
        null,
        e.Slug
      );
      saveCart(cart, globalData, apiData);
      if (apiData.variants?.length > 0) {
        getVariants(apiData.variants[0].JourneyCategoryId, e.ID);
      }
    } else {
      checkAvailability(
        selectionBrand,
        e.Name,
        brandImage,
        e.ImgUrl,
        selectionBrandId,
        e.ID
      );
    }
    localStorage.removeItem("start-here-modal");
    localStorage.removeItem("start-here-brand");
    // if (
    //   callingFromModelSelection &&
    //   router.pathname === `/services/[...slug]` &&
    //   router.query.slug &&
    //   router.query.slug[1]
    // ) {
    //   router.push(
    //     {
    //       pathname: `/services/[...slug]`,
    //     },
    //     `/services/${
    //       globalData.locationData?.data.EmirateAreaSlug || "dubai"
    //     }/${router.query.slug[1]}/${e.Slug || "car"}${
    //       router.query.slug[3] ? "/" + router.query.slug[3] : "/"
    //     }${router.query.id ? "?id=" + router.query.id : ""}`,
    //     { shallow: true }
    //   );
    // }
  };

  const checkAvailability = async (
    brand,
    modal,
    logoBrand,
    logoModal,
    brandId,
    modelId
  ) => {
    let ids = cart.products.map((item) => item.Id);
    let data = [];
    await Promise.all(
      ids.map(async (vId) => {
        try {
          const response = await axios.get(
            `${
              process.env.NEXT_PUBLIC_API_URL
            }v2/api/workshops/jobs?size=${40}&page=${1}&lat=${
              globalData.locationData.lat
            }&lng=${
              globalData.locationData.lng
            }&variantId=${vId}&carModelId=${modelId}&sortingCol=Distance`
          );
          const res = response.data;
          if (data.length === 0) {
            data = res.Data;
          } else {
            data = data.filter(
              (value) =>
                value.Id == res.Data.filter((val) => value.Id == val.Id)[0].Id
            );
          }
        } catch (error) {
          console.log("error", error);
        }
      })
    );
    let result =
      data.filter((item) => item.Id === cart.workshop?.Id).length === 1;
    if (result) {
      let items = [];
      await Promise.all(
        cart.products.map(async (prod) => {
          try {
            const response = await API.get(
              `v2/api/workshops/${cart.workshop?.Id}/jobs?page=1&variantId=${prod.Id}&carModelId=${globalData.carData?.modelId}&sortingCol=Distance&workshopId=${cart.workshop?.Id}&lat=1&lng=1&size=40`
            );
            const res = response.data;
            if (res.Data.length !== 0) {
              items.push(res.Data[0]);
            }
          } catch (error) {
            console.error("Error", error);
            // alert("something went wrong");
          }
        })
      );
      if (cart.products.length === items.length) {
        getCarBrandModal(brand, modal, logoBrand, logoModal, brandId, modelId);
        saveCart(cart, globalData, apiData);
      } else {
        setShowWarning(true);
      }
    }
  };

  const hideAlert = () => {
    setShowAlert(false);
    toggleChooseCar(false);
  };

  const handleConfirmChange = () => {
    setCartStage(1);
    setCartProducts([]);
    setCartJobItems([]);
    setCartWorkshop(null);
  };

  useEffect(() => {
    if (!carHide && apiData.models?.length > 0) {
      handleClose();
    }
  }, [carHide]);

  const handleClose = () => {
    localStorage.removeItem("carpopup");
    if (globalData.carData?.brand && globalData.carData?.modal == null) {
      getCarBrandModal(null, null, null, null, null, null, true);
    }
  };

  const handlebackClick = () => {
    if (isMobile) {
      setCarHide(true);
      toggleChooseCar(true);
      setCarPopup("brand");
      localStorage.setItem("toggleback", true);
    } else {
      const brand = JSON.parse(localStorage.getItem("start-here-brand"));
      localStorage.removeItem("start-here-modal");
      localStorage.removeItem("start-here-brand");
      // handleClose()
      setTimeout(() => {
        toggleChooseCar(true);
        setCarHide(true);
      }, 300);
      setShowServiceSelection(false);
    }

    //  localStorage.removeItem("toggleback");
  };

  const handleResetCart = () => {
    setCartStage(null);
    setCartProducts([]);
    setCartJobItems([]);
    setCartWorkshop(null);
    setCartAddedPackages([]);
    sessionStorage.removeItem("get-fitting-at-home");
    setLayer(false);
    resetCart()
      .then((res) => {
        localStorage.setItem("hasLocationPermission", "granted");
        if (router.query?.slug) {
          setShowAlert(false);
          router.push(
            {
              pathname: `/services/[...slug]`,
            },
            `/services/${
              globalData.locationData?.data.EmirateAreaSlug || "dubai"
            }/${router.query?.slug[1] ? router.query?.slug[1] : ""}${
              router.query.slug[2] ? "/" + router.query?.slug[2] : ""
            }`,
            { shallow: true }
          );
          setCarHide(!carHide);
          setBrandHide(true);
          setHide(false);
          setData(apiData.brands);
          setSData(apiData.brands);
          toggleChooseCar(true);
        } else {
          setShowAlert(false);
          setCarHide(!carHide);
          setBrandHide(true);
          setHide(false);
          setData(apiData.brands);
          setSData(apiData.brands);
          toggleChooseCar(true);
        }
      })
      .catch((err) => {
        toast.dismiss();
        console.log("err", err);
        toast.error("Something went wrong! Please retry.");
      });
  };

  const handleClasses = (classValue) => {
    return handleClassNames(classValue, styles);
  };

  useEffect(() => {
    const selectionModal = localStorage.getItem("start-here-modal");
    const selectionBrand = localStorage.getItem("start-here-brand");
    if (
      selectionBrand &&
      selectionModal &&
      (router.pathname === `/services/[...slug]` || router.pathname === `/`) &&
      !localStorage.getItem("lead-submitted")
    ) {
      setShowServiceSelection(true);
      setBrandHide(false);
      // setCarPopup("model");
      localStorage.removeItem("toggleback");
      setModalDetails({
        ...JSON.parse(selectionModal),
        brandName: JSON.parse(selectionBrand)?.carBrand,
      });
    }
  }, []);

  return (
    <div className="ob-car-choose-section">
      <ConfirmCartChange
        show={showAlert}
        handleResetCart={handleResetCart}
        onHide={hideAlert}
        paragraph={"Change of car will reset the items in your cart. Proceed?"}
      />
      <Modal show={showWarning} onHide={() => setShowWarning(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Change Car</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>Do you want change car? </h5>
          <p>
            As you have multiple services, there will only keep one service
            while changing workshop!
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleConfirmChange}>
            Yes
          </Button>
          <Button
            variant="secondary"
            onClick={() => {
              toggleChooseCar(false);
              setShowWarning(false);
            }}
          >
            No
          </Button>
        </Modal.Footer>
      </Modal>
      {mobileCartView && isMobile && (
        <button
          className="change-car border-none bg-gray"
          onClick={() => {
            if (
              (cart.products && cart.products?.length) ||
              (cart.workshop && cart.jobItems) ||
              (cart.addedPackages && cart.addedPackages?.length)
            ) {
              setShowAlert(true);
              toggleChooseCar(true);
              return;
            }
            setCarHide(!carHide);
            setBrandHide(true);
            setHide(false);
            setData(apiData.brands);
            setSData(apiData.brands);
          }}
        >
          Change
        </button>
      )}
      {!isMobile && width > 850 ? (
        <Nav.Item>
          <div
            onClick={() => {
              handleChangeCar();
            }}
          >
            {!textTrue ? (
              <div
                className={carBrand == null ? style + "bg-blue" : style}
                style={{ ...style1 }}
              >
                {" "}
                {/* {carBrand != null && carModal != null && (
                  <img
                    src={`${(loginData && loginData?.userAuth) ? logo : JSON.parse(localStorage.getItem("logo")) || logo}`}
                    // src={`${logo}`}
                    width={40}
                    height={40}
                    alt="brand"
                    className="mr-2"
                  // style={{ borderRadius: "40px" }}
                  />
                  // <div style={{ fontSize: 20 }}>
                  //   <FaCar className="mr-2" />
                  // </div>
                )}{" "} */}
                <div>
                  {carBrand != null && carModal != null ? (
                    !isLogin ? (
                      <div
                        style={{ cursor: "pointer" }}
                        className={`dropdown ${handleClasses(
                          "ad-more-car active-car-val show"
                        )}`}
                      >
                        <div
                          // onClick={() => setShowCarPopUp(!showCarPopUp)}
                          className={handleClasses("ad-car-dropdown")}
                          id="dropdownCar"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <label>
                            <img
                              className={handleClasses("brimage")}
                              src={
                                isCurrentCar.brandUrl ||
                                "/assets/header/demo.webp"
                              }
                            />
                          </label>
                          <strong>{buttonText || "Change "}</strong>
                          <span>
                            {isCurrentCar.brand
                              ? `${isCurrentCar.brand} - ${isCurrentCar.modal}`
                              : "fetch.."}
                          </span>
                          <i className="fa-solid fa-caret-down" />
                        </div>
                      </div>
                    ) : null
                  ) : !isLogin ? (
                    <div
                      style={{ cursor: "pointer" }}
                      className={`dropdown ${handleClasses("ad-more-car")}`}
                    >
                      <div
                        className={`${handleClasses(
                          "ad-car-dropdown glare-animation pulse-anm"
                        )} animate__bounceIn animate__infinite  pulse-anm	infinite`}
                      >
                        <strong
                          className={handleClasses(
                            "ob-car-choose-section-bounce"
                          )}
                        >
                          Start Here
                        </strong>
                        <i className="fa-solid fa-caret-down" />
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            ) : (
              <span
                className={
                  text == "Change Car"
                    ? style + " bg-gray"
                    : style + " bg-blue custom-color-choose-car"
                }
                style={style1}
              >
                {" "}
                {text}{" "}
              </span>
            )}
          </div>
        </Nav.Item>
      ) : (
        <></>
      )}
      {showMobileBtn && (
        <span
          style={{ border: "0px", background: "#0451be", color: "white" }}
          className={"ob-button mx-3 mt-3 tour-1 ob-button-custom"}
          onClick={() => {
            setCarHide(!carHide);
            setBrandHide(true);
            setHide(false);
            setData(apiData.brands);
            setSData(apiData.brands);
          }}
        >
          {" "}
          {carBrand != null && carModal != null && (
            // <img
            //   src={`${logo}`}
            //   width={30}
            //   alt="brand"
            //   className="mr-1"
            //   style={{ borderRadius: "40px" }}
            // />
            <FaCar className="mr-2" />
          )}{" "}
          {carBrand != null && carModal != null
            ? " " + carBrand1 + " " + carModal
            : "Choose Your Car"}
        </span>
      )}

      {carHide &&
        router.asPath != "/" &&
        router.pathname != "/super-car" &&
        router.pathname != "/workshop-network" &&
        router.pathname != "/profile/my-car" &&
        globalData.chooseCarForm && (
          <div
            className="bg-layer"
            style={{
              width: "100%",
              height: "calc(100% + 7px)",
              background: "#0303036e",
              position: "fixed",
              left: "0",
              top: 0,
            }}
            onClick={() => {
              setCarHide(false);
              toggleChooseCar(false);
              setCarPopup(null);
            }}
          ></div>
        )}

      {width > 600 ? (
        <>
          <OffCanvas
            width={500}
            transitionDuration={300}
            effect={"parallax"}
            isMenuOpened={carHide}
            position={"right"}
          >
            {globalData.chooseCarForm ? (
              <OffCanvasMenu
                className={
                  router.pathname == "/super-car"
                    ? "choose-car choose-car-top-common"
                    : "choose-car choose-car-top"
                }
              >
                {showServiceSelection &&
                (router.pathname == "/" ||
                  router.pathname === `/services/[...slug]`) ? (
                  <div className="d-flex justify-content-between cstp-bar">
                    <button
                      className="bck-btn"
                      onClick={() => handlebackClick()}
                    >
                      <span className="bck-icon">
                        {" "}
                        <IoMdArrowRoundBack />{" "}
                      </span>
                      Back
                    </button>
                    <div className="img-tp-rightbar">
                      <img
                        src={
                          showServiceSelection
                            ? modalDetails?.ImgUrl
                            : globalData?.carData?.modalUrl
                        }
                        className=""
                        height="45px"
                        alt="brand"
                      />

                      <div className="car-rght-new">
                        {" "}
                        <h5>
                          {showServiceSelection
                            ? modalDetails?.brandName
                            : globalData.carData?.brand}
                        </h5>
                        <span>
                          {showServiceSelection
                            ? modalDetails?.Name
                            : globalData.carData?.modal}
                        </span>
                      </div>
                    </div>
                  </div>
                ) : null}
                <Container
                  style={
                    !showServiceSelection
                      ? { paddingTop: "60px" }
                      : { display: "flex", height: "calc(100% - 65px)" }
                  }
                >
                  {showServiceSelection &&
                  (router.pathname == "/" ||
                    router.pathname === `/services/[...slug]`) ? (
                    <>
                      <Row className="w-100" style={{ margin: "0 auto" }}>
                        <ServiceSelection
                          handleCloseLead={handleCloseLead}
                          handleServiceSelection={handleServiceSelection}
                        />
                      </Row>
                    </>
                  ) : brandHide ? (
                    <>
                      <Row>
                        <div className="search-brand d-flex align-items-center w-100">
                          <a
                            onClick={() => {
                              toggleChooseCar(false);
                              setCarHide(false);
                              setCarPopup(null);
                              handleClose();
                            }}
                          >
                            <img
                              src="/assets/images/back.webp"
                              width={35}
                              className={"p-2"}
                              alt="back"
                            />
                          </a>
                          <input
                            type="text"
                            placeholder="Search Brand"
                            className="border-none ml-3 w-100"
                            onChange={search}
                          />
                          {loginData &&
                          loginData.userAuth &&
                          globalData.carData ? (
                            <div
                              className="my-car-button"
                              role="button"
                              onClick={() => {
                                toggleManageCar(true);
                                toggleChooseCar(false);
                                setCarHide(false);
                                setCarPopup(null);
                                showMyAccount(true);
                              }}
                            >
                              My Cars
                            </div>
                          ) : null}
                        </div>
                      </Row>
                      <Scrollbars
                        className="car-brand-scroll"
                        style={{ height: "calc(100vh - 180px)" }}
                      >
                        <Row>
                          {apiData.brands && data && data.length ? (
                            data.map((e) => (
                              <Col
                                xs={4}
                                className="text-center brand"
                                role="button"
                                onClick={() => selectCar(e)}
                              >
                                <img
                                  src={
                                    e.ImgUrl ||
                                    "https://openbonnet-storage.s3.me-south-1.amazonaws.com/public/car-company/thumbnail/4.png"
                                  }
                                  height={100}
                                  width={100}
                                  style={{
                                    objectFit: "contain",
                                  }}
                                  className={"p-4"}
                                  alt="brand"
                                />
                                <p>{e.Name || "BMW"}</p>
                              </Col>
                            ))
                          ) : (
                            <Col xs={4} md={12} className=" mt-2">
                              {!apiData.brands?.length ? (
                                <div className="d-flex justify-content-center align-items-center text-center ">
                                  <div>
                                    <Spinner
                                      variant="primary"
                                      size="sm"
                                      animation="border"
                                      role="status"
                                    ></Spinner>
                                  </div>
                                  <p className="visually-hidden mx-2">
                                    Loading...
                                  </p>
                                </div>
                              ) : (
                                <div>
                                  <div className="text-center">
                                    {!isOpen && (
                                      <a
                                        onClick={handleClickMissingModel}
                                        className="missingCar "
                                      >
                                        Unable to find your Car Model or Brand?
                                      </a>
                                    )}
                                  </div>
                                  <Container
                                    style={
                                      !isOpen
                                        ? { paddingTop: "60px" }
                                        : {
                                            display: "flex",
                                            height: "calc(100% - 65px)",
                                          }
                                    }
                                  >
                                    {isOpen &&
                                      (router.pathname === "/" ||
                                        router.pathname ===
                                          `/services/[...slug]`) && (
                                        <Row
                                          className="w-100"
                                          style={{ margin: "0 auto" }}
                                        >
                                          <ServiceSelection />
                                        </Row>
                                      )}
                                  </Container>
                                </div>
                              )}
                            </Col>
                          )}
                        </Row>
                      </Scrollbars>
                    </>
                  ) : (
                    <>
                      <Row>
                        <div className="search-brand">
                          <div className="d-flex align-items-center w-100">
                            <a
                              onClick={() => {
                                setBrandHide(true);
                              }}
                            >
                              <img
                                src="/assets/images/back.webp"
                                width={35}
                                className={"p-2"}
                                alt="back"
                              />{" "}
                              {/* Search your car brand */}
                            </a>
                            <input
                              type="text"
                              placeholder="Search Model"
                              onChange={searchOne}
                              className="border-none ml-3 py-2 w-100"
                            />
                          </div>

                          {logo && (
                            <img
                              src={logo}
                              width={"auto"}
                              height={"50px"}
                              className={"p-1"}
                              alt="brand"
                            />
                          )}
                        </div>
                      </Row>
                      <Scrollbars
                        className="scroll-bar-brand"
                        style={{ height: "calc(100vh - 145px)" }}
                      >
                        <Row>
                          {apiData.models && modelList && modelList.length ? (
                            modelList.map((e) => (
                              <Col
                                xs={4}
                                className="text-center brand"
                                role="button"
                                onClick={() => selectModel(e)}
                              >
                                <img
                                  src={
                                    e.ImgUrl ||
                                    "https://archive.izmostock.com/img-get/I0000sJ.U3oSxU4w/s/1000/2015-audi-a3-ambition-2door-convertible-2wd-angular-front.jpg"
                                  }
                                  height={100}
                                  width={100}
                                  style={{
                                    objectFit: "contain",
                                  }}
                                  className={"p-1"}
                                  alt="brand"
                                />
                                <p>{e.Name || "BMW"}</p>
                              </Col>
                            ))
                          ) : (
                            <Col xs={4} md={12} className="text-center mt-2">
                              {!apiData.models?.length ? (
                                <div className="d-flex justify-content-center align-items-center">
                                  <div>
                                    <Spinner
                                      variant="primary"
                                      size="sm"
                                      animation="border"
                                      role="status"
                                    ></Spinner>
                                  </div>
                                  <p className="visually-hidden mx-2">
                                    Loading...
                                  </p>
                                </div>
                              ) : (
                                <div>
                                  <div className="text-center">
                                    {!isOpen && (
                                      <a
                                        onClick={handleClickMissingModel}
                                        className="missingCar "
                                      >
                                        Unable to find your Car Model or Brand?
                                      </a>
                                    )}
                                  </div>
                                  <Container
                                    style={
                                      !isOpen
                                        ? { paddingTop: "60px" }
                                        : {
                                            display: "flex",
                                            height: "calc(100% - 65px)",
                                          }
                                    }
                                  >
                                    {isOpen &&
                                      (router.pathname === "/" ||
                                        router.pathname ===
                                          `/services/[...slug]`) && (
                                        <Row
                                          className="w-100"
                                          style={{ textAlign: "start" }}
                                        >
                                          <ServiceSelection />
                                        </Row>
                                      )}
                                  </Container>
                                </div>
                              )}
                            </Col>
                          )}
                        </Row>
                      </Scrollbars>
                    </>
                  )}
                </Container>
              </OffCanvasMenu>
            ) : null}
          </OffCanvas>
        </>
      ) : (
        <>
          {carHide && (
            <div className="mobile-car-selection">
              <Container style={{}}>
                {brandHide ? (
                  <>
                    <Row>
                      <h4 className="px-2 mt-2">Choose Car</h4>
                    </Row>
                    <Row>
                      <div
                        className={
                          loginData && loginData.userAuth
                            ? "mt-2 search-brand d-flex align-items-center w-100"
                            : "mt-2 search-brand-custom d-flex align-items-center w-100"
                        }
                      >
                        <div>
                          <a
                            onClick={() => {
                              setCarHide(false);
                              toggleChooseCar(false);
                              handleClose();
                            }}
                          >
                            <img
                              src="/assets/images/back.webp"
                              width={35}
                              className={"p-2"}
                              alt="back"
                            />
                          </a>
                          <input
                            type="text"
                            style={isMobile ? { width: "60%" } : null}
                            placeholder="Search Brand"
                            className="border-none ml-3"
                            onChange={search}
                          />
                        </div>

                        {loginData &&
                        loginData.userAuth &&
                        globalData?.carData ? (
                          <div className="my-car-containter">
                            <div
                              className="my-car-button"
                              style={
                                isMobile
                                  ? { paddingLeft: "30px !important" }
                                  : {}
                              }
                              role="button"
                              onClick={() => {
                                toggleManageCar(true);
                                toggleChooseCar(false);
                                setCarHide(false);
                                setCarPopup(null);
                                showMyAccount(true);
                              }}
                            >
                              My Cars
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </Row>
                    <Scrollbars
                      className="scroll-brand"
                      style={{ height: "calc(100vh - 201px )" }}
                    >
                      <Row>
                        {apiData.brands ? (
                          data.map((e) => (
                            <Col
                              xs={4}
                              className="text-center brand"
                              role="button"
                              onClick={() => selectCar(e)}
                            >
                              <img
                                src={
                                  e.ImgUrl ||
                                  "https://openbonnet-storage.s3.me-south-1.amazonaws.com/public/car-company/thumbnail/4.png"
                                }
                                height={100}
                                width={100}
                                style={{
                                  objectFit: "contain",
                                }}
                                className={"p-2"}
                                alt="brand"
                              />
                              <p>{e.Name || "BMW"}</p>
                            </Col>
                          ))
                        ) : (
                          <Col xs={12} className="mt-2">
                            <div className="d-flex justify-content-center align-items-center">
                              <div>
                                <Spinner
                                  variant="primary"
                                  size="sm"
                                  animation="border"
                                  role="status"
                                ></Spinner>
                              </div>
                              <p className="visually-hidden mx-2">Loading...</p>
                            </div>
                          </Col>
                        )}
                      </Row>
                    </Scrollbars>
                  </>
                ) : (
                  <>
                    <Row>
                      {!isMobile || router.pathname == `/services/[...slug]` ? (
                        <div
                          style={
                            isMobile && router.pathname == `/services/[...slug]`
                              ? { marginTop: "-5px" }
                              : {}
                          }
                          className="search-brand d-flex w-100 justify-content-between"
                        >
                          <a
                            onClick={() => {
                              setBrandHide(true);
                              handleClose();
                            }}
                          >
                            <img
                              src="/assets/images/back.webp"
                              width={35}
                              className={"p-2"}
                              alt="back"
                            />{" "}
                            Search your car brand
                          </a>
                          <img
                            src={logo}
                            width={"auto"}
                            height={"50px"}
                            className={"p-1"}
                            alt="brand"
                            // style={{ objectFit: "contain" }}
                          />
                        </div>
                      ) : null}
                      <div className="search-model">
                        <a
                          onClick={() => {
                            setBrandHide(!brandHide);
                          }}
                        >
                          <img
                            src="/assets/images/back.webp"
                            width={35}
                            className={"p-2"}
                            alt="back"
                          />
                        </a>
                        <input
                          type="text"
                          placeholder="Search Model"
                          onChange={searchOne}
                          className="border-none ml-3 py-2"
                        />
                        {/* {logo && (
                          <img
                            src={logo}
                            width={"auto"}
                            height={"50px"}
                            className={"p-1"}
                            alt="brand"
                          />
                        )} */}
                      </div>
                    </Row>

                    <Scrollbars
                      className="scroll-model"
                      style={{
                        height: "calc(100vh - 200px)",
                        marginTop:
                          isMobile && router.pathname == `/services/[...slug]`
                            ? 0
                            : 50,
                      }}
                    >
                      <Row>
                        {apiData.models && apiData.models?.length && mData ? (
                          apiData.models?.map((e) => (
                            <Col
                              xs={4}
                              className="text-center brand"
                              role="button"
                              onClick={() => selectModel(e)}
                            >
                              <img
                                src={
                                  e.ImgUrl ||
                                  "https://archive.izmostock.com/img-get/I0000sJ.U3oSxU4w/s/1000/2015-audi-a3-ambition-2door-convertible-2wd-angular-front.jpg"
                                }
                                height={100}
                                style={{ objectFit: "contain" }}
                                // className={"p-2"}
                                alt="brand"
                              />
                              <p>{e.Name || "BMW"}</p>
                            </Col>
                          ))
                        ) : (
                          <Col xs={12}>
                            <div className="d-flex justify-content-center align-items-center">
                              <div>
                                <Spinner
                                  variant="primary"
                                  size="sm"
                                  animation="border"
                                  role="status"
                                ></Spinner>
                              </div>
                              <p className="visually-hidden mx-2">Loading...</p>
                            </div>
                          </Col>
                        )}
                      </Row>
                    </Scrollbars>
                  </>
                )}
              </Container>
            </div>
          )}
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    globalData: state.globalData,
    apiData: state.apiData,
    profile: state.profile,
    cart: state.cart,
    loginData: state.loginData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getModel: (id) => {
      dispatch(getModel(id));
    },
    getWorkshopFilterMapMultiple: (
      size,
      page,
      lat,
      lang,
      vIds,
      mId,
      filter
    ) => {
      dispatch(
        getWorkshopFilterMapMultiple(size, page, lat, lang, vIds, mId, filter)
      );
    },

    setCartStage: (stage) => {
      dispatch(setCartStage(stage));
    },
    setCartProducts: (products) => {
      dispatch(setCartProducts(products));
    },
    setCartJobItems: (jobs) => {
      dispatch(setCartJobItems(jobs));
    },
    setCartWorkshop: (data) => {
      dispatch(setCartWorkshop(data));
    },
    setCartAddedPackages: (packages) => {
      dispatch(setCartAddedPackages(packages));
    },
    setCarPopup: (flag) => {
      dispatch(setCarPopup(flag));
    },
    getVariants: (
      id,
      mId = null,
      EmirateId = null,
      EmirateAreaId = null,
      CarCompanyId = null
    ) => {
      dispatch(getVariants(id, mId, EmirateId, EmirateAreaId, CarCompanyId));
    },
    getCarBrandModal: (
      brand,
      modal,
      logoBrand,
      logoModal,
      brandId,
      modelId,
      setNull,
      slug = null
    ) => {
      dispatch(
        getCarBrandModal(
          brand,
          modal,
          logoBrand,
          logoModal,
          brandId,
          modelId,
          slug,
          setNull
        )
      );
    },
    showMyAccount: (data) => {
      dispatch(showMyAccount(data));
    },
    setLoader: (data) => {
      dispatch(setLoader(data));
    },
    setLayer: (status) => {
      dispatch(setLayer(status));
    },
    toggleChooseCar: (stage) => {
      dispatch(toggleChooseCar(stage));
    },
    toggleSignupForm: (stage) => {
      dispatch(toggleSignupForm(stage));
    },
    toggleLoginForm: (stage) => {
      dispatch(toggleLoginForm(stage));
    },
    toggleLocation: (stage) => {
      dispatch(toggleLocation(stage));
    },
    toggleManageCar: (stage) => {
      dispatch(toggleManageCar(stage));
    },
    setCartJourney: (stage) => {
      dispatch(setCartJourney(stage));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChooseCar);
