import React, { useEffect, useState } from "react";
import { FaArrowLeft, FaTimes } from "react-icons/fa";
import VerifyOTP from "./VerifyOTP";
import Login from "./Login";
import SignUp from "./SignUp";
import { UserSignUpApi, UserLoginApi, VerifyOtpApi } from "actions/Auth";
import toast from "react-hot-toast";
import { connect, useDispatch } from "react-redux";
import { useRouter } from "next/router";
import { useForm } from "react-hook-form";
import { gtmLogin, gtmSignUp } from "lib/google-tag-manager";
import { fetchCustomerCart } from "actions/homePage";
import {
  setLoginPopup,
  setOtpScreen,
  toggleLoginForm,
  toggleSignupForm,
} from "redux/actions/globalAction";
import { isMobile } from "react-device-detect";
import { getUserAuth, setAuth } from "redux/actions/loginAction";
import { fetchCars, toggleUpdateProfile } from "redux/actions/profileAction";

const UserAuthentication = ({
  globalData,
  toggleSignupForm,
  toggleLoginForm,
  setCarHide,
  setLogHide,
  setAuthBoxType,
  authBoxType,
  setOtpScreen,
  setLoginPopup,
  setAuth,
  fetchCars,
  fetchCustomerCart,
  getUserAuth,
  toggleUpdateProfile,
}) => {
  const [verifyOtpSection, setVerifyOtpSection] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otpValid, setOtpValid] = useState(false);
  const router = useRouter();
  const dispatch = useDispatch();

  const isCheckout = router.pathname == "/checkout";

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isSubmitting },
    clearErrors,
    reset,
  } = useForm();

  const onLoginSubmit = async (formData) => {
    const response = await UserLoginApi(formData);
    const data = response;
    if (data.IsSuccess) {
      setPhoneNumber(data.Data?.PhoneNumber);
      setValue("otp", "");
      clearErrors("otp");
      setOtpValid("");
      setVerifyOtpSection(true);
      setOtpScreen(true);
      gtmLogin();
    } else {
      toast.dismiss();
      toast.error("User Authentication Failed. Please retry.");
    }
  };

  const resendOtp = async (formData) => {
    const response = await UserLoginApi(formData);
    const data = response;
    if (data.IsSuccess) {
      toast.dismiss();
      toast.success("OTP Resent Successfully!");
    } else {
      toast.dismiss();
      toast.error("OTP Resending Failed. Please retry.");
    }
  };

  const onOTPVerify = async (formData) => {
    formData = {
      username: formData.phoneNumber,
      password: formData.otp,
      grant_type: "password",
      provider_type: "OTP",
    };
    try {
      const response = await VerifyOtpApi(formData);
      if (response?.status == 400) {
        setOtpValid("Invalid OTP");
        toast.dismiss();
        toast.error("Invalid OTP! Please retry.");
        return;
      }

      localStorage.setItem("headerLogin", true);
      localStorage.setItem("phoneNumber", formData.username);
      localStorage.setItem("login_access", JSON.stringify(response?.data));
      setAuth(response?.data);
      reset();

      toast.dismiss();
      toast.success("Account Logged in Successfully!");
      setPhoneNumber("");
      setLogHide(false);
      toggleLoginForm(false);
      toggleSignupForm(false);
      setCarHide(false);
      setAuthBoxType("");
      setOtpScreen(false);
      toggleUpdateProfile(true);
      setVerifyOtpSection(false);
      await getUserAuth();
      if (!isCheckout) {
        setTimeout(() => {
          fetchCustomerCart();
        }, 1000);
      }
    } catch (err) {
      console.log("Error:", err);
      toast.dismiss();
      toast.error("Something went wrong! Please retry.");
    }
  };

  const onSignUpSubmit = async (formData) => {
    const response = await UserSignUpApi(formData);
    const data = response;
    if (!data.IsSuccess) {
      toast.dismiss();
      toast.error(data?.Message || "Something went wrong! Please retry.");
    } else {
      setPhoneNumber(data?.Data?.PhoneNumber);
      setVerifyOtpSection(true);
      setOtpScreen(true);
      gtmSignUp();
    }
  };

  useEffect(() => {
    if (router.query.referrelCode)
      setValue("RefferalCode", router.query.referrelCode);
  }, [router.query]);

  return (
    <>
      {(verifyOtpSection && globalData.LoginForm) ||
      globalData.SignupForm ||
      (authBoxType && globalData.SignupForm) ||
      globalData.LoginForm ? (
        <>
          <div
            className={
              router.pathname == "/checkout"
                ? "island-box island-box-scroll-bar island-box-checkout"
                : "island-box island-box-scroll-bar"
            }
            style={{
              width: isMobile ? "100%" : "30%",
              zIndex: isMobile ? 999 : 14,
            }}
          >
            <div>
              <div
                className="d-flex justify-content-between align-items-center"
                style={{ fontSize: 24 }}
              >
                {authBoxType === "signup" && globalData.SignupForm ? (
                  <div className="sign-up-page d-block w-100">
                    <div className="new-header new-background-gradient text-white">
                      <div className="w-100">
                        <p className="new-fw-bold" style={{ fontSize: "22px" }}>
                          SIGN UP
                        </p>
                        <p className="">Earn AED 25 OB CashPoints </p>
                        <p className="">Use Referral Code - "HELLO"</p>
                      </div>
                    </div>
                  </div>
                ) : null}
                <div style={{ position: "absolute", right: "10px", top: '10px' }}>
                  {isMobile ? (
                    <FaArrowLeft
                      className="for-mobile"
                      onClick={() => {
                        setOtpScreen(false);
                        toggleLoginForm(false);
                        toggleSignupForm(false);
                        setAuthBoxType("");
                        setLoginPopup(false);
                        setVerifyOtpSection(false);
                      }}
                    />
                  ) : (
                    <FaTimes
                      className="hide-in-mobile"
                      color="#000"
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setOtpScreen(false);
                        toggleLoginForm(false);
                        toggleSignupForm(false);
                        setAuthBoxType("");
                        setLoginPopup(false);
                        setVerifyOtpSection(false);
                      }}
                    />
                  )}
                </div>
              </div>
              {globalData.otpScreen &&
              (globalData.LoginForm || globalData.SignupForm) ? (
                <VerifyOTP
                  resendOtp={resendOtp}
                  onOTPVerify={onOTPVerify}
                  handleSubmit={handleSubmit}
                  register={register}
                  errors={errors}
                  isSubmitting={isSubmitting}
                  otpValid={otpValid}
                  setOtpValid={setOtpValid}
                  phoneNumber={phoneNumber}
                  setVerifyOtpSection={setVerifyOtpSection}
                  isCheckout={false}
                  setOtpScreen={setOtpScreen}
                />
              ) : (
                <>
                  {authBoxType === "login" && globalData.LoginForm ? (
                    <Login
                      onLoginSubmit={onLoginSubmit}
                      handleSubmit={handleSubmit}
                      register={register}
                      errors={errors}
                      isSubmitting={isSubmitting}
                      setAuthBoxType={setAuthBoxType}
                      toggleSignupForm={toggleSignupForm}
                    />
                  ) : null}
                  {authBoxType === "signup" && globalData.SignupForm ? (
                    <SignUp
                      onSignUpSubmit={onSignUpSubmit}
                      handleSubmit={handleSubmit}
                      register={register}
                      errors={errors}
                      isSubmitting={isSubmitting}
                      setAuthBoxType={setAuthBoxType}
                      toggleLoginForm={toggleLoginForm}
                    />
                  ) : null}
                </>
              )}
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    globalData: state.globalData,
    apiData: state.apiData,
    loginData: state.loginData,
    profile: state.profile,
    cart: state.cart,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    clearLoginData: () => {
      dispatch(clearLoginData());
    },
    getUserAuth: () => {
      dispatch(getUserAuth());
    },
    setAuth: (data) => {
      dispatch(setAuth(data));
    },
    setLoginPopup: (stage) => {
      dispatch(setLoginPopup(stage));
    },
    fetchCars: () => {
      dispatch(fetchCars());
    },
    setCarPopup: (status) => {
      dispatch(setCarPopup(status));
    },
    setShowMobileIstand: (flag) => {
      dispatch(setShowMobileIstand(flag));
    },
    setCartProducts: (data) => {
      dispatch(setCartProducts(data));
    },
    setCartJobItems: (data) => {
      dispatch(setCartJobItems(data));
    },
    setCartAddons: (data) => {
      dispatch(setCartAddons(data));
    },
    setCartWorkshop: (data) => {
      dispatch(setCartWorkshop(data));
    },
    toggleSearch: (stage) => {
      dispatch(toggleSearch(stage));
    },
    toggleSosForm: (stage) => {
      dispatch(toggleSosForm(stage));
    },
    toggleChooseCar: (stage) => {
      dispatch(toggleChooseCar(stage));
    },
    toggleSignupForm: (stage) => {
      dispatch(toggleSignupForm(stage));
    },
    toggleLoginForm: (stage) => {
      dispatch(toggleLoginForm(stage));
    },
    toggleLocation: (stage) => {
      dispatch(toggleLocation(stage));
    },
    toggleManageCar: (stage) => {
      dispatch(toggleManageCar(stage));
    },
    setLoader: (data) => {
      dispatch(setLoader(data));
    },
    getVariants: (id, mId, WId) => {
      dispatch(getVariants(id, mId, WId));
    },
    setAccessLocation: (data) => {
      dispatch(setAccessLocation(data));
    },
    fetchCustomerCart: () => {
      dispatch(fetchCustomerCart());
    },
    getProfileData: () => {
      dispatch(getProfileData());
    },
    toggleUpdateProfile: (data) => {
      dispatch(toggleUpdateProfile(data));
    },
    setOtpScreen: (data) => {
      dispatch(setOtpScreen(data));
    },
    setCartStage: (stage) => {
      dispatch(setCartStage(stage));
    },
    setCartJourney: (stage) => {
      dispatch(setCartJourney(stage));
    },
    setSelctedId: (stage) => {
      dispatch(setSelctedId(stage));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserAuthentication);
