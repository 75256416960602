import React from "react";
import styles from "@assets/css/home.module.css";
import { handleClassNames } from "lib/cssHelpers";
import { useState } from "react";
import { useEffect } from "react";
import AssureStrip from "./assure-strip";
import Sliderblock from "./sliderblock";
import KtFeatured from "./kt-featured";
import TpSelling from "./tp-selling";
import AssureMobile from "./assure-mobile";
import ReviewSection from "./review-section";
import OfferSection from "./offer-section";
import CardSlide from "./card-slide";
import BrandsSection from "./brands-section";
import Faq from "./Faq";
import SOS from "components/HeroSlider/SOS";
import { FaTimes } from "react-icons/fa";
import { Container } from "react-bootstrap";
import { OffCanvas, OffCanvasMenu } from "react-offcanvas";
import Scrollbars from "react-custom-scrollbars";
import { isMobile } from "react-device-detect";
import {
  setHideHeader,
  setLoader,
  toggleChooseCar,
  toggleLocation,
  toggleLoginForm,
  toggleManageCar,
  toggleNotification,
  toggleSearch,
  toggleSignupForm,
  toggleSosForm,
} from "redux/actions/globalAction";
import { connect } from "react-redux";
import { useRouter } from "next/router";
import CarSlideMobile from "./car-slide-mobile";
import ConfirmationModal from "components/common/confirmationModal";

const CustomHome = ({
  toggleLocation,
  toggleLoginForm,
  toggleSosForm,
  toggleChooseCar,
  toggleSignupForm,
  toggleManageCar,
  toggleSearch,
  globalData,
  setLoader,
  setHideHeader,
}) => {
  const [showSos, setShowSos] = useState(false);
  const router = useRouter();
  const [show, setShow] = useState(false);
  const handleClasses = (classValue) => {
    return handleClassNames(classValue, styles);
  };

  const handleScroll = () => {
    const scrollTop = window?.scrollY || document.documentElement.scrollTop;
    if (router.pathname == "/") {
      const scrollPosition = window?.scrollY;
      if (scrollPosition >= 700) {
        setHideHeader(true);
      } else if (scrollTop <= 700) {
        setHideHeader(false);
      }
    } else {
      setHideHeader(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      setHideHeader(false);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleShowSos = () => {
    setShowSos(true);
    toggleSosForm(true);
  };

  useEffect(() => {
    localStorage.removeItem("pro_click");
    setShowSos(false);
    toggleLocation(false);
    toggleLoginForm(false);
    toggleSosForm(false);
    toggleChooseCar(false);
    toggleSignupForm(false);
    toggleManageCar(false);
    toggleSearch(false);
    toggleNotification(false);
  }, []);

  return (
    <div>
      <main className={handleClasses("main-block")}>
        <ConfirmationModal
          show={show}
          onHide={() => {
            setShow(false);
          }}
        />
        <AssureStrip />
        {!isMobile ? (
          <OffCanvas
            className="bg-layer"
            width={isMobile ? "100%" : 500}
            transitionDuration={isMobile ? "100%" : 500}
            effect={"parallax"}
            isMenuOpened={showSos && globalData.sosForm}
            position={"right"}
          >
            <OffCanvasMenu
              className={"sos-menu-class"}
              style={{
                paddingTop: 150,
                color: "#000",
                cursor: "default",
                boxShadow: "0 0 8px 2px #00000036",
                zIndex: 10,
              }}
            >
              <Scrollbars>
                <Container style={{ zIndex: 999 }}>
                  <SOS setSos={showSos} />
                </Container>
              </Scrollbars>
            </OffCanvasMenu>
          </OffCanvas>
        ) : null}
        {isMobile && showSos && globalData?.sosForm ? (
          <div style={{ marginTop: "100px" }} className="sos-mob mt-0 pt-0">
            <FaTimes
              style={{ fontSize: "20px" }}
              className="sos-close"
              onClick={() => {
                toggleSosForm(false);
                setShowSos(!showSos);
              }}
            />
            <Container className="pt-3" style={{ marginBottom: "220px" }}>
              <SOS setSos={setShowSos} />
            </Container>
          </div>
        ) : null}
        <Sliderblock handleShowSos={handleShowSos} />
        <div className="show-section-web">
          <KtFeatured />
          <TpSelling />
        </div>
        <div className="show-section-mobile">
          <TpSelling />
          <KtFeatured />
        </div>
        <div className="show-section-mobile">
          {isMobile && (
            <CarSlideMobile setLoader={setLoader} globalData={globalData} />
          )}
        </div>
        <div className="assure-section-mobile-margin">
          <AssureMobile />
        </div>
        <OfferSection />
        <div className="show-section-web">
          <CardSlide setLoader={setLoader} />
        </div>
        <BrandsSection />
        <ReviewSection />
        <div className="margin-top-custom">
          <Faq setLoader={setLoader} />
        </div>
      </main>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    globalData: state.globalData,
    apiData: state.apiData,
    loginData: state.loginData,
    profile: state.profile,
    cart: state.cart,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleSearch: (stage) => {
      dispatch(toggleSearch(stage));
    },
    toggleSosForm: (stage) => {
      dispatch(toggleSosForm(stage));
    },
    toggleChooseCar: (stage) => {
      dispatch(toggleChooseCar(stage));
    },
    toggleSignupForm: (stage) => {
      dispatch(toggleSignupForm(stage));
    },
    toggleLoginForm: (stage) => {
      dispatch(toggleLoginForm(stage));
    },
    toggleLocation: (stage) => {
      dispatch(toggleLocation(stage));
    },
    toggleManageCar: (stage) => {
      dispatch(toggleManageCar(stage));
    },
    setLoader: (flag) => {
      dispatch(setLoader(flag));
    },
    toggleNotification: (flag) => {
      dispatch(toggleNotification(flag));
    },
    setHideHeader: (flag) => {
      dispatch(setHideHeader(flag));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomHome);
