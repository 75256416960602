import { useEffect, useState } from "react";
import { Button, Col, Container, Modal, Row, Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import {
  fetchCustomPlanList,
  getEmirates,
  getModel,
} from "../../redux/actions/apiActions";
import Skeleton from "react-loading-skeleton";
import { fetchCars } from "../../redux/actions/profileAction";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import {
  getCarBrandModal,
  setLayer,
  setLoader,
  setLoginPopup,
  toggleChooseCar,
} from "../../redux/actions/globalAction";
import { isMobile } from "react-device-detect";
import { useRouter } from "next/router";
import ConfirmCartChange from "./ConfirmCartChange";
import { resetCart } from "lib/cart";
import {
  setCartAddedPackages,
  setCartJobItems,
  setCartProducts,
  setCartStage,
  setCartWorkshop,
  setShowMobileIstand,
} from "redux/actions/cartAction";
import ServiceSelection from "components/Header/ChooseCar/ServiceSelection";
import { IoMdArrowRoundBack } from "react-icons/io";

function ChooseCarMobile({
  show,
  setShow,
  apiData,
  getModel,
  globalData,
  getCarBrandModal,
  setLoader,
  loginData,
  toggleChooseCar,
  cart,
  setCartStage,
  setCartProducts,
  setCartJobItems,
  setCartWorkshop,
  setCartAddedPackages,
  setLayer,
  setShowMobileIstand,
}) {
  const [addState, setAddState] = useState("brand");
  const [brandList, setBrandList] = useState([]);
  const [modelList, setModelList] = useState([]);
  const [brand, setbrand] = useState({});
  const [model, setModel] = useState({});
  const [showModal, setShowModal] = useState(false);
  const router = useRouter();
  const [showAlert, setShowAlert] = useState(false);
  const [showLead, setShowLead] = useState(false);
  const [modalDetails, setModalDetails] = useState({});

  const handleClose = () => {
    toggleChooseCar(false);
    setShow(false);
    setAddState("brand");
  };

  const handleLeadPopup = () => {
    const selectionModal = JSON.parse(
      localStorage.getItem("start-here-modal") || JSON.stringify("")
    );
    const selectionBrand = JSON.parse(
      localStorage.getItem("start-here-brand") || JSON.stringify("")
    );

    const isServicesOrHome =
      router.pathname === `/services/[...slug]` || router.pathname === `/`;
    const isLeadSubmitted = localStorage.getItem("lead-submitted");

    if (
      selectionBrand &&
      selectionModal &&
      !isLeadSubmitted &&
      isServicesOrHome
    ) {
      localStorage.removeItem("toggleback");
      setShowLead(true);
      setAddState("");
      const modalDetails = {
        ...selectionModal,
        brandName: selectionBrand?.Name || "", // Provide a default value if Name is missing
      };
      setModalDetails(modalDetails);
    }
  };
  useEffect(() => {
    handleLeadPopup();
  }, [show]);

  const handleCloseLead = () => {
    setShowLead(false);
  };

  useEffect(() => {
    if (
      !sessionStorage.getItem("isWorkshopCollection") &&
      ((cart.products && cart.products?.length && show) ||
        (cart.workshop && cart?.jobItems && show) ||
        (cart.addedPackages && cart.addedPackages?.length && show))
    ) {
      setShowAlert(true);
      return;
    } else {
      setShowModal(show);
    }
    return () => {
      // localStorage.removeItem("toggleback");
    };
  }, [show]);

  useEffect(() => {
    if (!isMobile) {
      return;
    }
    if (globalData.showCarPop == "brand") {
      setAddState("brand");
      setShow(true);
    } else if (globalData.showCarPop == "model") {
      setAddState("model");
      setShow(true);
    } else if (globalData.showCarPop == null) {
      setAddState("brand");
      setShow(false);
      // setBrandHide(false);
    }
  }, [globalData.showCarPop]);

  useEffect(() => {
    if (globalData.carData?.brand) {
      //   setAddState(false);
      setbrand({
        Name: globalData.carData?.brand,
        ImgUrl: globalData.carData?.brandUrl,
      });
      setModel({
        Name: globalData.carData?.modal,
        ImgUrl: globalData.carData?.modalUrl,
        ID: globalData.carData?.modelId,
      });
    }
  }, [globalData.carData]);

  useEffect(() => {
    setBrandList(apiData.brands);
  }, [apiData.brands]);

  useEffect(() => {
    setModelList(apiData.models);
  }, [apiData.models]);

  const searchBrand = (e) => {
    let text = String(e.target.value).toLowerCase();
    if (text.length == 0) {
      setBrandList(apiData.brands);
    } else {
      const filteredBrands = apiData.brands.filter((e) => {
        return e.Name.toLowerCase().includes(text);
      });
      setBrandList(filteredBrands);
    }
  };

  const searchModel = (e) => {
    let text = String(e.target.value).toLowerCase();
    if (text.length == 0) {
      setModelList(apiData.models);
    } else {
      const filteredModels = apiData.models.filter((e) => {
        return e.Name.toLowerCase().includes(text);
      });
      setModelList(filteredModels);
    }
  };

  const onBrandSelect = (selectedBrand) => {
    localStorage.setItem("start-here-brand", JSON.stringify(selectedBrand));
    setbrand(selectedBrand);
    setAddState("model");
    getModel(selectedBrand.ID);
  };

  const saveGlobalCar = () => {
    const selectedModel = JSON.parse(
      localStorage.getItem("start-here-modal") || JSON.stringify("")
    );
    const brand = JSON.parse(
      localStorage.getItem("start-here-brand") || JSON.stringify("")
    );
    getCarBrandModal(
      brand.Name,
      selectedModel.Name,
      brand.ImgUrl,
      selectedModel.ImgUrl,
      brand.ID,
      selectedModel.ID,
      selectedModel.Slug
    );
    localStorage.removeItem("start-here-modal");
    localStorage.removeItem("start-here-brand");
    setShow(false);
  };

  const onModelSelect = (selectedModel) => {
    localStorage.setItem("start-here-modal", JSON.stringify(selectedModel));
    setModalDetails({ ...brand, ...selectedModel });
    setModel(selectedModel);
    if (
      Boolean(
        (!globalData.carData &&
          !localStorage.getItem("car_data") &&
          (router.pathname == "/" ||
            router.pathname === `/services/[...slug]`)) ||
          localStorage.getItem("toggleback")
      ) &&
      !(loginData && loginData.userAuth)
    ) {
      localStorage.removeItem("toggleback");
      setShowLead(true);
      setAddState("");
    } else {
      handleClose();
      getCarBrandModal(
        brand.Name,
        selectedModel.Name,
        brand.ImgUrl,
        selectedModel.ImgUrl,
        brand.ID,
        selectedModel.ID,
        selectedModel.Slug
      );

      if (
        router.pathname === `/services/[...slug]` &&
        router.query.slug &&
        localStorage.getItem("url_data")
      ) {
        router.push(
          {
            pathname: `/services/[...slug]`,
          },
          `/services/${
            globalData.locationData?.data.EmirateAreaSlug || "dubai"
          }/${router.query.slug[1]}/${selectedModel.Slug || "car"}${
            router.query.slug[3] ? "/" + router.query.slug[3] : "/"
          }${router.query.id ? "?id=" + router.query.id : ""}`,
          { shallow: true }
        );
      } else {
        if (router.pathname === `/services/[...slug]`) {
          router.push(
            `/services/${
              globalData.locationData?.data.EmirateAreaSlug || "dubai"
            }/${router.query.slug[1]}/${selectedModel.Slug || "car"}`
          );
        }
      }

      // getModel(selectedModel.ID)
    }
  };

  const hideAlert = () => {
    setShowAlert(false);
    toggleChooseCar(false);
  };

  const handleResetCart = () => {
    
    setCartStage(null);
    setCartProducts([]);
    setCartJobItems([]);
    setCartWorkshop(null);
    setCartAddedPackages([]);
    setLayer(false);
    resetCart()
      .then((res) => {
        localStorage.setItem("hasLocationPermission", "granted");
        if (router.query?.slug) {
          setShowAlert(false);
          router.push(
            {
              pathname: `/services/[...slug]`,
            },
            `/services/${
              globalData.locationData?.data.EmirateAreaSlug || "dubai"
            }/${router.query?.slug[1] ? router.query?.slug[1] : ""}${
              router.query.slug[2] ? "/" + router.query?.slug[2] : ""
            }`,
            { shallow: true }
          );
          setShowModal(show);
          setShowMobileIstand(false);
        } else {
          setShowAlert(false);
          setShowModal(show);
        }
      })
      .catch((err) => {
        toast.dismiss();
        toast.error("Something went wrong! Please retry.");
      });
  };

  const handlebackClick = () => {
    setShowLead(false);
    setAddState("brand");
    localStorage.setItem("toggleback", true);
  };

  const urlData =
    typeof window !== "undefined" ? localStorage.getItem("url_data") : null;

  // Rest of your component code

  return (
    <>
      <ConfirmCartChange
        show={showAlert}
        handleResetCart={handleResetCart}
        onHide={hideAlert}
        paragraph={"Change of car will reset the items in your cart. Proceed?"}
      />
      <Modal show={showModal} onHide={handleClose}>
        <div className="mobile-start-here">
          {showLead ? (
            <div className="d-flex justify-content-between cstp-bar">
              <button className="bck-btn" onClick={() => handlebackClick()}>
                <span className="bck-icon">
                  {" "}
                  <IoMdArrowRoundBack />{" "}
                </span>
                Back
              </button>
              <div className="img-tp-rightbar">
                <img
                  src={modalDetails?.ImgUrl || globalData?.carData?.modalUrl}
                  className=""
                  height="45px"
                  alt="brand"
                />

                <div className="car-rght-new">
                  {" "}
                  <h5>
                    {modalDetails?.brandName || globalData.carData?.brand}
                  </h5>
                  <span>{modalDetails?.Name || globalData.carData?.modal}</span>
                </div>
              </div>
            </div>
          ) : null}
        </div>
        <Modal.Body className="start-here-body">
          {!showLead ? (
            addState === "brand" ? (
              <Container>
                <Row>
                  <div className="d-flex justify-content-between align-items-center w-100 mb-3">
                    <h3 style={urlData ? { lineHeight: "0.9" } : {}}>
                      Choose Your Car <br />
                      {urlData ? (
                        <span
                          style={{
                            fontSize: "15px",
                            paddingLeft: "5px",
                            marginTop: "0px",
                          }}
                        >
                          To View Job Price
                        </span>
                      ) : null}
                    </h3>

                    <p
                      role="button"
                      className="shadow p-2 bg-white rounded-circle"
                      onClick={handleClose}
                      style={{ background: "#F5F5F5" }}
                    >
                      <img src="/assets/close.svg" alt="close" />
                    </p>
                  </div>
                  <div className="d-flex justify-content-between align-items-center shadow w-100 mb-3 p-2">
                    <a>
                      <img
                        src="/assets/search.svg"
                        width={35}
                        className={"pl-2"}
                        alt="search"
                      />
                    </a>
                    <input
                      type="text"
                      placeholder="Search Brand"
                      onChange={searchBrand}
                      style={{
                        width: "90%",
                        height: 40,
                        border: "none",
                        marginLeft: "5%",
                      }}
                    />
                  </div>
                </Row>
                <Row>
                  {brandList && brandList.length ? (
                    brandList.map((e) => (
                      <Col
                        xs={4}
                        role="button"
                        className="justify-content-between text-center align-items-center p-2 mt-4"
                        onClick={() => onBrandSelect(e)}
                      >
                        <img
                          src={e.ImgUrl}
                          className={"p-2 brand-item"}
                          alt="brand"
                          style={{ width: "80%" }}
                        />
                        <div className="text-center">{e.Name}</div>
                      </Col>
                    ))
                  ) : (
                    <Col xs={12}>
                      {!apiData.brands?.length ? (
                        <div className="d-flex justify-content-center align-items-center">
                          <div>
                            <Spinner
                              variant="primary"
                              size="sm"
                              animation="border"
                              role="status"
                            ></Spinner>
                          </div>
                          <p className="visually-hidden mx-2">Loading...</p>
                        </div>
                      ) : (
                        <div className="text-center mt-1">No Data Found</div>
                      )}
                    </Col>
                  )}
                </Row>
              </Container>
            ) : (
              <>
                {addState === "model" && (
                  <>
                    <Container>
                      <Row>
                        <div className="d-flex justify-content-between align-items-center w-100 mb-1">
                          <h3>Select Model</h3>
                          <p
                            role="button"
                            className="shadow p-2 bg-white rounded-circle"
                            onClick={handleClose}
                            style={{ background: "#F5F5F5" }}
                          >
                            <img src="/assets/close.svg" alt="close" />
                          </p>
                        </div>
                      </Row>
                    </Container>
                    <Container fluid>
                      <Row className="my-3 align-items-center">
                        <img
                          src="/assets/arrow-left.svg"
                          role="button"
                          width={25}
                          alt="arrow-left"
                          className={"pl-2"}
                          onClick={() => {
                            setAddState("brand");
                            setbrand({});
                          }}
                        />
                        <img
                          src={brand.ImgUrl}
                          className="p-2"
                          height="50px"
                          alt="brand"
                        />
                        <h5
                          className="m-0 font-weight-bold"
                          style={{ color: "#0451be", fontSize: "14px" }}
                        >
                          {brand.Name}
                        </h5>
                      </Row>
                    </Container>
                    <Container>
                      <Row>
                        <div className="d-flex justify-content-between align-items-center shadow w-100 mb-3 p-2">
                          <a>
                            <img
                              src="/assets/search.svg"
                              width={35}
                              className={"pl-2"}
                              alt="search"
                            />
                          </a>
                          <input
                            type="text"
                            placeholder="Search Model"
                            onChange={searchModel}
                            style={{
                              width: "90%",
                              height: 40,
                              border: "none",
                              marginLeft: "5%",
                            }}
                          />
                        </div>
                      </Row>

                      <Row>
                        {modelList?.length === 0 ? (
                          <>
                            {[1, 2, 3, 4, 5, 6, 7, 8, 9].map(() => {
                              return (
                                <Col xs={4} role="button" className="mt-4">
                                  <Skeleton height={80} />
                                  <Skeleton count={1} />
                                </Col>
                              );
                            })}
                          </>
                        ) : null}
                        {modelList && modelList.length
                          ? modelList.map((e) => (
                              <Col
                                xs={4}
                                role="button"
                                className="mt-4"
                                onClick={() => onModelSelect(e)}
                              >
                                <img
                                  src={e.ImgUrl}
                                  className={" "}
                                  alt="brand"
                                />
                                <p
                                  style={{
                                    fontSize: "12px",
                                    color: "#000",
                                  }}
                                  className="text-center"
                                >
                                  {e.Name}
                                </p>
                              </Col>
                            ))
                          : null}
                      </Row>
                    </Container>
                  </>
                )}
              </>
            )
          ) : (
            <ServiceSelection
              handleServiceSelection={saveGlobalCar}
              handleCloseLead={handleCloseLead}
            />
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    apiData: state.apiData,
    globalData: state.globalData,
    loginData: state.loginData,
    cart: state.cart,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getModel: (id) => {
      dispatch(getModel(id));
    },
    getEmirates: () => {
      dispatch(getEmirates());
    },
    fetchCars: () => {
      dispatch(fetchCars());
    },
    getCarBrandModal: (
      brand,
      modal,
      logoBrand,
      logoModal,
      brandId,
      modelId,
      slug = null
    ) => {
      dispatch(
        getCarBrandModal(
          brand,
          modal,
          logoBrand,
          logoModal,
          brandId,
          modelId,
          slug
        )
      );
    },
    setLoginPopup: (stage) => {
      dispatch(setLoginPopup(stage));
    },
    fetchCustomPlanList: () => {
      dispatch(fetchCustomPlanList());
    },
    setLoader: (data) => {
      dispatch(setLoader(data));
    },
    toggleChooseCar: (data) => {
      dispatch(toggleChooseCar(data));
    },
    setLayer: (status) => {
      dispatch(setLayer(status));
    },
    setCartStage: (stage) => {
      dispatch(setCartStage(stage));
    },
    setCartProducts: (products) => {
      dispatch(setCartProducts(products));
    },
    setCartJobItems: (jobs) => {
      dispatch(setCartJobItems(jobs));
    },
    setCartWorkshop: (data) => {
      dispatch(setCartWorkshop(data));
    },
    setCartAddedPackages: (packages) => {
      dispatch(setCartAddedPackages(packages));
    },
    setShowMobileIstand: (packages) => {
      dispatch(setShowMobileIstand(packages));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChooseCarMobile);
