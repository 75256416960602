import axios from "axios";

export const GetInfo = (callback = (lat, lang) => {}, callError = () => {}) => {
  const fetchLocationFromAPI = async (url) => {
    try {
      const { data: res } = await axios.post(url, { homeMobileCountryCode: '971'});
      const lat = Number(res.location?.lat);
      const lang = Number(res.location?.lng);
      localStorage.setItem(
        "geolocation",
        JSON.stringify({ location: { lat: lat, lng: lang }, accuracy: 0 })
      );
      callback(lat, lang);
    } catch (error) {
      console.log(error);
        callError();
    }
  };
  let googleGeolocationURL;
  if (process.env.NEXT_PUBLIC_IS_DEV) {
    googleGeolocationURL = `/api/geolocation`;
  } else {
    googleGeolocationURL = `https://www.googleapis.com/geolocation/v1/geolocate?key=${process.env.NEXT_PUBLIC_GOOGLE_MAP_KEY}`;
  }
  if (localStorage.getItem("geolocation")) {
    const res = JSON.parse(localStorage.getItem("geolocation"));
    const lat = Number(res.location?.lat);
    const lang = Number(res.location?.lng);
    callback(lat, lang);
    return;
  }
  return fetchLocationFromAPI(googleGeolocationURL);
};
