import React, { useEffect, useState } from "react";
import Layout from "../../layouts/Layout";
import { connect } from "react-redux";
import ProfilePage from "../../components/profile/elements/profile/ProfilePage";
import ProfileLayout from "../../components/profile/ProfileLayout";
import { isLoggedIn } from "../../lib/auth";
import { useRouter } from "next/dist/client/router";
import authenticatedRoute from "../../components/auth/AuthenticateRoute";

const profile = ({ slug, apiData, profile }) => {

  const router = useRouter();
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    isLoggedIn().catch((err) => router.push("/"));
    setMounted(true);
  }, []);

  return mounted ? (
    <Layout title={"Block in ROBOTS.TXT"}>
      <ProfileLayout slug={"profile"}>
        <ProfilePage />
      </ProfileLayout>
    </Layout>
  ) : <></>;
};

const mapStateToProps = (state) => {
  return {
    apiData: state.apiData,
    globalData: state.globalData,
    profile: state.profile,
  };
};

const mapDispatchToProps = (dispatch) => { return {}; };

export default authenticatedRoute(connect(mapStateToProps, mapDispatchToProps)(profile));