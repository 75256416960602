import { category } from "../../lib/data/category";
import {
  FETCH_CATEGORY,
  FETCH_COORDINATES,
  GET_VARIANT,
  FETCH_CAR_BRAND,
  FETCH_CAR_MODEL,
  EMIRATES,
  OB_POINT_GENERIC,
  OB_POINT_JOURNEY_CATEGORY,
  GET_ERROR,
  EMIRATES_AREA,
  JOB_SECTION,
  CATEGORY_BY_SECTION,
  WORKSHOPS,
  WORKSHOPS_ADDONS,
  WORKSHOPS_WORKING_HOUR,
  WORKSHOPS_JOURNEY_CATEGORY,
  WORKSHOPS_JOURNEY_VARIANT,
  WORKSHOPS_ADDONS_BY_VARIANT,
  WORKSHOPS_JOB_WITH_VARIANT,
  JOB_SEARCH,
  PACKAGES,
  PURCHASE_ORDER,
  CUSTOMER_PURCHASE_ORDER,
  CUSTOMER_QUOTATION_REQUEST,
  WORKSHOP_AMENITIES,
  TOP_SELLING,
  WORKSHOP_FILTER_MAP,
  BRANDS,
  WORKSHOPS_PRODUCT_CATEGORY,
  FETCH_AVATAR,
  FETCH_RECOMMENDED_BY_BRAND_WORKSHOP,
  FETCH_RECOMMENDED_OB_ASSURED_WORKSHOP,
  FETCH_RECOMMENDED_WORKSHOP,
  FETCH_LEVEL_UP_MEMBERSHIP,
  FETCH_DEFAULT_WORKSHOP,
  LOWEST_PACKAGES,
  FETCH_HOME_CAR_BRAND,
  FETCH_CUSTOM_PLAN_LIST,
  FETCH_WORKSHOP_IMAGES,
  FETCH_SUPER_CAR_LEAD_ITEMS,
  FETCH_SUPER_CAR_BRAND,
  RESET_STORE,
  GET_OFFER_CATEGORIES,
  FETCH_HOMEPAGE_BANNER,
  FETCH_REQUESTPAGE_BANNER,
  GET_NEW_TYRES_VARIANT,
} from "../actions/types";

const initState = {
  categories: category.category || null,
  coordinates: [],
  variants: [],
  tyreVariants: [],
  brands: [],
  superCarBrand: [],
  homeBrands: [],
  models: [],
  emirates: [],
  emiratesArea: [],
  obPointsGeneric: [],
  obPointsJourneyCategory: [],
  errors: [],
  jobSection: [],
  categoryBySection: [],
  workshops: [],
  workshopsAddons: [],
  workshopWorkingHour: [],
  workshopJourneyCategory: [],
  productCategory: [],
  workshopJourneyVariant: [],
  workshopAddonByVariant: [],
  workshopJobWithVariant: [],
  jobSearch: [],
  packages: [],
  lowestPackages: [],
  purchaseOrder: [],
  customerPurchaseOrder: [],
  customerQuotationRequest: [],
  amenities: [],
  topSelling: [],
  workshopFilter: [],
  workshopFilterMap: [],
  workshopBrands: [],
  avatars: [],
  recommendedWorkshops: null,
  recommendedOBAssuredWorkshops: null,
  recommendedByBrandWorkshops: null,
  levelupMembership: null,
  defaultWorkshop: null,
  customPlanList: null,
  superCarLeadItems: [],
  workshopCoverImages: [],
  offerCategories: [],
  homePageBanner:[],
  reqeustPageBanner:[]
};

const apiReducer = (state = initState, action) => {
  switch (action.type) {
    case FETCH_CATEGORY:
      return {
        ...state,
        categories: action.payload,
      };
    case FETCH_COORDINATES:
      return {
        ...state,
        coordinates: action.payload,
      };
    case GET_VARIANT:
      return {
        ...state,
        variants: action.payload,
      };
      case GET_NEW_TYRES_VARIANT:
        return {
          ...state,
          tyreVariants: action.payload,
        };
    case FETCH_CAR_BRAND:
      return {
        ...state,
        brands: action.payload,
      };
    case FETCH_SUPER_CAR_BRAND:
      return {
        ...state,
        superCarBrand: action.payload,
      };
    case FETCH_HOME_CAR_BRAND:
      return {
        ...state,
        homeBrands: action.payload,
      };
    case FETCH_CAR_MODEL:
      return {
        ...state,
        models: action.payload,
      };
    case EMIRATES:
      return {
        ...state,
        emirates: action.payload,
      };
    case GET_OFFER_CATEGORIES:
      return {
        ...state,
        offerCategories: action.payload,
      };

      case FETCH_HOMEPAGE_BANNER:
      return {
        ...state,
        homePageBanner: action.payload,
      };

      case FETCH_REQUESTPAGE_BANNER:
        return {
          ...state,
          reqeustPageBanner: action.payload,
        };



    case EMIRATES_AREA:
      return {
        ...state,
        emiratesArea: action.payload,
      };
    case OB_POINT_GENERIC:
      return {
        ...state,
        obPointsGeneric: action.payload,
      };
    case OB_POINT_JOURNEY_CATEGORY:
      return {
        ...state,
        obPointsJourneyCategory: action.payload,
      };
    case JOB_SECTION:
      return {
        ...state,
        jobSection: action.payload,
      };
    case CATEGORY_BY_SECTION:
      return {
        ...state,
        categoryBySection: action.payload,
      };
    case WORKSHOPS:
      return {
        ...state,
        workshops: action.payload,
      };
    case WORKSHOPS_ADDONS:
      return {
        ...state,
        workshopsAddons: action.payload,
      };
    case WORKSHOPS_WORKING_HOUR:
      return {
        ...state,
        workshopWorkingHour: action.payload,
      };
    case WORKSHOPS_JOURNEY_CATEGORY:
      return {
        ...state,
        workshopJourneyCategory: action.payload,
      };
    case WORKSHOPS_JOURNEY_VARIANT:
      return {
        ...state,
        workshopJourneyVariant: action.payload,
      };
    case WORKSHOPS_ADDONS_BY_VARIANT:
      return {
        ...state,
        workshopAddonByVariant: action.payload,
      };
    case WORKSHOPS_JOB_WITH_VARIANT:
      return {
        ...state,
        workshopJobWithVariant: action.payload,
      };
    case JOB_SEARCH:
      return {
        ...state,
        jobSearch: action.payload,
      };
    case PACKAGES:
      return {
        ...state,
        packages: action.payload,
      };
    case LOWEST_PACKAGES:
      return {
        ...state,
        lowestPackages: action.payload,
      };
    case PURCHASE_ORDER:
      return {
        ...state,
        purchaseOrder: action.payload,
      };
    case CUSTOMER_PURCHASE_ORDER:
      return {
        ...state,
        customerPurchaseOrder: action.payload,
      };
    case CUSTOMER_QUOTATION_REQUEST:
      return {
        ...state,
        customerQuotationRequest: action.payload,
      };
    case WORKSHOP_AMENITIES:
      return {
        ...state,
        amenities: action.payload,
      };
    case TOP_SELLING:
      return {
        ...state,
        topSelling: action.payload,
      };
    case WORKSHOP_FILTER_MAP:
      return {
        ...state,
        workshopFilterMap: action.payload,
      };
    case BRANDS:
      return {
        ...state,
        workshopBrands: action.payload,
      };
    case WORKSHOPS_PRODUCT_CATEGORY:
      return {
        ...state,
        productCategory: action.payload,
      };
    case GET_ERROR:
      return {
        ...state,
        errors: action.payload,
      };
    case FETCH_AVATAR:
      return {
        ...state,
        avatars: action.payload,
      };
    case FETCH_RECOMMENDED_WORKSHOP:
      return {
        ...state,
        recommendedWorkshops: action.payload,
      };
    case FETCH_RECOMMENDED_OB_ASSURED_WORKSHOP:
      return {
        ...state,
        recommendedOBAssuredWorkshops: action.payload,
      };
    case FETCH_RECOMMENDED_BY_BRAND_WORKSHOP:
      return {
        ...state,
        recommendedByBrandWorkshops: action.payload,
      };
    case FETCH_LEVEL_UP_MEMBERSHIP:
      return {
        ...state,
        levelupMembership: action.payload,
      };
    case FETCH_DEFAULT_WORKSHOP:
      return {
        ...state,
        defaultWorkshop: action.payload,
      };
    case FETCH_CUSTOM_PLAN_LIST:
      return {
        ...state,
        customPlanList: action.payload,
      };
    case FETCH_SUPER_CAR_LEAD_ITEMS:
      return {
        ...state,
        superCarLeadItems: action.payload,
      };
    case FETCH_WORKSHOP_IMAGES:
      return {
        ...state,
        workshopCoverImages: action.payload,
      };

    default:
      return state;
  }
};

export default apiReducer;
