import axios from "axios";
import {
  ADDON,
  BATTERY_SELECTION_ITEMS,
  CART_DATA,
  CAR_BRAND_MODAL,
  LOCATION_DATA,
  SERVICE,
  SET_ACCESS_LOCATION,
  SET_CAR_POPUP,
  SET_CURRENT_CATEGORY,
  SET_LAYER,
  SET_LOADER,
  SET_LOGIN_POPUP,
  SET_OTP_SCREEN,
  SET_SAVE_LOCATION,
  SET_SELECTED_ID,
  SET_TOUR_BOX,
  SHOW_JOB_SCREEN,
  TOGGLE_CHOOSE_CAR,
  TOGGLE_LOCATION,
  TOGGLE_LOGIN,
  TOGGLE_MANAGE_CAR,
  TOGGLE_NOTIFICATION,
  TOGGLE_REQUEST_FORM,
  TOGGLE_SEARCH,
  TOGGLE_SIGNUP,
  TOGGLE_SOS_FORM,
  TYRE_SELECTION_ITEMS,
  TOGGLE_HIDE_HEADER,
  ADD_TO_COMPARE,
  REMOVE_FROM_COMPARE,
  SET_NEW_CAR_SIZE,
} from "./types";
import { BsDatabase } from "react-icons/bs";
import { useEffect } from "react";

export const getCarBrandModal =
  (
    brand,
    modal,
    logoBrand,
    logoModal,
    brandId,
    modelId,
    slug = null,
    setNull = false
  ) =>
  async (dispatch) => {
    
    // if (brandId && modelId) {
    let carData = setNull
      ? null
      : {
          brand: brand,
          modal: modal,
          brandUrl: logoBrand,
          modalUrl: logoModal,
          brandId: brandId,
          modelId: modelId,
          slug,
        };
        
    localStorage.setItem("car_data", JSON.stringify(carData));
    dispatch({
      type: CAR_BRAND_MODAL,
      payload: carData,
    });
    // }
  };

export const fetchCarBrandModal = () => async (dispatch) => {
  let carData = JSON.parse(localStorage.getItem("car_data"));
  dispatch({
    type: CAR_BRAND_MODAL,
    payload: carData,
  });
};

export const setRequestForm = (data) => async (dispatch) => {
  dispatch({
    type: TOGGLE_REQUEST_FORM,
    payload: data,
  });
};

export const setHideHeader = (data) => async (dispatch) => {
  dispatch({
    type: TOGGLE_HIDE_HEADER,
    payload: data,
  });
};

export const getLocationData = (lat, lng, address) => async (dispatch) => {
  // let addressData = address
  let locationData = { lat: lat, lng: lng };
  const location = JSON.parse(
    sessionStorage.getItem("location_data") || JSON.stringify("")
  );
  const old_lat = location.lat;
  const old_lng = location.lng;
  if ((lat == 0 && lng == 0) || (old_lat == lat && old_lng == lng)) {
    return;
  }
  
  try {
    const { data } = await axios.get(
      `${process.env.NEXT_PUBLIC_API_URL}v2/api/slugs/emirate-area?lat=${lat}&lng=${lng}`
    );
    locationData.data = data.Data;
    sessionStorage.setItem("location_data", JSON.stringify(locationData));
    
    dispatch({
      type: LOCATION_DATA,
      payload: locationData,
    });
  } catch (error) {
    console.log("eeee ", error);
  }
};

export const fetchLocationData = () => async (dispatch) => {
  let locationData = JSON.parse(sessionStorage.getItem("location_data"));
  if (locationData) {
    dispatch({
      type: LOCATION_DATA,
      payload: locationData,
    });
  }
};

export const getCartData = (data) => async (dispatch) => {
  let cartData = { data: data };
  dispatch({
    type: CART_DATA,
    payload: cartData,
  });
};

export const getService = (data) => async (dispatch) => {
  let jobData = data;
  localStorage.setItem("service_data", JSON.stringify(jobData));
  dispatch({
    type: SERVICE,
    payload: jobData,
  });
};

export const getAddon = (data) => async (dispatch) => {
  let jobData = data;
  localStorage.setItem("addon_data", JSON.stringify(jobData));
  dispatch({
    type: ADDON,
    payload: jobData,
  });
};

export const fetchTyreSelectionItems = () => async (dispatch) => {
  const { data } = await axios.get(
    `${process.env.NEXT_PUBLIC_API_URL}v2/api/customer-quotation-request/tyre-sizes`
  );
  dispatch({
    type: TYRE_SELECTION_ITEMS,
    payload: data.Data,
  });
};
export const fetchNewTyreItems = (width, height, rim) => async (dispatch) => {
  const { data } = await axios.get(
    `${process.env.NEXT_PUBLIC_API_URL}v2/api/tyre-sizes/web?Width=&Height=&Rim=`
  );
  dispatch({
    type: TYRE_SELECTION_ITEMS,
    payload: data.Data,
  });
};
export const fetchNewTyreItemsHeight = (width) => async (dispatch) => {
  const { data } = await axios.get(
    `${process.env.NEXT_PUBLIC_API_URL}v2/api/tyre-sizes/web?Width=${width}&Height=&Rim=`
  );
  dispatch({
    type: TYRE_SELECTION_ITEMS,
    payload: data.Data,
  });
};
export const fetchNewTyreItemsRim = ( width, height) => async (dispatch) => {
  const { data } = await axios.get(
    `${process.env.NEXT_PUBLIC_API_URL}v2/api/tyre-sizes/web?Width=${width}&Height=${height}&Rim=`
  );
  dispatch({
    type: TYRE_SELECTION_ITEMS,
    payload: data.Data,
  });
};



export const fetchBatterySelectionItems = () => async (dispatch) => {
  const { data } = await axios.get(
    `${process.env.NEXT_PUBLIC_API_URL}v2/api/customer-quotation-request/battery-am-hrs`
  );
  dispatch({
    type: BATTERY_SELECTION_ITEMS,
    payload: data.Data,
  });
};
export const setNewCarTyreSize = (data) => async (dispatch) => {

  dispatch({
    type: SET_NEW_CAR_SIZE,
    payload: data,
  });
};

export const setLoginPopup = (flag) => async (dispatch) => {
  dispatch({
    type: SET_LOGIN_POPUP,
    payload: flag,
  });
};

export const setLayer = (status) => async (dispatch) => {
  dispatch({
    type: SET_LAYER,
    payload: status,
  });
};

export const setCarPopup = (flag) => async (dispatch) => {
  dispatch({
    type: SET_CAR_POPUP,
    payload: flag,
  });
};

export const setLoader = (flag) => async (dispatch) => {
  dispatch({
    type: SET_LOADER,
    payload: flag,
  });
};

export const setTourBox = (flag) => async (dispatch) => {
  dispatch({
    type: SET_TOUR_BOX,
    payload: flag,
  });
};

export const offerInquiry = async (req) => {
  try {
    const { data } = await axios.post(
      `${process.env.NEXT_PUBLIC_API_URL}v2/api/promotions/lead`,
      req
    );
    return data;
  } catch (error) {
    console.log(err);
  }
};

export const toggleLocation = (data) => async (dispatch) => {
  dispatch({
    type: TOGGLE_LOCATION,
    payload: data,
  });
};

export const toggleLoginForm = (data) => async (dispatch) => {
  dispatch({
    type: TOGGLE_LOGIN,
    payload: data,
  });
};

export const toggleSignupForm = (data) => async (dispatch) => {
  dispatch({
    type: TOGGLE_SIGNUP,
    payload: data,
  });
};
export const toggleChooseCar = (data) => async (dispatch) => {
  dispatch({
    type: TOGGLE_CHOOSE_CAR,
    payload: data,
  });
};

export const toggleSosForm = (data) => async (dispatch) => {
  dispatch({
    type: TOGGLE_SOS_FORM,
    payload: data,
  });
};

export const toggleSearch = (data) => async (dispatch) => {
  dispatch({
    type: TOGGLE_SEARCH,
    payload: data,
  });
};

export const toggleManageCar = (data) => async (dispatch) => {
  dispatch({
    type: TOGGLE_MANAGE_CAR,
    payload: data,
  });
};

export const toggleNotification = (data) => async (dispatch) => {
  dispatch({
    type: TOGGLE_NOTIFICATION,
    payload: data,
  });
};
export const setAccessLocation = (data) => async (dispatch) => {
  dispatch({
    type: SET_ACCESS_LOCATION,
    payload: data,
  });
};

export const setSelctedId = (data) => async (dispatch) => {
  dispatch({
    type: SET_SELECTED_ID,
    payload: data,
  });
};

export const setCurrentCategory = (data) => async (dispatch) => {
  dispatch({
    type: SET_CURRENT_CATEGORY,
    payload: data,
  });
};

export const setSaveLocation = (data) => async (dispatch) => {
  dispatch({
    type: SET_SAVE_LOCATION,
    payload: data,
  });
};

export const setOtpScreen = (data) => async (dispatch) => {
  dispatch({
    type: SET_OTP_SCREEN,
    payload: data,
  });
};

export const setjobHistoryScreen = (data) => async (dispatch) => {
  dispatch({
    type: SHOW_JOB_SCREEN,
    payload: data,
  });
};

export const AddToCompare = (data) => async (dispatch) => {
  dispatch({
    type: ADD_TO_COMPARE,
    payload: data,
  });
};
export const RemoveFromCompare = (data) => async (dispatch) => {
  dispatch({
    type: REMOVE_FROM_COMPARE,
    payload: data,
  });
};