module.exports = {
  googleReviews: [
    {
      name: " John Denver",
      car: "Honda Accord Owner",
      location: "Sharjah",
      star: "5",
      content:
        "I was skeptical at first but since they are partnered with Amazon it gave me a bit of confidence to avail services from Open Bonnet. They will assist you with the process and are highly attentive to your queries and concerns. They help their customers understand the sequences and does the job nicely with real-time support. Highly recommended car service and most specially available through Amazon.",
      img: "John.webp",
    },
    {
      name: " Bashir",
      car: "Toyota Yaris Owner",
      location: "Dubai",
      star: "5",
      content:
        "One horrible day my Yaris broke down and the engine was overheating with smoke . I went to ServiceMyCar.com and they basically robbed me and quote me a hefty price. But then I came to know about openbonnet and I thought I’d try their services. They were very professional and the advisor named rishad gave me a clear picture of what was wrong . He was very polite and also took an extra mile to be transparent. Within 1 and a half days they have me my car back in perfect condition and I feel the car is a bit smoother than before. And the price was very reasonable. I would recommend everyone to try openbonnet if they want to repair their car.",
      img: "Bashir.webp",
    },
    {
      name: " Pulastya Vishwas",
      car: "Ford Explorer",
      location: "Dubai",
      star: "5",
      content:
        "Not affiliated to them in any way - but I was quite mind-blown by the cost to repair my used Explorer with them. Runs as smooth as it can be. Can't recommend this service enough. Its tough to find reliable service garages for your car, luckily I have.",
      img: "Pulastya.webp",
    },
    {
      name: " Wycliff Onuonga",
      car: "Nissan Altima",
      location: "Dubai",
      star: "5",
      content:
        "Contacted them regarding my car vibrating when idle.  They referred me to Champion Workshop where they were able to diagnose the issue on the spot. I received the quote the same day (very reasonable prices) and brought the car for repair the next week. They replaced the engine and gearbox mounting, drive belt and tensioner within a day. They take all the guess work out of finding a professional garage at affordable prices. Fantastic service and highly recommended.",
      img: "Wycliff.webp",
    },
  ],
};

/*Bashir, Toyota Yaris Owner, Dubai
One horrible day my Yaris broke down and the engine was overheating with smoke . I went to ServiceMyCar.com and they basically robbed me and quote me a hefty price.
But then I came to know about openbonnet and I thought I’d try their services. They were very professional and the advisor named rishad gave me a clear picture of what was wrong . He was very polite and also took an extra mile to be transparent. Within 1 and a half days they have me my car back in perfect condition and I feel the car is a bit smoother than before. And the price was very reasonable. I would recommend everyone to try openbonnet if they want to repair their car.

Pulastya Vishwas, Ford Explorer, Dubai
Not affiliated to them in any way - but I was quite mind-blown by the cost to repair my used Explorer with them. Runs as smooth as it can be. Can't recommend this service enough.
Its tough to find reliable service garages for your car, luckily I have.
Wycliff Onuonga, Nissan Altima, Dubai
Contacted them regarding my car vibrating when idle.  They referred me to Champion Workshop where they were able to diagnose the issue on the spot. I received the quote the same day (very reasonable prices) and brought the car for repair the next week. They replaced the engine and gearbox mounting, drive belt and tensioner within a day.
They take all the guess work out of finding a professional garage at affordable prices. Fantastic service and highly recommended. */
