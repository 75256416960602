import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import axios from "axios";
import toast from "react-hot-toast";
import moment from "moment";
import OTPVerifyModal from "./OTPVerifyModal";
// import DatePicker from "react-date-picker/dist/entry.nostyle";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import { FaPen, FaTimes } from "react-icons/fa";
import { toggleUpdateProfile } from "redux/actions/profileAction";
import { UserLoginApi } from "actions/Auth";

const EditForm = ({ profile, profileUrl, avatar, toggleUpdateProfile }) => {
  const [firstNameEdit, setFirstNameEdit] = useState(false);
  const [lastNameEdit, setLastNameEdit] = useState(false);
  const [phoneNumberEdit, setPhoneNumberEdit] = useState(false);
  const [emailEdit, setEmailEdit] = useState(false);
  const [DOBEdit, setDOBEdit] = useState(false);
  const [showOTPVerify, setShowOTPVerify] = useState(false);
  const [profileFormData, setProfileFormData] = useState({});
  const [btnDisable, setBtnDisable] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {},
  });

  useEffect(() => {
    setValue("FirstName", profile.info.FirstName);
    setValue("LastName", profile.info.LastName);
    setValue("PhoneNumber", profile.info.PhoneNumber);
    setValue("Email", profile.info.Email);
    setValue("DOB", new Date(profile.info.DOB));
  }, [profile]);

  const onUpdateSubmit = async (data) => {
    let token = await JSON.parse(localStorage.getItem("login_access"));
    if (data.PhoneNumber !== profile.info.PhoneNumber) {
      setBtnDisable(true);
      setProfileFormData(data);
      sendOTP();
      return;
    }
    if (data.Email !== profile.info.Email) {
      await axios
        .post(
          `${process.env.NEXT_PUBLIC_API_URL}v2/api/customers/${profile.info.CustomerId}/email-change`,
          {
            oldEmail: profile.info.Email,
            newEmail: data.Email,
            comfirmEmail: data.Email,
          },
          {
            headers: {
              // "Content-Type": "application/x-www-form-urlencoded" ,
              Authorization: "bearer " + token.access_token,
            },
          }
        )
        .then(async (res) => {
          toast.dismiss();
          toast.success(
            "Verification Link Sent via Email. Please click the link to update your Email."
          );
        })
        .catch((err) => {
          toast.dismiss();
          toast.error("Something went wrong! Please retry.");
        });
      return;
    }
    if (avatar) {
      data.AvatarImageId = avatar;
    }

    await axios
      .post(
        `${process.env.NEXT_PUBLIC_API_URL}v2/api/customers/${profile.info.CustomerId}/profile`,
        data,
        {
          headers: {
            // "Content-Type": "application/x-www-form-urlencoded" ,
            "Content-Type": "multipart/form-data",
            // multipart/form-data
            Authorization: "bearer " + token.access_token,
          },
        }
      )
      .then(async (res) => {
        console.log(res);
        toast.dismiss();
        toggleUpdateProfile(true);
        toast.success("Profile Updated Successfully!");
      })
      .catch((err) => {
        console.log("Error ", err);
        toast.dismiss();
        toast.error("Something went wrong! Please retry.");
      });
  };

  const sendOTP = async () => {
    let token = await JSON.parse(localStorage.getItem("login_access"));
    const payload = {
      PhoneNumber: profile.info?.PhoneNumber,
      NewPhoneNumber: profileFormData?.PhoneNumber,
    };
    const response = await UserLoginApi(payload);

    if (response?.IsSuccess) {
      setBtnDisable(false);
      setShowOTPVerify(true);
      toast.success("OTP Sent to Mobile Number!");
    }
  };

  const onOTPVerify = async (otp) => {
    let data = profileFormData;
    data.otp = otp;
    let token = await JSON.parse(localStorage.getItem("login_access"));
    await axios
      .post(
        `${process.env.NEXT_PUBLIC_API_URL}v2/api/customers/${profile.info.CustomerId}/profile`,
        data,
        {
          headers: {
            // "Content-Type": "application/x-www-form-urlencoded" ,
            "Content-Type": "multipart/form-data",
            // multipart/form-data
            Authorization: "bearer " + token.access_token,
          },
        }
      )
      .then(async (res) => {
        toast.dismiss();
        toast.success("Profile Updated Successfully!");
        setShowOTPVerify(false);
      })
      .catch((err) => {
        console.log("Error ", err);
        toast.dismiss();
        toast.error("Something went wrong! Please retry.");
      });
  };

  return (
    <div style={{ width: "100%" }}>
      <form onSubmit={handleSubmit(onUpdateSubmit)}>
        <Row
          className="w-100"
          style={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            flexDirection: "column",
          }}
        >
          <Col
            style={{
              fontSize: "18px",
              fontWeight: "700",
              padding: "5px",
              paddingTop: "25px",
            }}
          >
            Personal Details
          </Col>
        </Row>
        <Row className="w-100 personal-details">
          <Col lg={4} md={4} sm={12} className="p-2">
            <div>First Name</div>

            <div className="d-flex ">
              {firstNameEdit ? (
                <input
                  type="text"
                  className="login-input"
                  {...register("FirstName", { required: true })}
                />
              ) : (
                <input
                  type="numeric"
                  className="login-input"
                  value={profile.info.FirstName}
                  disabled
                />
              )}

              <div
                className="d-flex align-items-center px-2"
                role="button"
                style={{ border: "1px solid #000", borderLeft: "none" }}
                onClick={() => setFirstNameEdit(!firstNameEdit)}
              >
                <p className="text-dark">
                  {firstNameEdit ? <FaTimes /> : <FaPen />}
                </p>
              </div>
            </div>
          </Col>
          <Col lg={4} md={4} sm={12} className="p-2">
            <div>Last Name</div>

            <div className="d-flex ">
              {lastNameEdit ? (
                <input
                  type="text"
                  className="login-input"
                  {...register("LastName", { required: true })}
                />
              ) : (
                <input
                  type="numeric"
                  className="login-input"
                  value={profile.info?.LastName}
                  disabled
                />
              )}

              <div
                className="d-flex align-items-center px-2"
                role="button"
                style={{ border: "1px solid #000", borderLeft: "none" }}
                onClick={() => setLastNameEdit(!lastNameEdit)}
              >
                <p className="text-dark">
                  {lastNameEdit ? <FaTimes /> : <FaPen />}
                </p>
              </div>
            </div>
          </Col>
          <Col lg={4} md={4} sm={12} className="p-2">
            <div>Mobile Number</div>
            <div className="d-flex ">
              <div
                className="d-flex align-items-center px-2"
                style={{ border: "1px solid #000", borderRight: "none" }}
              >
                <svg
                  width={31}
                  height={30}
                  viewBox="0 0 31 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.0083 29.8114C23.2886 29.8114 30.0011 23.1539 30.0011 14.9414C30.0011 6.72885 23.2886 0.0712891 15.0083 0.0712891C6.72809 0.0712891 0.015625 6.72885 0.015625 14.9414C0.015625 23.1539 6.72809 29.8114 15.0083 29.8114Z"
                    fill="#F0F0F0"
                  />
                  <path
                    d="M8.55469 20.2178L9.86998 29.0672C13.6317 30.4476 17.792 30.2911 21.4374 28.6319C25.0828 26.9727 27.9154 23.9465 29.3132 20.2178H8.55469Z"
                    fill="black"
                  />
                  <path
                    d="M8.55469 9.78162L9.86998 0.932157C13.6314 -0.447474 17.791 -0.290494 21.4359 1.36864C25.0807 3.02778 27.9129 6.05349 29.3107 9.78162H8.55469Z"
                    fill="#6DA544"
                  />
                  <path
                    d="M8.06025e-10 14.9987C-3.18685e-05 18.0616 0.944996 21.0512 2.70814 23.5658C4.47128 26.0805 6.96809 27.9997 9.86317 29.0658V0.932129C6.96817 1.99817 4.47142 3.91736 2.70829 6.4319C0.945148 8.94643 7.18711e-05 11.9359 8.06027e-10 14.9987H8.06025e-10Z"
                    fill="#A2001D"
                  />
                </svg>
                <p>+971</p>
              </div>
              {phoneNumberEdit ? (
                <input
                  type="text"
                  onKeyDown={(e) => {
                    const isCtrlKey = e.ctrlKey || e.metaKey; // Check if "ctrl" or "cmd" key is pressed
                    const keyValue = e.key;
                    const numericRegex = /^[0-9]*$/;
                    if (
                      !(
                        numericRegex.test(keyValue) ||
                        isCtrlKey ||
                        keyValue === "Backspace"
                      )
                    ) {
                      e.preventDefault();
                    }
                  }}
                  onPaste={(e) => {
                    const pastedValue = e.clipboardData.getData("text");
                    const numericRegex = /^[0-9]*$/;
                    if (!numericRegex.test(pastedValue)) {
                      e.preventDefault();
                    }
                  }}
                  onInput={(e) => {
                    if (e.target.value.length > e.target.maxLength) {
                      e.target.value = e.target.value.slice(
                        0,
                        e.target.maxLength
                      );
                    }
                  }}
                  maxLength={9}
                  className="login-input"
                  {...register("PhoneNumber", { required: true })}
                />
              ) : (
                <input
                  type="numeric"
                  className="login-input"
                  value={profile.info.PhoneNumber}
                  disabled
                />
              )}

              <div
                className="d-flex align-items-center px-2"
                role="button"
                style={{ border: "1px solid #000", borderLeft: "none" }}
                onClick={() => setPhoneNumberEdit(!phoneNumberEdit)}
              >
                <p className="text-dark">
                  {phoneNumberEdit ? <FaTimes /> : <FaPen />}
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="w-100 personal-details">
          <Col lg={6} md={6} sm={12} className="p-2">
            <div>Email</div>

            <div className="d-flex ">
              {emailEdit ? (
                <input
                  type="email"
                  className="login-input"
                  {...register("Email", { required: true })}
                />
              ) : (
                <input
                  type="numeric"
                  className="login-input"
                  value={profile.info.Email}
                  disabled
                />
              )}

              <div
                className="d-flex align-items-center px-2"
                role="button"
                style={{ border: "1px solid #000", borderLeft: "none" }}
                onClick={() => setEmailEdit(!emailEdit)}
              >
                <p className="text-dark">
                  {emailEdit ? <FaTimes /> : <FaPen />}
                </p>
              </div>
            </div>
          </Col>
          <Col lg={6} md={6} sm={12} className="p-2">
            <div>Date of Birth</div>

            <div className="d-flex ">
              {DOBEdit ? (
                <Controller
                  control={control}
                  name="DOB"
                  rules={{ required: true }}
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState,
                  }) => (
                    <DatePicker
                      className="login-input"
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />
              ) : (
                <input
                  type="numeric"
                  className="login-input"
                  value={moment(profile.info.DOB).format("L")}
                  disabled
                />
              )}

              <div
                className="d-flex align-items-center px-2"
                role="button"
                style={{ border: "1px solid #000", borderLeft: "none" }}
                onClick={() => setDOBEdit(!DOBEdit)}
              >
                <p className="text-dark">{DOBEdit ? <FaTimes /> : <FaPen />}</p>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="w-100 personal-details" style={{ paddingTop: "15px" }}>
          <Button
            type="submit"
            variant=""
            size="lg"
            className="bg-black btn-profile-update"
            disabled={isSubmitting || btnDisable}
          >
            Update & Save
          </Button>
        </Row>
      </form>
      <OTPVerifyModal
        show={showOTPVerify}
        setShow={setShowOTPVerify}
        onOTPVerify={onOTPVerify}
        sendOTP={sendOTP}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    profile: state.profile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleUpdateProfile: (data) => {
      dispatch(toggleUpdateProfile(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditForm);
