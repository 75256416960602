import { useState, useRef, useEffect } from "react";
import Typewriter from "typewriter-effect";
import { useRouter } from "next/router";
import OutsideClickHandler from "react-outside-click-handler";
import { setLoader } from "../../redux/actions/globalAction";
import { connect } from "react-redux";
import Image from "next/image"
import { fetchSearch } from "../../actions/homePage";
import IMAGES from '../../images'

const SearchHead = ({ setLoader }) => {
  const [focused, setFocused] = useState(false);
  const [data, setData] = useState([]);
  const [getText, setText] = useState("");
  const [dataO, setDataO] = useState("");
  const [searchData, setSearchData] = useState([]);
  const onFocus = () => setFocused(true);
  const onBlur = () => setFocused(false);
  const router = useRouter();

  useEffect(async () => {
    const response = await fetchSearch();
    setSearchData(response?.Data);
  }, []);

  useEffect(() => {
    let data = [];
    if (dataO == "" && searchData?.length != 0) {

      data = searchData && searchData?.map((item, index) => {
        let name;
        if (item.JourneyVariantName == null) {
          name = item.JourneyCategoryName + " (Category)";
        } else {
          name = item.JourneyVariantName + " (Job)";
        }
        return { ...item, id: index, name };
      });
      setDataO(data);
    }
  }, [searchData]);

  const search = (e) => {
    let text = String(e.target.value).toLowerCase();
    setText(text);
    if (text.length == 0) {
      setData([]);
    } else {
      const filteredBrands = dataO?.filter((e) => {
        return (
          e.JourneyVariantName?.toLowerCase().includes(text) ||
          // e.JobSection.toLowerCase().includes(text) ||
          e.JourneyCategoryName?.toLowerCase().includes(text)
        );
      });
      setData([]);
      setData(filteredBrands);
    }
  };

  const handleSearchItemClick = (e) => {
    setLoader(true);
    setData([]);
    if (e?.JourneyVariantName == null) {
      router.push(`/services/dubai-silicon-oasis/${e?.JourneyCategorySlug}`)
    } else {
      router.push(`/services/dubai-silicon-oasis/${e?.JourneyVariantSlug}`)
    }
  };

  return (
    <div
      className=" justify-content-center w-100"
      style={{ position: "relative" }}
    >
      {/* <div className="w-50" style={{position: 'absolute', top: -22}}>
        {dataO != "" && <ReactSearchAutocomplete
          items={dataO}
          // onSearch={handleOnSearch}
          // onHover={handleOnHover}
          onSelect={handleSearchItemClick}
          // onFocus={handleOnFocus}
          // onClear={handleOnClear}
          // showIcon={false}
          styling={{ zIndex: 4, padding: "40px", searchIconMargin: "6px 5px", border: '2px solid rgba(0,114,188,.5)', boxShadow: '0 0 25px rgb(0 0 0 / 25%)'}} // To display it on top of the search box below
          autoFocus
          placeholder={`Search here ...`}
        />}
      </div> */}

      <div className="input-search rowMap align-items-center">
        <div className="px-2 d-flex align-items-center">
          <Image src={IMAGES.SERACH} alt="logo" width={15} height={15} />
        </div>
        <div
          className="justify-content-center inputService custom-width"
          onClick={() => setFocused(true)}
          style={{ background: "#fff" }}
        >
          {focused ? (
            <input
              type="text"
              placeholder=""
              style={{
                fontWeight: "400",
                borderStyle: "none",
                fontSize: 14,
                width: "100%",
              }}
              onFocus={onFocus}
              onBlur={onBlur}
              onChange={search}
              value={getText}
              autoFocus
            />
          ) : (
            <div
              onClick={() => setFocused(true)}
              className={`form-control ${handleClasses("custom-search-bar")}`}
              style={{ lineHeight: "42px" }}
            >
              {/* <Typewriter
            options={{
              autoStart: true,
              loop: true,
              skipAddStyles: false,
              wrapperClassName: "search-text-type",
            }}
            onInit={(typewriter) => {
              typewriter
                .typeString("SEARCH ANY CAR SERVICE,REPAIR OR WORKSHOP NAME.")
                .callFunction(() => { })
                .pauseFor(500)
                .deleteAll()
                .callFunction(() => { })
                .start();
            }}
          /> */}
              <div className="marquee-container">
                <ul className="marquee">
                  <li className="search-Text">
                    SEARCH ANY CAR SERVICE , REPAIR OR WORKSHOP NAME.
                  </li>
                </ul>
              </div>
            </div>
          )}
        </div>
        <OutsideClickHandler onOutsideClick={() => setData([])}>
          {getText !== "" && data != "" && (
            <div
              style={{
                background: "#fff",
                width: "100%",
                height: "300px",
                position: "absolute",
                top: "68px",
                left: "0",
                boxShadow: "0px 0px 16px rgba(0, 0, 0, 0.25)",
                overflowY: "scroll",
              }}
            >
              {data.map((e, index) => (
                <div
                  className="search-bar-option"
                  onClick={() => handleSearchItemClick(e)}
                >
                  <div className="search-bar-inside text-left">
                    {e.JourneyVariantName == null ? (
                      <>{e.JourneyCategoryName}</>
                    ) : (
                      <>{e.JourneyVariantName}</>
                    )}
                  </div>
                </div>
              ))}
            </div>
          )}
        </OutsideClickHandler>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    apiData: state.apiData,
    globalData: state.globalData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setLoader: (flag) => {
      dispatch(setLoader(flag));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchHead);
