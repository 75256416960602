import { useCallback, useEffect, useRef, useState } from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { FaTimes } from "react-icons/fa";

function OTPVerifyModal({ show, setShow, onOTPVerify, sendOTP }) {
  const otpOne = useRef();
  const otpTwo = useRef();
  const otpThree = useRef();
  const otpFour = useRef();
  const otpFive = useRef();
  const otpSix = useRef();
  const [otpData1, setOtpData1] = useState(null);
  const [otpData2, setOtpData2] = useState(null);
  const [otpData3, setOtpData3] = useState(null);
  const [otpData4, setOtpData4] = useState(null);
  const [otpData5, setOtpData5] = useState(null);
  const [otpData6, setOtpData6] = useState(null);
  const [isDisabled, setIsDisabled] = useState(true);
  const [verifyStyle, setVerifyStyle] = useState(false);
  const [timer, setTimer] = useState(45);

  const timeOutCallback = useCallback(() => {
    setTimer((currTimer) => currTimer - 1);
  }, []);

  useEffect(() => {
    if (timer > 0) setTimeout(timeOutCallback, 1000);
    else setIsDisabled(false);
  }, [timer, timeOutCallback]);

  const handleResendOtp = () => {

    setIsDisabled(true);
    setTimer(45);
    sendOTP();
  };




  useEffect(() => {

    if (
      otpData1 !== null && otpData1 !== "" &&
      otpData2 !== null && otpData2 !== "" &&
      otpData3 !== null && otpData3 !== "" &&
      otpData4 !== null && otpData4 !== "" &&
      otpData5 !== null && otpData5 !== "" &&
      otpData6 !== null && otpData6 !== ""
    ) {
      setVerifyStyle(true);
    } else {
      setVerifyStyle(false);
    }
  }, [otpData1, otpData2, otpData3, otpData4, otpData5, otpData6]);


  const handleKeyPress = (e) => {
    const keyCode = e.keyCode || e.which;
    const keyValue = String.fromCharCode(keyCode);


    const isValidInput = /^[0-9]+$/.test(keyValue);
    if (!isValidInput) {
      e.preventDefault();
    }
  };

  const handleVerify = () => {
    let otp = otpData1 + otpData2 + otpData3 + otpData4 + otpData5 + otpData6;
    onOTPVerify(otp);
  };

  return (
    <Modal centered show={show} onHide={() => setShow(false)}>
      <Modal.Body className="p-2 p-sm-5">
        <FaTimes
          style={{
            cursor: "pointer",
            position: "absolute",
            right: 15,
            top: 15,
          }}
          onClick={() => {
            setTimer(45)
            setIsDisabled(false)
            setShow(false)
          }}
        />
        <div
          style={{ display: "flex", flexDirection: "column", padding: "18px", textAlign: "center" }}
        >
          <div style={{ fontSize: "25px", fontWeight: "700" }}>
            OTP verification
          </div>
          <div>
            Enter the verification code we just sent on your mobile number
          </div>
        </div>
        <Container>
          <Row>
            <Col xs={2} className="px-2">
              <input
                type="text"
                className="w-100 otp-input"
                maxLength={1}
                ref={otpOne}
                onKeyPress={handleKeyPress}
                onChange={(e) => {
                  setOtpData1(e.target.value);
                  if (e.target.value == "") {
                  } else {
                    otpTwo.current.focus();
                  }
                }}
              />
            </Col>
            <Col xs={2} className="px-2">
              <input
                type="text"
                className="w-100 otp-input"
                maxLength={1}
                ref={otpTwo}
                onKeyPress={handleKeyPress}
                onChange={(e) => {
                  setOtpData2(e.target.value);
                  if (e.target.value == "") {
                    otpOne.current.focus();
                  } else {
                    otpThree.current.focus();
                  }
                }}
              />
            </Col>
            <Col xs={2} className="px-2">
              <input
                type="text"
                className="w-100 otp-input"
                maxLength={1}
                ref={otpThree}
                onKeyPress={handleKeyPress}
                onChange={(e) => {
                  setOtpData3(e.target.value);
                  if (e.target.value == "") {
                    otpTwo.current.focus();
                  } else {
                    otpFour.current.focus();
                  }
                }}
              />
            </Col>
            <Col xs={2} className="px-2">
              <input
                type="text"
                className="w-100 otp-input"
                maxLength={1}
                ref={otpFour}
                onKeyPress={handleKeyPress}
                onChange={(e) => {
                  setOtpData4(e.target.value);
                  if (e.target.value == "") {
                    otpThree.current.focus();
                  } else {
                    otpFive.current.focus();
                  }
                }}
              />
            </Col>
            <Col xs={2} className="px-2">
              <input
                type="text"
                className="w-100 otp-input"
                maxLength={1}
                ref={otpFive}
                onKeyPress={handleKeyPress}
                onChange={(e) => {
                  setOtpData5(e.target.value);
                  if (e.target.value == "") {
                    otpFour.current.focus();
                  } else {
                    otpSix.current.focus();
                  }
                }}
              />
            </Col>
            <Col xs={2} className="px-2">
              <input
                type="text"
                className="w-100 otp-input"
                maxLength={1}
                ref={otpSix}
                onKeyPress={handleKeyPress}
                onChange={(e) => {
                  setOtpData6(e.target.value);
                  if (e.target.value == "") {
                    otpFive.current.focus();
                  }
                }}
              />
            </Col>
          </Row>
        </Container>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            padding: "18px",
          }}
        >
          <button
            style={{ width: "100%" }}
            onClick={handleVerify}
            className={verifyStyle ? "verify-otp-orange" : "verify-otp"}
            disabled={!verifyStyle}
          >
            Verify
          </button>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            padding: "18px",
          }}
        >
          Didn't receive OTP yet,
          <div
            onClick={handleResendOtp}
            style={{ color: "#0451be", marginLeft: "5px", cursor: "pointer", pointerEvents: isDisabled ? "none" : "" }}

          >
            {" "} {isDisabled ? ` Resend in ${timer}` : " Resend!"}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default OTPVerifyModal;
