import React from "react";
import Slider from "react-slick";

const AppBar = () => {
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  return (
    <div
      className="mt-3 mt-sm-0 card-why profile-level-up h-auto"
      style={{ justifyContent: "flex-start" }}
    >
      <img
        src="/assets/why3.webp"
        width={"95%"}
        alt="why"
        style={{ margin: "5px", borderRadius: "20px" }}
      />
      <img src="/assets/images/level-up.webp" width="80%" alt="level-up" className="mt-2" />
      <h5 className="mt-3 text-black text-center font-weight-bold">
        Premium Annual Subscription
      </h5>
      <p className="text-black text-center">
        <span className="font-weight-bold">Enhance Your OB Experience</span>
        <br />
        With Added Benefits <br />
        For <span className="font-weight-bold">AED 299</span> Only
      </p>
      <Slider className="w-100 d-flex align-items-center mb-4" {...settings}>
        <div className="d-flex align-items-center w-100">
          <img
            src="/assets/level-up/pickup.webp"
            width={64}
            alt="pickup"
            className="mt-1"
            style={{
              padding: 8,
            }}
          />
          <p className="m-0 text-blue ml-2" style={{ fontSize: 16 }}>
            Free Pickup Drop On All Services
          </p>
        </div>
        <div className="d-flex align-items-center w-100">
          <img
            src="/assets/level-up/profit.webp"
            width={64}
            alt="profit"
            className="mt-1"
            style={{
              padding: 8,
            }}
          />
          <p className="m-0 text-blue ml-2" style={{ fontSize: 16 }}>
            Earn Double OB CashPoints
          </p>
        </div>
        <div className="d-flex align-items-center w-100">
          <img
            src="/assets/level-up/offer.webp"
            width={64}
            alt="offer"
            className="mt-1"
            style={{
              padding: 8,
            }}
          />
          <p className="m-0 text-blue ml-2" style={{ fontSize: 16 }}>
            Get Exclusive Offers
          </p>
        </div>
        <div className="d-flex align-items-center w-100">
          <img
            src="/assets/level-up/validity.webp"
            width={64}
            alt="validity"
            className="mt-1"
            style={{
              padding: 8,
            }}
          />
          <p className="m-0 text-blue ml-2" style={{ fontSize: 16 }}>
            12 Months Validity
          </p>
        </div>
      </Slider>
    </div>
  );
};

export default AppBar;
