import API from ".";
// Authentication API
export const SignInUrl = (request) => API.post('v2/api/otp/generate', request);
export const SignUpUrl = (request) => API.post('v2/api/register/customer', request);
// export const VerifyOtpUrl = (request) => API.post('token', request);
export const VerifyOtpUrl = (request) => {
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
    };
    return API.post('token', request, { headers });
  };







