import { useEffect, useRef, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { connect } from "react-redux";
import Slider from "react-slick";
import { fetchCars } from "../../redux/actions/profileAction";
import Link from "next/link";
import OutsideClickHandler from "react-outside-click-handler";
import { getCarBrandModal } from "../../redux/actions/globalAction";
import { packageSize } from "../../lib/config";
import { FaPen, FaPenSquare } from "react-icons/fa";
import { saveCart } from "../../lib/cart";
import { showMyAccount } from "../../redux/actions/cartAction";

function MyAccount({
  isMobile,
  fetchCars,
  loginData,
  profile,
  show,
  setShow,
  getCarBrandModal,
  cart,
  apiData,
  globalData,
  showMyAccount
}) {
  const [selectedCar, setCar] = useState({});

  useEffect(async () => {
    await fetchCars();
  }, []);

  useEffect(async () => {
    if (!(cart.workshop && cart.products)) {
      if (!localStorage.getItem('car_data') && Array.isArray(globalData.carData)) {
        selectCar(profile.cars[0]);
      }
    }
  }, [profile.cars]);

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0
  };

  const selectCar = (carData) => {
    if (carData && !(cart.workshop && cart.products)) {
      setCar(carData);
      getCarBrandModal(
        carData.CarCompany.CompanyName,
        carData.CarModel.Name,
        carData.CarCompany.ImgUrl,
        carData.CarModel.ImgUrl,
        carData.CarCompany.Id,
        carData.CarModel.Id,
        carData.CarModel.Slug
      );
      saveCart(cart, globalData, apiData)
      showMyAccount(false)
    }
  };

  return (
    <>
      <div
        className="bg-layer"
        onClick={() => {
          setShow(false);
          showMyAccount(false)
        }}
      ></div>
      <OutsideClickHandler onOutsideClick={() => {
        setShow(false)
        showMyAccount(false)
      }}>
        <div
          className="account-box pt-3 pb-1"
          style={{ width: isMobile ? "80%" : "35%" }}
        >
          <h5 className="pl-2 font-weight-bold px-3">My Account</h5>
          <Container className="px-4">
            <Row>
              <img
                src={loginData?.userAuth?.Avatar || "/assets/user.svg"}
                width={85}
                className="pr-3 pl-2"
                alt="logo"
              />
              <div className="d-flex justify-content-center flex-column info">
                <h4 className="m-0">{loginData?.userAuth?.FirstName}</h4>
                <p>
                  +971 {loginData?.userAuth?.PhoneNumber} |{" "}
                  {loginData?.userAuth?.Email}
                </p>
              </div>
            </Row>
            <hr className="my-2" />
            <Row>
              <Col className="p-1 text-center">
                <Link href="/profile/dashboard">
                  <a className="py-1">Dashboard</a>
                </Link>
              </Col>
              <Col className="p-1 text-center">
                <Link href="/profile">
                  <a className="py-1">Profile</a>
                </Link>
              </Col>
              <Col className="p-1 text-center">
                <Link href="/profile/orders">
                  <a className="py-1">My Orders</a>
                </Link>
              </Col>
              <Col className="p-1 text-center">
                <Link href="/profile/quotation">
                  <a className="py-1">My Quotes</a>
                </Link>
              </Col>
            </Row>
          </Container>
          <div className="mt-2" style={{ background: "#f5f5f5", padding: "0px 30px", borderTop: "4px solid #ff3201" }}>

            {profile.cars.length > 0 ? (
              <Slider className="mt-2 w-100" {...settings}>
                {profile.cars.map((car, index) => (
                  <div
                    className={`item text-center py-1 ${selectedCar == car && "selected-car"
                      }`}
                    key={index}
                    onClick={() => selectCar(car)}
                  >
                    <img
                      src={
                        car?.CarModel?.ImgUrl ||
                        "https://openbonnet-storage.s3.me-south-1.amazonaws.com/public/car-company/bmw.png"
                      }
                      width="90%"
                      className="m-auto"
                      alt="img"
                    />
                    <div className="d-flex align-items-start justify-content-center">
                      <img
                        className="mr-1"
                        src={
                          car?.CarCompany?.ImgUrl ||
                          "https://archive.izmostock.com/img-get/I0000sJ.U3oSxU4w/s/1000/2015-audi-a3-ambition-2door-convertible-2wd-angular-front.jpg"
                        }
                        width="20px"
                        height="20px"
                        alt="CarCompany"
                      />{" "}
                      <div>
                        <p className="p-0 m-0">{car.CarCompany?.CompanyName}-{car.CarModel?.Name}</p>
                        <p className="m-0 p-0" style={{ fontSize: "10px" }}>{car.PlateNo ? car.PlateNo : "XXX-XX-XXX"}</p>
                      </div>
                      <Link href="/profile/my-car">
                        <a>
                          <FaPenSquare className="ml-1" style={{ fontSize: 15 }} />
                        </a>
                      </Link>
                    </div>
                  </div>
                ))}
              </Slider>
            ) : (
              <div
                className={`item text-center py-1 `}
                style={{
                  background: "#F5F5F5"
                }}
              //   key={index}
              //   onClick={() => selectCar(car)}
              >
                <p>No Car Added to Account</p>
                <Link href="/profile/my-car">
                  <a className="text-primary">Add Car</a>
                </Link>
              </div>
            )}
          </div>
        </div>
      </OutsideClickHandler>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    globalData: state.globalData,
    apiData: state.apiData,
    loginData: state.loginData,
    profile: state.profile,
    cart: state.cart,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    clearLoginData: () => {
      dispatch(clearLoginData());
    },
    getBrand: () => {
      dispatch(getBrand());
    },
    getModel: (id) => {
      dispatch(getModel(id));
    },
    getCarBrandModal: (
      brand,
      modal,
      logoBrand,
      logoModal,
      brandId,
      modelId,
      slug = null
    ) => {
      dispatch(
        getCarBrandModal(brand, modal, logoBrand, logoModal, brandId, modelId, slug)
      );
    },
    fetchCars: () => {
      dispatch(fetchCars());
    },
    showMyAccount: (data) => {
      dispatch(showMyAccount(data))
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyAccount);
