import { Col, Row, Spinner } from "react-bootstrap";
import { FaHome, FaPhoneAlt, FaWhatsapp } from "react-icons/fa";
import Nav from "react-bootstrap/Nav";
import { gtmCall, gtmWhatsApp } from "../../lib/google-tag-manager";
import { isMobile } from "react-device-detect";

function Login({
  onLoginSubmit,
  handleSubmit,
  register,
  errors,
  isSubmitting,
  setAuthBoxType,
  toggleSignupForm,
}) {
  return (
    <form
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        marginTop: "20%",
      }}
      onSubmit={handleSubmit(onLoginSubmit)}
    >
      <div className="container text-center">
        <img src="/assets/user.svg" alt="logo" width={120} className="m-2" />
        <div
          className="auth-validation-div"
          style={{
            height: "40px",
            padding: "8px",
            margin: "5px 30px",
            marginTop: "10px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            border: "1px solid #000",
            borderRadius: "20px",
          }}
        >
          <div className="pr-2 d-flex align-items-center">
            {" "}
            <svg
              width={31}
              height={30}
              viewBox="0 0 31 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.0083 29.8114C23.2886 29.8114 30.0011 23.1539 30.0011 14.9414C30.0011 6.72885 23.2886 0.0712891 15.0083 0.0712891C6.72809 0.0712891 0.015625 6.72885 0.015625 14.9414C0.015625 23.1539 6.72809 29.8114 15.0083 29.8114Z"
                fill="#F0F0F0"
              />
              <path
                d="M8.55469 20.2178L9.86998 29.0672C13.6317 30.4476 17.792 30.2911 21.4374 28.6319C25.0828 26.9727 27.9154 23.9465 29.3132 20.2178H8.55469Z"
                fill="black"
              />
              <path
                d="M8.55469 9.78162L9.86998 0.932157C13.6314 -0.447474 17.791 -0.290494 21.4359 1.36864C25.0807 3.02778 27.9129 6.05349 29.3107 9.78162H8.55469Z"
                fill="#6DA544"
              />
              <path
                d="M8.06025e-10 14.9987C-3.18685e-05 18.0616 0.944996 21.0512 2.70814 23.5658C4.47128 26.0805 6.96809 27.9997 9.86317 29.0658V0.932129C6.96817 1.99817 4.47142 3.91736 2.70829 6.4319C0.945148 8.94643 7.18711e-05 11.9359 8.06027e-10 14.9987H8.06025e-10Z"
                fill="#A2001D"
              />
            </svg>{" "}
            +971
          </div>
          <input
            type="text"
            placeholder="Phone Number"
            onKeyDown={(e) => {
              const isCtrlKey = e.ctrlKey || e.metaKey; // Check if "ctrl" or "cmd" key is pressed
              const keyValue = e.key;
              const numericRegex = /^[0-9]*$/;
              const isEnterKey = e.key === "Enter";
              if (
                !(
                  numericRegex.test(keyValue) ||
                  isCtrlKey ||
                  keyValue === "Backspace" ||
                  isEnterKey
                )
              ) {
                e.preventDefault();
              }
            }}
            onPaste={(e) => {
              const pastedValue = e.clipboardData.getData("text");
              const numericRegex = /^[0-9]*$/;
              if (!numericRegex.test(pastedValue)) {
                e.preventDefault();
              }
            }}
            onInput={(e) => {
              if (e.target.value.length > e.target.maxLength) {
                e.target.value = e.target.value.slice(0, e.target.maxLength);
              }
            }}
            maxLength={9}
            style={{ border: "none" }}
            className="w-100"
            {...register("phoneNumber", {
              required: true,
              validate: (value) => value[0] === "5" && value.length === 9,
            })}
          />
          {errors.phoneNumber && (
            <span className="text-danger auth-validation-span">
              Enter valid phone number
            </span>
          )}
        </div>

        <button
          className="button-submit btn-login mt-3 text-white mx-lg-0 shine-animation-new"
          style={{ border: "none", background: "#000" }}
          type="submit"
          disabled={isSubmitting}
        >
          Login
          {isSubmitting && (
            <Spinner animation="grow" role="status" className="ml-2" size="sm">
              <span className="visually-hidden"></span>
            </Spinner>
          )}
        </button>
        <a
          className="text-center"
          style={{ margin: "5px", cursor: "auto", color: "#8a3c90" }}
        >
          If you don't have a account,
          <span
            className="text-primary"
            style={{ cursor: "pointer" }}
            onClick={() => {
              setAuthBoxType("signup");
              toggleSignupForm(true);
            }}
          >
            {" "}
            Sign Up
          </span>
        </a>
      </div>

      <div className="Facing_issues-icons">
        <p>Facing Issues?</p>
        <div className="d-flex justify-content-center">
          <Nav.Item>
            <Nav.Link
              className="center-header whatsapp"
              href="https://api.whatsapp.com/send?phone=978002274357"
              target="_blank"
            >
              <a
                className=" align-items-center phoneP d-flex"
                onClick={() => gtmWhatsApp()}
                style={{ textDecoration: "none" }}
                target="_blank"
              >
                <img src="/assets/whatsapp.svg" alt="logo" width={35} />
              </a>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              className="center-header whatsapp"
              href="tel:+8002274357"
              target="_blank"
            >
              <a
                onClick={() => gtmCall()}
                className=" align-items-center phoneP d-flex"
                s
                style={{ textDecoration: "none" }}
                target="_blank"
              >
                <img src="/assets/phone.svg" alt="logo" width={35} />
              </a>
            </Nav.Link>
          </Nav.Item>
        </div>
      </div>
    </form>
  );
}

export default Login;
