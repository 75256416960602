import {
  FETCH_ADDRESS,
  FETCH_BILLING_DETAILS,
  FETCH_CARS,
  FETCH_NOTIFICATION,
  FETCH_OB_POINTS,
  FETCH_PROFILE_COMPLETION,
  FETCH_PROFILE_ORDERS,
  FETCH_PROFILE_PACKAGES_ORDERS,
  FETCH_PROFILE_QUOTATIONS,
  FETCH_RELATIONSHIP_SUMMARY,
  PROFILE,
  SET_LOCATION,
  SET_REMOVED_LABOUR,
  SET_REMOVED_SPARES,
  SET_SELECTED_CAR,
  TOGGLE_ADDRESS_LOADING,
  TOGGLE_CAR_LOADING,
  TOGGLE_ORDER_LOADING,
  UPDATE_PROFILE_ORDERS,
  TOGGLE_PACKAGE_LOADING,
  ADD_ORDER_ESTIMATE,
  UPDATE_ORDER_ESTIMATE,
  TOGGLE_OBPOINTS_LOADING,
  TOGGLE_QUOTATIONS_LOADING,
  SET_JOB_HISTORY,
  TOGGLE_JOB_HISTORY_LOADING,
  FETCH_JOB_HISTORY,
  FETCH_ORDER_INVOICE,
  TOGGLE_INVOICE_LOADING,
  SET_UPDATE_PROFILE,
  FETCH_PROFILE_ACTIVE_ORDERS,
  FETCH_PROFILE_FOR_ACTIVE_ORDERS,
  FETCH_PROFILE_ACTIVE_QUOTATIONS,
  TOGGLE_REPAIR_INSURNACE_LOADING,
  FETCH_REPAIR_INSURANCE,
} from "./types";
import axios from "axios";

export const getProfileData = () => async (dispatch) => {
  let token = await JSON.parse(localStorage.getItem("login_access"));
  let customerId = await JSON.parse(localStorage.getItem("customerId"));
  if (token) {
    try {
      const { data } = await axios.get(
        `${process.env.NEXT_PUBLIC_API_URL}v2/api/customers/${customerId}/profile`,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: "bearer " + token.access_token,
          },
        }
      );
      dispatch({
        type: PROFILE,
        payload: data.Data,
      });
    } catch (error) {
      console.log("error ", error);
    }
  }
};

export const fetchAddress = () => async (dispatch) => {
  let token = await JSON.parse(localStorage.getItem("login_access"));
  let customerId = await JSON.parse(localStorage.getItem("customerId"));
  if (token) {
    dispatch({ type: TOGGLE_ADDRESS_LOADING, payload: true });
    try {
      const { data } = await axios.get(
        `${process.env.NEXT_PUBLIC_API_URL}v2/api/customers/${customerId}/addresses`,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: "bearer " + token.access_token,
          },
        }
      );

      dispatch({
        type: FETCH_ADDRESS,
        payload: data.Data,
      });
      dispatch({ type: TOGGLE_ADDRESS_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: TOGGLE_ADDRESS_LOADING, payload: false });
      console.log("error ", error);
    }
  }
};
export const fetchBillingDetails = () => async (dispatch) => {
  let token = await JSON.parse(localStorage.getItem("login_access"));
  let customerId = await JSON.parse(localStorage.getItem("customerId"));
  if (token) {
    dispatch({ type: TOGGLE_ADDRESS_LOADING, payload: true });
    try {
      const { data } = await axios.get(
        `${process.env.NEXT_PUBLIC_API_URL}v2/api/customers/${customerId}/billing-details`,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: "bearer " + token.access_token,
          },
        }
      );

      dispatch({
        type: FETCH_BILLING_DETAILS,
        payload: data.Data,
      });
      dispatch({ type: TOGGLE_ADDRESS_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: TOGGLE_ADDRESS_LOADING, payload: false });
      console.log("error ", error);
    }
  }
};

export const fetchJobHistory = (carData) => async (dispatch) => {
  let token = await JSON.parse(localStorage.getItem("login_access"));
  let cartId = carData?.Id;
  if (token) {
    try {
      dispatch({ type: FETCH_JOB_HISTORY, payload: true });
      const { data } = await axios.get(
        `${process.env.NEXT_PUBLIC_API_URL}v2/api/customer-purchase-order/history?CarId=${cartId}`,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: "bearer " + token.access_token,
          },
        }
      );
      dispatch({
        type: SET_JOB_HISTORY,
        payload: data.Data,
      });
      dispatch({ type: FETCH_JOB_HISTORY, payload: false });
    } catch (error) {
      dispatch({ type: FETCH_JOB_HISTORY, payload: false });
      console.log("error ", error);
    }
  }
};

export const fetchRepairInsurance = (carData) => async (dispatch) => {
  let token = await JSON.parse(localStorage.getItem("login_access"));
  let customerId = await JSON.parse(localStorage.getItem("customerId"));
  let cartId = carData?.Id;
  if (token) {
    try {
      dispatch({ type: TOGGLE_REPAIR_INSURNACE_LOADING, payload: true });
      const { data } = await axios.get(
        `${process.env.NEXT_PUBLIC_API_URL}v2/api/customers/${customerId}/profile/insurances?CarId=${cartId}`,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: "bearer " + token.access_token,
          },
        }
      );
      dispatch({
        type: FETCH_REPAIR_INSURANCE,
        payload: data.Data,
      });
      dispatch({ type: TOGGLE_REPAIR_INSURNACE_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: TOGGLE_REPAIR_INSURNACE_LOADING, payload: false });
      console.log("error ", error);
    }
  }
};

export const jobHistoryLoading = (data) => async (dispatch) => {
  dispatch({
    type: TOGGLE_JOB_HISTORY_LOADING,
    payload: data,
  });
};

export const fetchCars = () => async (dispatch) => {
  let token = await JSON.parse(localStorage.getItem("login_access"));
  let customerId = await JSON.parse(localStorage.getItem("customerId"));

  if (token && customerId) {
    dispatch({ type: TOGGLE_CAR_LOADING, payload: true });
    try {
      const { data } = await axios.get(
        `${process.env.NEXT_PUBLIC_API_URL}v2/api/customers/${customerId}/vehicles`,
        {
          headers: {
            Authorization: "bearer " + token.access_token,
          },
        }
      );
      dispatch({
        type: FETCH_CARS,
        payload: data.Data,
      });
      dispatch({ type: TOGGLE_CAR_LOADING, payload: false });
    } catch (error) {
      console.log("error ", error);
      dispatch({ type: TOGGLE_CAR_LOADING, payload: false });
    }
  }
};

export const setLocation = (lat, lng) => async (dispatch) => {
  dispatch({
    type: SET_LOCATION,
    payload: { lat, lng },
  });
};

export const fetchOrders =
  (carId, size = 10, page = 1) =>
  async (dispatch) => {
    let token = await JSON.parse(localStorage.getItem("login_access"));
    let customerId = await JSON.parse(localStorage.getItem("customerId"));
    if (token && carId) {
      try {
        dispatch({ type: TOGGLE_ORDER_LOADING, payload: true });
        const { data } = await axios.get(
          `${process.env.NEXT_PUBLIC_API_URL}v2/api/customers/${customerId}/profile/orders?Page=${page}&Size=${size}&CarModelId=${carId}`,
          {
            headers: {
              Authorization: "bearer " + token.access_token,
            },
          }
        );

        dispatch({
          type: FETCH_PROFILE_ORDERS,
          payload: data,
        });
        dispatch({ type: TOGGLE_ORDER_LOADING, payload: false });
      } catch (error) {
        console.log("error ", error);
        dispatch({ type: TOGGLE_ORDER_LOADING, payload: false });
      }
    }
  };
export const fetchActiveOrders =
  (carId, size = 10, page = 1) =>
  async (dispatch) => {
    let token = await JSON.parse(localStorage.getItem("login_access"));
    let customerId = await JSON.parse(localStorage.getItem("customerId"));
    if (token) {
      try {
        dispatch({ type: TOGGLE_ORDER_LOADING, payload: true });
        const { data } = await axios.get(
          `${
            process.env.NEXT_PUBLIC_API_URL
          }v2/api/customers/${customerId}/profile/orders?Page=${page}&Size=${size}${
            carId ? "CarModelId=" + carId : ""
          }&active=true`,
          {
            headers: {
              Authorization: "bearer " + token.access_token,
            },
          }
        );

        dispatch({
          type: FETCH_PROFILE_ACTIVE_ORDERS,
          payload: data,
        });
        dispatch({ type: TOGGLE_ORDER_LOADING, payload: false });
      } catch (error) {
        console.log("error ", error);
        dispatch({ type: TOGGLE_ORDER_LOADING, payload: false });
      }
    }
  };

export const fetchForActiveOrders = () => async (dispatch) => {
  let token = await JSON.parse(localStorage.getItem("login_access"));
  let customerId = await JSON.parse(localStorage.getItem("customerId"));
  if (token) {
    try {
      dispatch({ type: TOGGLE_ORDER_LOADING, payload: true });
      const { data } = await axios.get(
        `${process.env.NEXT_PUBLIC_API_URL}v2/api/customers/${customerId}/active-items`,
        {
          headers: {
            Authorization: "bearer " + token.access_token,
          },
        }
      );

      dispatch({
        type: FETCH_PROFILE_FOR_ACTIVE_ORDERS,
        payload: data,
      });
      dispatch({ type: TOGGLE_ORDER_LOADING, payload: false });
    } catch (error) {
      console.log("error ", error);
      dispatch({ type: TOGGLE_ORDER_LOADING, payload: false });
    }
  }
};

export const fetchInspectionReports = async () => {
  let token = await JSON.parse(localStorage.getItem("login_access"));
  let customerId = await JSON.parse(localStorage.getItem("customerId"));
  if (token) {
    try {
      const { data } = await axios.get(
        `${process.env.NEXT_PUBLIC_API_URL}v2/api/customers/${customerId}/profile/car-inspection-reports`,
        {
          headers: {
            Authorization: "bearer " + token.access_token,
          },
        }
      );
      return data;
    } catch (error) {
      console.log("error ", error);
      return [];
    }
  }
};

export const fetchBuy3Get1 = async () => {
  let token = await JSON.parse(localStorage.getItem("login_access"));
  let customerId = await JSON.parse(localStorage.getItem("customerId"));
  if (token) {
    try {
      const { data } = await axios.get(
        `${process.env.NEXT_PUBLIC_API_URL}v2/api/customers/${customerId}/profile/buy-3-get-1-free`,
        {
          headers: {
            Authorization: "bearer " + token.access_token,
          },
        }
      );
      return data;
    } catch (error) {
      console.log("error ", error);
      return [];
    }
  }
};

export const fetchSelectedCarOrders =
  (carId, size = 10, page = 1) =>
  async (dispatch) => {
    let token = await JSON.parse(localStorage.getItem("login_access"));
    let customerId = await JSON.parse(localStorage.getItem("customerId"));
    if (token) {
      try {
        dispatch({ type: TOGGLE_ORDER_LOADING, payload: true });
        const { data } = await axios.get(
          `${process.env.NEXT_PUBLIC_API_URL}v2/api/customers/${customerId}/profile/orders?Page=${page}&Size=${size}&CarId=${carId}`,
          {
            headers: {
              Authorization: "bearer " + token.access_token,
            },
          }
        );

        dispatch({
          type: FETCH_PROFILE_ORDERS,
          payload: data,
        });
        dispatch({ type: TOGGLE_ORDER_LOADING, payload: false });
      } catch (error) {
        console.log("error ", error);
        dispatch({ type: TOGGLE_ORDER_LOADING, payload: false });
      }
    }
  };

export const fetchOrdersPackages = (size, page) => async (dispatch) => {
  let token = await JSON.parse(localStorage.getItem("login_access"));
  let customerId = await JSON.parse(localStorage.getItem("customerId"));
  if (token) {
    try {
      dispatch({ type: TOGGLE_PACKAGE_LOADING, payload: true });
      const { data } = await axios.get(
        `${process.env.NEXT_PUBLIC_API_URL}v2/api/customers/${customerId}/profile/packages?Page=${page}&Size=${size}`,
        {
          headers: {
            Authorization: "bearer " + token.access_token,
          },
        }
      );
      dispatch({
        type: FETCH_PROFILE_PACKAGES_ORDERS,
        payload: data,
      });
      dispatch({ type: TOGGLE_PACKAGE_LOADING, payload: false });
    } catch (error) {
      console.log("error ", error);
      dispatch({ type: TOGGLE_PACKAGE_LOADING, payload: false });
    }
  }
};

export const updateRemovedSpares = (data) => async (dispatch) => {
  dispatch({
    type: SET_REMOVED_SPARES,
    payload: data,
  });
};

export const updateRemovedLabour = (data) => async (dispatch) => {
  dispatch({
    type: SET_REMOVED_LABOUR,
    payload: data,
  });
};
export const toggleUpdateProfile = (flag) => async (dispatch) => {
  dispatch({
    type: SET_UPDATE_PROFILE,
    payload: flag,
  });
};

export const updateProfileOrders = (data) => async (dispatch) => {
  dispatch({
    type: UPDATE_PROFILE_ORDERS,
    payload: data,
  });
};

export const addEstimate = (data) => async (dispatch) => {
  dispatch({
    type: ADD_ORDER_ESTIMATE,
    payload: data,
  });
};

export const updateEstimate = (data) => async (dispatch) => {
  dispatch({
    type: UPDATE_ORDER_ESTIMATE,
    payload: data,
  });
};

export const fetchQuotations = (carId, size, page) => async (dispatch) => {
  let token = await JSON.parse(localStorage.getItem("login_access"));
  let customerId = await JSON.parse(localStorage.getItem("customerId"));
  if (token) {
    dispatch({ type: TOGGLE_QUOTATIONS_LOADING, payload: true });
    try {
      const { data } = await axios.get(
        `${process.env.NEXT_PUBLIC_API_URL}v2/api/customers/${customerId}/profile/customer-quotation-requests?Page=${page}&Size=${size}&CarId=${carId}`,
        {
          headers: {
            Authorization: "bearer " + token.access_token,
          },
        }
      );
      dispatch({
        type: FETCH_PROFILE_QUOTATIONS,
        payload: data,
      });

      dispatch({ type: TOGGLE_QUOTATIONS_LOADING, payload: false });
    } catch (error) {
      console.log("error ", error);
    }
  }
};

export const fetchNotification = () => async (dispatch) => {
  let token = await JSON.parse(localStorage.getItem("login_access"));
  let customerId = await JSON.parse(localStorage.getItem("customerId"));
  if (token) {
    try {
      const { data } = await axios.get(
        `${process.env.NEXT_PUBLIC_API_URL}v2/api/customers/customers/${customerId}/profile/notifications`,
        {
          headers: {
            Authorization: "bearer " + token.access_token,
          },
        }
      );
      dispatch({
        type: FETCH_NOTIFICATION,
        payload: data,
      });
    } catch (error) {
      console.log("error ", error);
    }
  }
};

export const fetchProfileCompletion = () => async (dispatch) => {
  let token = await JSON.parse(localStorage.getItem("login_access"));
  let customerId = await JSON.parse(localStorage.getItem("customerId"));
  if (token) {
    try {
      const { data } = await axios.get(
        `${process.env.NEXT_PUBLIC_API_URL}v2/api/customers/${customerId}/profile-completion`,
        {
          headers: {
            Authorization: "bearer " + token.access_token,
          },
        }
      );
      dispatch({
        type: FETCH_PROFILE_COMPLETION,
        payload: data,
      });
    } catch (error) {
      console.log("error ", error);
    }
  }
};

export const fetchOrderInvoice = (orderId) => async (dispatch) => {
  let token = await JSON.parse(localStorage.getItem("login_access"));
  if (token) {
    try {
      dispatch({ type: TOGGLE_INVOICE_LOADING, payload: true });
      const { data } = await axios.get(
        `${process.env.NEXT_PUBLIC_API_URL}v2/api/customer-purchase-order/${orderId}/Invoices`,
        {
          headers: {
            Authorization: "bearer " + token.access_token,
          },
        }
      );
      dispatch({
        type: FETCH_ORDER_INVOICE,
        payload: data?.Data,
      });
      dispatch({ type: TOGGLE_INVOICE_LOADING, payload: false });
    } catch (error) {
      console.log("error ", error);
    }
  }
};

export const fetchRelationshipSummary = () => async (dispatch) => {
  let token = await JSON.parse(localStorage.getItem("login_access"));
  let customerId = await JSON.parse(localStorage.getItem("customerId"));
  if (token) {
    try {
      const { data } = await axios.get(
        `${process.env.NEXT_PUBLIC_API_URL}v2/api/customers/${customerId}/relationship-summary`,
        {
          headers: {
            Authorization: "bearer " + token.access_token,
          },
        }
      );
      dispatch({
        type: FETCH_RELATIONSHIP_SUMMARY,
        payload: data,
      });
    } catch (error) {
      console.log("error ", error);
    }
  }
};

export const fetchOBPoints = () => async (dispatch) => {
  let token = await JSON.parse(localStorage.getItem("login_access"));
  let customerId = await JSON.parse(localStorage.getItem("customerId"));
  if (token) {
    try {
      dispatch({ type: TOGGLE_OBPOINTS_LOADING, payload: true });
      const { data } = await axios.get(
        `${process.env.NEXT_PUBLIC_API_URL}v2/api/customers/${customerId}/ob-points`,
        {
          headers: {
            Authorization: "bearer " + token.access_token,
          },
        }
      );
      dispatch({
        type: FETCH_OB_POINTS,
        payload: data.Data,
      });
      dispatch({ type: TOGGLE_OBPOINTS_LOADING, payload: false });
    } catch (error) {
      console.log("error ", error);
    }
  }
};
//
