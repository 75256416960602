import axios from "axios";
import { route } from "next/dist/server/router";
import { Router, useRouter } from "next/router";
import { useEffect, useRef, useState } from "react";
import { Col, Container, Modal, Row, Spinner } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { FaAngleDown, FaAngleUp, FaCheck, FaTimes } from "react-icons/fa";
import Select from "react-select";
import { Recorder } from "react-voice-recorder";
import "react-voice-recorder/dist/index.css";
import { connect, useDispatch } from "react-redux";
import { getModel } from "../../redux/actions/apiActions";
import { isMobile } from "react-device-detect";
import WorkshopTime from "../common/WorkshopTime";
import { fetchDefaultWorkshop, fetchEmirates } from "../../actions/homePage";
import API from "api";

const audioData = {
  url: null,
  blob: null,
  chunks: null,
  duration: {
    h: 0,
    m: 0,
    s: 0,
  },
};

function SOS({ setSos, apiData, getModel }) {
  const [show, setShow] = useState(true);
  const [recHide, setRecHide] = useState(false);
  const [imageHide, setImageHide] = useState(false);
  const [imageFile, setImageFile] = useState();
  const [audioFile, setAudioFile] = useState();
  const [audioDetails, setAudioDetails] = useState(audioData);
  const [ServiceList, setServiceList] = useState([]);
  const [ModelList, setModelList] = useState([]);
  const [BrandList, setBrandList] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState("");

  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm();
  const dispatch = useDispatch();

  const router = useRouter();
  const fileInputRef = useRef(null);

  useEffect(() => {
    dispatch(fetchEmirates());
    dispatch(fetchDefaultWorkshop());
  }, []);

  const handleImageUpload = (e) => {
    const file = e.target?.files[0];
    if (e.target?.files[0]?.size > 3145728) {
      toast.dismiss();
      toast.error("Select a image which is less than 3 MB.");
      return;
    }
    if (!/^(image\/jpeg|image\/png)$/.test(file?.type)) {
      toast.dismiss();
      toast.error("Invalid file type. Please select a JPEG or PNG image.");
      return;
    }
    setImageFile(e.target?.files[0]);
  };

  const handleAudioStop = (data) => {
    setAudioDetails(data);
  };

  const handleAudioUpload = (file) => {
    if (file == null) {
      toast.dismiss();
      toast.error("Please try recording your message again!");
      return;
    }
    setAudioFile(file);
    setRecHide(false);
  };

  const handleCountDown = (data) => { };

  const handleReset = () => {
    const reset = {
      url: null,
      blob: null,
      chunks: null,
      duration: {
        h: 0,
        m: 0,
        s: 0,
      },
    };
    setAudioDetails(reset);
  };

  const CarCompanyId = watch("CarCompanyId");

  useEffect(() => {
    const carData = JSON.parse(
      localStorage.getItem("car_data") || JSON.stringify("{}")
    );
    if (carData && carData != null && carData != {}) {
      setValue("CarCompanyId", {
        label: carData?.brand,
        value: carData?.brandId,
      });
      setValue("CarModelId", {
        label: carData?.modal,
        value: carData?.modelId,
      });
    }
  }, []);

  useEffect(() => {
    if (CarCompanyId) {
      if (selectedBrand) {
        setValue("CarModelId", {});
      }
      getModel(CarCompanyId?.value);
    }
  }, [CarCompanyId]);

  useEffect(() => {
    const ServiceListOptions =
      apiData?.categories?.map((service) => {
        return {
          label: service.Name,
          value: service.ID,
        };
      }) || [];
    setServiceList(ServiceListOptions);

    const BrandListOptions =
      apiData?.brands?.map((car) => {
        return {
          label: car.Name,
          value: car.ID,
        };
      }) || [];
    setBrandList(BrandListOptions.length ? BrandListOptions : BrandList);

    const ModelListOptions =
      apiData?.models?.map((model) => {
        return {
          label: model.Name,
          value: model.ID,
        };
      }) || [];
    setModelList(ModelListOptions);
  }, [apiData]);

  const removePrefixPostfixSpaces = (inputString) => {
    while (inputString.startsWith(" ")) {
      inputString = inputString?.slice(1);
    }
    while (inputString.endsWith(" ")) {
      inputString = inputString?.slice(0, -1);
    }
    return inputString;
  };

  const handleAdd = async (data) => {
    const FirstName = removePrefixPostfixSpaces(data.FirstName);
    const LastName = removePrefixPostfixSpaces(data.LastName);
    const URL =
      typeof window !== "undefined"
        ? window?.location?.href
        : `https://openbonnet.com/${router?.asPath}`;
    data.Image = imageFile;
    data.Document = null;
    data.Audio = audioFile;
    const payload = {
      ...data,
      FirstName,
      LastName,
      JourneyCategoryId: data.JourneyCategoryId?.value,
      CarCompanyId: data.CarCompanyId.value,
      CarModelId: data.CarModelId.value,
      Type: "SOS-Form",
      RequestPostUrl: URL,
    };

    await API.post(`v2/api/leads`, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then(async (res) => {
        localStorage.setItem(
          "SosUser",
          JSON.stringify({ FirstName: data.FirstName, LastName: data.LastName })
        );
        localStorage.setItem("hasVisitedRoute", true);
        router.push("/request/thank-you");
        toast.dismiss();
        toast.success("Request successfully submitted!");
        reset();
        setImageFile();
        setAudioFile();
      })
      .catch((err) => {
        console.log("Error ", err);
        toast.dismiss();
        toast.error("Something went wrong! Please retry.");
      });
  };

  return (
    <>
      <Row className="px-2 justify-content-between">
        <h4 className="px-2 w-100">Request a Call Back</h4>
        <h6 className="px-2 text-black">
          Currently{" "}
          {apiData?.defaultWorkshop?.Details ? (
            <WorkshopTime data={apiData?.defaultWorkshop?.Details} />
          ) : (
            "Fetching..."
          )}
        </h6>
      </Row>
      <hr />
      <Row
        style={
          isMobile
            ? { marginRight: "0px", marginBottom: "10px", paddingLeft: "10px" }
            : { marginRight: "0px", marginBottom: "100px", paddingLeft: "10px" }
        }
      >
        <div className="w-100">
          <div className="d-flex">
            <div style={{ padding: "10px 10px 10px 10px", width: "50%" }}>
              <input
                type="text"
                {...register("FirstName", {
                  required: true,
                })}
                className="login-input new-common-input"
                onKeyPress={(event) => {
                  if (/\d/.test(event.key)) {
                    // test if the pressed key is a number
                    event.preventDefault(); // prevent the input of numeric values
                  }
                }}
                placeholder="First Name"
                onPaste={(event) => {
                  const clipboardData =
                    event.clipboardData || window.clipboardData;
                  const pastedData = clipboardData.getData("text");
                  if (/\d/.test(pastedData)) {
                    // test if the clipboard contents contain numeric values
                    event.preventDefault(); // prevent the paste operation
                  }
                }}
              />
              {errors.FirstName && (
                <span className="text-danger">First name is required</span>
              )}
            </div>
            <div style={{ padding: "10px 10px 10px 10px", width: "50%" }}>
              <input
                type="text"
                {...register("LastName", {
                  required: false,
                })}
                className="login-input new-common-input"
                placeholder="Last Name"
                onKeyPress={(event) => {
                  if (/\d/.test(event.key)) {
                    // test if the pressed key is a number
                    event.preventDefault(); // prevent the input of numeric values
                  }
                }}
                onPaste={(event) => {
                  const clipboardData =
                    event.clipboardData || window.clipboardData;
                  const pastedData = clipboardData.getData("text");
                  if (/\d/.test(pastedData)) {
                    // test if the clipboard contents contain numeric values
                    event.preventDefault(); // prevent the paste operation
                  }
                }}
              />
            </div>
          </div>

          <div style={{ padding: "0px 10px 10px 10px", width: "100%" }}>
            <div className="d-flex">
              <div
                className="d-flex align-items-center px-2"
                style={{
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                  borderTopRightRadius: "0px",
                  borderBottomRightRadius: "0px",
                  borderRight: "0px",
                }}
              >
                <p className="text-black d-flex align-items-center">
                  <svg
                    width={31}
                    height={30}
                    viewBox="0 0 31 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15.0083 29.8114C23.2886 29.8114 30.0011 23.1539 30.0011 14.9414C30.0011 6.72885 23.2886 0.0712891 15.0083 0.0712891C6.72809 0.0712891 0.015625 6.72885 0.015625 14.9414C0.015625 23.1539 6.72809 29.8114 15.0083 29.8114Z"
                      fill="#F0F0F0"
                    />
                    <path
                      d="M8.55469 20.2178L9.86998 29.0672C13.6317 30.4476 17.792 30.2911 21.4374 28.6319C25.0828 26.9727 27.9154 23.9465 29.3132 20.2178H8.55469Z"
                      fill="black"
                    />
                    <path
                      d="M8.55469 9.78162L9.86998 0.932157C13.6314 -0.447474 17.791 -0.290494 21.4359 1.36864C25.0807 3.02778 27.9129 6.05349 29.3107 9.78162H8.55469Z"
                      fill="#6DA544"
                    />
                    <path
                      d="M8.06025e-10 14.9987C-3.18685e-05 18.0616 0.944996 21.0512 2.70814 23.5658C4.47128 26.0805 6.96809 27.9997 9.86317 29.0658V0.932129C6.96817 1.99817 4.47142 3.91736 2.70829 6.4319C0.945148 8.94643 7.18711e-05 11.9359 8.06027e-10 14.9987H8.06025e-10Z"
                      fill="#A2001D"
                    />
                  </svg>{" "}
                  +971
                </p>
              </div>
              <input
                type="number"
                style={{
                  borderTopLeftRadius: "0px",
                  borderBottomLeftRadius: "0px",
                }}
                className="login-input px-1 new-common-input"
                placeholder="Enter Phone Number"
                onPaste={(event) => {
                  event.preventDefault();
                  const text = event.clipboardData.getData("text/plain");
                  const sanitizedText = text.replace(/[^0-9]/g, ""); // remove spaces
                  document.execCommand("insertText", false, sanitizedText);
                }}
                onInput={(e) => {
                  if (e.target.value.length > e.target.maxLength)
                    e.target.value = e.target.value.slice(
                      0,
                      e.target.maxLength
                    );
                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                }}
                maxLength={9}
                {...register("PhoneNumber", {
                  required: true,
                  validate: (value) => {
                    return value[0] == "5" && value.length === 9;
                  },
                })}
              />
            </div>
            {errors.PhoneNumber && (
              <span className="text-danger">
                Enter valid phone number starting with 5
              </span>
            )}
          </div>
          <div style={{ padding: "0px 10px 10px 10px", width: "100%" }}>
            <input
              type="email"
              {...register("Email", {
                required: true,
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "invalid email address",
                },
              })}
              className="login-input new-common-input"
              placeholder="Email"
            />
            {errors.Email && (
              <span className="text-danger">Please enter a valid email ID</span>
            )}
          </div>
          <div className="d-flex">
            <div style={{ padding: "0px 10px 10px 10px", width: "50%" }}>
              <Controller
                control={control}
                name="CarCompanyId"
                rules={{ required: true }}
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState,
                }) => {
                  const currentvalue = value?.label ? value : undefined;
                  return (
                    <Select
                      menuPlacement="top"
                      options={BrandList}
                      onBlur={onBlur}
                      onChange={(selectedOption) => {
                        onChange(selectedOption);
                        setSelectedBrand(selectedOption.value);
                      }}
                      placeholder="Car Brand"
                      value={currentvalue}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                      })}
                      styles={{
                        control: (styles) => ({
                          ...styles,
                          height: 45,
                          border: "1px solid #808080",
                          borderRadius: "5px",
                          fontWeight: 600,
                          fontFamily: "inherit",
                          boxShadow: "none",
                          color: "#333333",
                        }),
                        placeholder: (styles) => ({
                          ...styles,
                          fontSize: "16px",
                          fontWeight: 600,
                          fontFamily: "inherit",
                        }),
                        menuList: (styles) => ({
                          ...styles,
                          border: "2px solid #2682fc",
                          maxHeight: "165px",
                        }),
                      }}
                    />
                  );
                }}
              />
              {errors.CarCompanyId && (
                <span className="text-danger">Car Brand is required</span>
              )}
            </div>

            <div style={{ padding: "0px 10px 10px 10px", width: "50%" }}>
              <Controller
                control={control}
                name="CarModelId"
                rules={{ required: true }}
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState,
                }) => {
                  const currentvalue = value?.label ? value : undefined;
                  return (
                    <Select
                      defaultValue={currentvalue}
                      options={ModelList}
                      menuPlacement="top"
                      onBlur={onBlur}
                      placeholder="Car Model"
                      onChange={onChange}
                      value={currentvalue}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                      })}
                      styles={{
                        control: (styles) => ({
                          ...styles,
                          height: 45,
                          border: "1px solid #808080",
                          borderRadius: "5px",
                          fontWeight: 600,
                          fontFamily: "inherit",
                          boxShadow: "none",
                          color: "#333333",
                        }),
                        placeholder: (styles) => ({
                          ...styles,
                          fontSize: "16px",
                          fontWeight: 600,
                          fontFamily: "inherit",
                        }),
                        menuList: (styles) => ({
                          ...styles,
                          border: "2px solid #2682fc",
                          maxHeight: "165px",
                        }),
                      }}
                    />
                  );
                }}
              />
              {errors.CarModelId && (
                <span className="text-danger">Car Model is required</span>
              )}
            </div>
          </div>

          <div style={{ padding: "0px 10px 10px 10px", width: "100%" }}>
            <Controller
              control={control}
              name="JourneyCategoryId"
              rules={{ required: true }}
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState,
              }) => {
                const currentvalue = value?.label ? value : undefined;

                return (
                  <Select
                    defaultValue={currentvalue}
                    options={ServiceList}
                    onBlur={onBlur}
                    isSearchable={false} // Remove search feature
                    menuPlacement="top"
                    onChange={onChange}
                    placeholder="Service Required"
                    value={currentvalue}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 0,
                    })}
                    styles={{
                      control: (styles) => ({
                        ...styles,
                        height: 45,
                        border: "1px solid #808080",
                        borderRadius: "5px",
                        fontWeight: 600,
                        fontFamily: "inherit",
                        boxShadow: "none",
                        color: "#333333",
                      }),
                      placeholder: (styles) => ({
                        ...styles,
                        fontSize: "16px",
                        fontWeight: 600,
                        fontFamily: "inherit",
                      }),
                      menuList: (styles) => ({
                        ...styles,
                        border: "2px solid #2682fc",
                        maxHeight: "165px",
                      }),
                    }}
                  />
                );
              }}
            />
            {errors.JourneyCategoryId && (
              <span className="text-danger">Please Select The Service</span>
            )}
          </div>
          <h6 className="px-3">Tell us more? (optional)</h6>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              paddingLeft: "10px",
              paddingRight: "10px",
            }}
          >
            <div
              style={{
                height: "50px",
                background: "#f3f4ff",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                borderRadius: "25px",
                padding: "18px",
              }}
            >
              <input
                type="text"
                style={{
                  border: "none",
                  background: "transparent",
                  width: "100%",
                  fontSize: "14px",
                }}
                placeholder="Type your message"
                {...register("Message", {
                  required: false,
                })}
              />
              {/* <label
                className="m-0"
                onClick={() => {
                  setImageHide(!imageHide)
                  fileInputRef.current?.click()
                }}
                style={{ cursor: "pointer" }}
              >
                <img src="/assets/repair/camera.svg" />
              </label>
              <input
                ref={fileInputRef}
                name="Image"
                type="file"
                accept="image/png, image/jpeg"
                style={{ display: "none" }}
                onChange={(e) => handleImageUpload(e)}
              /> */}
            </div>
            {/* <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
              <a
                onClick={() => {
                  setRecHide(!recHide);
                }}
              >
                {!recHide ? (
                  <img src="/assets/repair/mic.svg" width={44} />
                ) : (
                  <span
                    className="bg-danger py-1 px-2 text-white"
                    style={{ borderRadius: 20 }}
                  >
                    <FaTimes />
                  </span>
                )}
              </a>
            </div> */}
          </div>
          {(imageFile || audioFile) && (
            <small className="px-5">
              {imageFile && (
                <span className="mr-2">
                  <FaCheck /> Image Added
                </span>
              )}
              {audioFile && (
                <span className="mr-2">
                  <FaCheck /> Voice Added
                </span>
              )}
            </small>
          )}

          {/* <Modal show={recHide} onHide={() => setRecHide(false)}>
              <Modal.Body>
                <Container>
                  <Row>
                    <div className="d-flex justify-content-between align-items-center w-100 mb-0">
                      <p></p>
                      <p
                        role="button"
                        className="shadow p-2 bg-white rounded-circle"
                        onClick={() => setRecHide(false)}
                        style={{ background: "#F5F5F5" }}
                      >
                        <img src="/assets/close.svg" />
                      </p>
                    </div>
                    <div className="w-100">
                      <Recorder
                        record={true}
                        title={"New recording"}
                        audioURL={audioDetails.url}
                        showUIAudio
                        handleAudioStop={(data) => handleAudioStop(data)}
                        handleAudioUpload={(data) => handleAudioUpload(data)}
                        handleCountDown={(data) => handleCountDown(data)}
                        handleReset={() => handleReset()}
                        mimeTypeToUseWhenRecording={`audio/webm`} // For specific mimetype.
                      />
                    </div>
                  </Row>
                </Container>
              </Modal.Body>
            </Modal> */}

          {recHide && (
            <div className="w-100">
              <Recorder
                record={true}
                title={"New recording"}
                audioURL={audioDetails.url}
                showUIAudio
                handleAudioStop={(data) => handleAudioStop(data)}
                handleAudioUpload={(data) => handleAudioUpload(data)}
                handleCountDown={(data) => handleCountDown(data)}
                handleReset={(e) => handleReset(e)}
                mimeTypeToUseWhenRecording={`audio/webm`} // For specific mimetype.
              />
            </div>
          )}

          <div
            style={{
              position: "fixed",
              bottom: "0px",
              width: "100%",
              padding: "10px 0px",
              background: "#fff",
              boxShadow: "0px -5px 9px 0px #ccc",
              left: "0px",
            }}
          >
            <Row className="justify-content-center">
              <Col xs={11}>
                <button
                  className="otp-button d-flex align-items-center w-100 justify-content-center mt-3 Otp-Button-request"
                  disabled={isSubmitting}
                  onClick={handleSubmit(handleAdd)}
                  style={{
                    background:
                      "linear-gradient(0deg, rgba(247,146,34,1) 0%, rgba(255,50,1,1) 100%)",
                  }}
                >
                  Submit
                  {isSubmitting && (
                    <Spinner
                      animation="grow"
                      role="status"
                      className="ml-2"
                      size="sm"
                    >
                      <span className="visually-hidden"></span>
                    </Spinner>
                  )}
                </button>
              </Col>
            </Row>
          </div>
        </div>
      </Row>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    apiData: state.apiData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getModel: (ID) => {
      dispatch(getModel(ID));
    },
  };
};

// export default SOS;
export default connect(mapStateToProps, mapDispatchToProps)(SOS);
