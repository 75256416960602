const isProd = !process.env.NEXT_PUBLIC_IS_DEV;

export const gtmChooseWorkShop = (name) => {
  if (isProd)
    window.dataLayer?.push({
      event: `workshop-${name}`,
    });
};

export const gtmChooseService = (name) => {
  if (isProd)
    window.dataLayer?.push({
      event: `select-${name}`,
    });
};

export const gtmChooseAddOn = (name) => {
  if (isProd)
    window.dataLayer?.push({
      event: `addon-${name}`,
    });
};

export const gtmChooseProceed = (name) => {
  if (isProd)
    window.dataLayer?.push({
      event: `proceed-${name}`,
    });
};

export const gtmChooseSkip = (name) => {
  if (isProd)
    window.dataLayer?.push({
      event: `skip-${name}`,
    });
}

export const gtmChooseCheckout = (name) => {
  if (isProd)
    window.dataLayer?.push({
      event: `checkout-${name}`,
    });
};

export const gtmChooseLoginToOrder = (name) => {
  if (isProd)
    window.dataLayer?.push({
      event: `login_to_order-${name}`,
    });
};

export const gtmChoosePaymentOption = (name) => {
  if (isProd)
    window.dataLayer?.push({
      event: `payment-option-${name}`,
    });
}

export const gtmThankYouPage = () => {
  if (isProd)
    window.dataLayer?.push({
      event: `thank-you-page`,
    });
}

export const gtmLogin = () => {
  if (isProd)
    window.dataLayer?.push({
      event: `login`,
    });
}

export const gtmSignUp = () => {
  if (isProd)
    window.dataLayer?.push({
      event: `signup`,
    });
}

export const gtmOTPVerify = () => {
  if (isProd)
    window.dataLayer?.push({
      event: `OtpVerify`,
    });
}

export const gtmWhatsApp = () => {
  if (isProd)
    window.dataLayer?.push({
      event: `whatsApp`,
    });
}
export const gtmCall = () => {
  if (isProd)
    window.dataLayer?.push({
      event: `call`,
    });
}
export const gtmServiceContract = (name) => {
  if (isProd)
    window.dataLayer?.push({
      event: `service-contract-${name}`,
    });
}

export const gtmBrandIcons = (name) => {
  if (isProd)
    window.dataLayer?.push({
      event: `Brand-Icons-${name}`,
    });
}

export const gtmWorkshopCollection = (name) => {
  if (isProd)
    window.dataLayer?.push({
      event: `workshop-collection-${name}`,
    });
}

export const gtmTopSellingJobs = (name) => {
  if (isProd)
    window.dataLayer?.push({
      event: `top-selling-jobs-${name}`,
    });
}

export const gtmLeadRequestButtonClick = (name) => {
  if (isProd)
    window.dataLayer?.push({
      event: `lead-request-button-click`,
    });
}

export const gtmOfferInquiryButtonClick = (name) => {
  if (isProd)
    window.dataLayer?.push({
      event: `offer-inquiry-button-click`,
    });
}

export const gtmProductEcommerceUrlParameters = () => {
  if (isProd)
    window.dataLayer?.push({
      event: `product-ecommerce-url-parameters`,
    });
}

export const gtmCheckoutPage = (name) => {
  if (isProd)
    window.dataLayer?.push({
      event: `checkout-page-button-click-${name}`,
    });
}

export const gtmStartHereButtonClick = () => {
  if (isProd)
    window.dataLayer?.push({
      event: `Start-here`,
    });
}

export const gtmExitIntentSubmitButtonClick = () => {
  if (isProd)
    window.dataLayer?.push({
      event: `Exit-intent-popup-submit`,
    });
}




